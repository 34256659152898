import styled from 'styled-components/macro'
import { LogoStyled } from '@anyonelab/common'

export const LoginFormStyled = styled.div`
  margin: 5rem auto;
  width: 100%;
  max-width: 25rem;
  padding: 1rem;
  text-align: center;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  font-family: 'Poppins';
`
