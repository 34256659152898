/**
 * Asynchronously loads the component for NotFoundPage
 */

import { lazyLoad } from '@anyonelab/frontend/utils/loadable'

export const OnBoardingPageLoadable = lazyLoad(
  () => import('./OnBoardingPage'),
  (module) => module.OnBoardingPage,
)
