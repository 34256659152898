import { createSlice } from '@reduxjs/toolkit'
import { BobaSettingState } from './types'
import {
  useInjectReducer,
  useInjectSaga,
} from '@anyonelab/frontend/utils/redux-injectors'

export const initialState: BobaSettingState = {
  cashoutHistoryData: null,
  donationHistoryData: null,
  userData: null,
}

const slice = createSlice({
  name: 'bobaSetting',
  initialState,
  reducers: {
    updateUserData: (state, action) => {
      state.userData = action.payload
    },
    updateCashoutHistoryData: (state, action) => {
      state.cashoutHistoryData = action.payload
    },
    updateDonationHistoryData: (state, action) => {
      state.donationHistoryData = action.payload
    },
  },
})

export const { actions: bobaSettingActions } = slice

export const useBobaSettingSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  return { actions: slice.actions }
}
