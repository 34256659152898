import * as React from 'react'
import {
  BobaComponent,
  ChipComponent,
  Divider,
  LogoStyled,
} from '@anyonelab/common'
import messages from '@anyonelab/frontend/app/components/LoginForm/messages'
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons'
import { useIntl } from 'react-intl'
import { FirebaseAuthContext } from '@anyonelab/frontend/app/providers/FirebaseAuthProvider/FirebaseAuthProvider'
import { useHistory, Redirect } from 'react-router-dom'
import { LoginFormStyled } from '@anyonelab/frontend/app/components/LoginForm/styles/LoginFormStyled'
import { useSignInWithProvider } from '@anyonelab/frontend/app/components/LoginForm/hooks/useSignInWithProvider'
import { googleProvider } from '@anyonelab/frontend/app/services/auth/getGoogleProvider'
import { facebookProvider } from '@anyonelab/frontend/app/services/auth/getFacebookProvider'
import { AuthErrors } from '@anyonelab/frontend/app/components/LoginForm/components/AuthErrors/AuthErrors'
import styled from 'styled-components/macro'
import googleButtonImage from '../../../assets/btn_google_signin_light_normal_web@2x.png'
import facebookButtonImage from '../../../assets/btn_facebook_signin_light_normal_web@2x.png'
import { AppletButton } from '../Marketplace/components/AppletButton'

export const LoginForm = () => {
  const { isAuthenticated } = React.useContext(FirebaseAuthContext)
  const [authError, setAuthError] = React.useState<null | string>(null)

  const intl = useIntl()
  const history = useHistory()

  const onAuthError = (errMessage) => {
    setAuthError(errMessage)
  }

  const signInWithGoogleCb = useSignInWithProvider(
    googleProvider,
    history,
    onAuthError,
  )
  const signInWithFacebookCb = useSignInWithProvider(
    facebookProvider,
    history,
    onAuthError,
  )

  if (isAuthenticated) {
    return <Redirect to="/" />
  }

  /**
   * We are using the 3rd party package for the social login buttons.
   * Reason is very simple, I was lazy to build our own buttons and dealing with
   * responsiveness. The buttons we have here do not greatly fit the StyledComponents paradigm,
   * but they are good enough for MVP.
   * In the future versions, I recommend we build our own social login buttons and style them accordingly to designs.
   */
  return (
    <>
      <LoginFormStyled>
        <div
          style={{ fontWeight: 600, fontSize: 40, margin: '24px auto' }}
          children={'Hello 👋'}
        />
        <div
          style={{ color: '#B6B6B6', fontSize: 20, fontWeight: 300 }}
          children={'Fun apps for creators.'}
        />
        <div
          style={{ color: '#B6B6B6', fontSize: 20, fontWeight: 300 }}
          children={'啟動你的創作者工具箱'}
        />
        <SocialButtonsSectionStyled>
          <SignInButton
            src={googleButtonImage}
            alt="google-sign-in-button"
            onClick={signInWithGoogleCb}
          />
          <SignInButton
            style={{
              width: 214,
              paddingTop: 9,
              paddingBottom: 9,
              backgroundColor: '#4267b2',
              borderRadius: 5,
            }}
            src={facebookButtonImage}
            alt="facebook-sign-in-button"
            onClick={signInWithFacebookCb}
          />
          <Divider />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              alignItems: 'center',
              gap: '24px',
            }}
          >
            <Icon
              style={{
                background:
                  'linear-gradient(90deg, #E08686 0%, #E79281 52%, #EB9B7E 100%)',
              }}
              children={<ChipComponent width="45" height="45" />}
            />
            <StyledDiv>
              <div
                style={{ color: '#6B6B6B', fontWeight: 300, fontSize: 16 }}
                children={`SnackBio`}
              />
              <div
                style={{ color: '#6B6B6B', fontWeight: 300, fontSize: 16 }}
                children={`輕鬆創造你的數位名片`}
              />
            </StyledDiv>
            <Icon
              style={{
                background: '#AD5138',
              }}
              children={<BobaComponent width="45" height="45" />}
            />
            <StyledDiv>
              <div
                style={{ color: '#6B6B6B', fontWeight: 300, fontSize: 16 }}
                children={`BobaMe`}
              />
              <div
                style={{ color: '#6B6B6B', fontWeight: 300, fontSize: 16 }}
                children={`有趣小額贊助工具 `}
              />
            </StyledDiv>
          </div>
          <div
            style={{ color: '#B6B6B6', margin: '24px auto 12px' }}
            children={'and more...'}
          />
        </SocialButtonsSectionStyled>

        {authError && <AuthErrors errorMessage={authError} />}
      </LoginFormStyled>
      <div
        style={{
          color: '#838587',
          textAlign: 'center',
          fontSize: 14,
          fontFamily: 'Roboto',
        }}
        children={'Copyright ©2021 Anyone Lab'}
      />
    </>
  )
}

const SubHeaderStyled = styled.p`
  color: #3d3d3d;
  margin-bottom: 0;
`

const SocialButtonsSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  button {
    width: 40px;
    max-width: 100%;
  }
`

const SignInButton = styled.img`
  width: 220px;
  margin: 6px 0;
  cursor: pointer;
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

const Icon = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`
