export const getDailyColor = (
  weekday: string,
): { primary: string; secondary: string } => {
  switch (weekday) {
    case 'Mon':
      return { primary: '#3E668E', secondary: '#DEE2EB' }
    case 'Tue':
      return { primary: '#AD5138', secondary: '#FABA96' }
    case 'Wed':
      return { primary: '#927A6A', secondary: '#B4D7DB' }
    case 'Thu':
      return { primary: '#DBDBDB', secondary: '#A0A295' }
    case 'Fri':
      return { primary: '#7C6D64', secondary: '#FCE4A4' }
    case 'Sat':
      return { primary: '#883953', secondary: '#DFB899' }
    case 'Sun':
    default:
      return { primary: '#6F5C57', secondary: '#F3C485' }
  }
}

export const getDailyEmoji = (weekday: string): string => {
  switch (weekday) {
    case 'Mon':
      return '٩(◦`꒳´◦)۶'
    case 'Tue':
      return '͟͟͞͞( •̀д•́)'
    case 'Wed':
      return '( ิ◕㉨◕ ิ)'
    case 'Thu':
      return '( ͡° ͜ʖ ͡°)'
    case 'Fri':
      return '(ﾉ>ω<)ﾉ'
    case 'Sat':
      return 'ξ( ✿＞◡❛)'
    case 'Sun':
    default:
      return '( Φ ω Φ )'
  }
}
