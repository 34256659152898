import { defineMessages } from 'react-intl'

export default defineMessages({
  header: {
    defaultMessage: 'Welcome to AnyoneLab!',
  },
  subheader: {
    defaultMessage: 'To log in, please select your connected account.',
  },
  logInWithProviderButton: {
    defaultMessage: 'Log in',
  },
  introduction: {
    defaultMessage:
      'BobaMe is a donation platform produced by AnyoneLab. The login mailbox you choose will receive notification or reply from the creator!',
  },
})
