import * as React from 'react'

type WarningIconProps = {
  backgroundColor?: string
  tickColor?: string
} & React.SVGProps<SVGSVGElement>

export const WarningIcon = (props: WarningIconProps) => (
  <svg
    width={11}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m5.5 1.89 3.765 6.163h-7.53L5.5 1.89ZM5.5 0 0 9h11L5.5 0ZM6 6.632H5v.947h1v-.947Zm0-2.843H5v1.895h1V3.79Z"
      fill={props.backgroundColor || '#AB5337'}
    />
  </svg>
)
