import React, { useState } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartArea,
} from 'chart.js'
import styled, { useTheme } from 'styled-components/macro'
import moment from 'moment'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { LinkButtonDistribution } from '@anyonelab/frontend/types/DataInsight'
import { useIntl } from 'react-intl'
import messages from './messages'
import { NotifyBlock } from '../NotifyBlock'

type PageViewChartProps = {
  data: LinkButtonDistribution[] | null
  locked: boolean
} & React.HTMLAttributes<HTMLDivElement>

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
)

export const PageClickChart = (props: PageViewChartProps) => {
  const theme = useTheme()
  const intl = useIntl()
  const getGradient = (
    ctx: CanvasRenderingContext2D,
    chartArea: ChartArea,
    barHeight: number,
  ) => {
    const gradientBg = ctx.createLinearGradient(
      0,
      barHeight,
      0,
      chartArea.bottom,
    )
    gradientBg.addColorStop(0, 'rgba(39, 204, 223,1)')
    gradientBg.addColorStop(1, 'rgba(39, 204, 223,0)')
    return gradientBg
  }

  const responseData = props.data

  if (!responseData) {
    return <></>
  }

  const chartLabel = responseData.map((item, index) => index + 1)
  const chartTitle = responseData.map((item, index) => item.link_button_title)
  const chartData = responseData.map((item) => item.total_clicks)

  return (
    <Container {...props}>
      {props.locked ? (
        <NotifyBlock themeColor={'#EE8183'} content={'page_click'} />
      ) : (
        <Bar
          redraw
          height={255}
          options={{
            indexAxis: 'y',
            plugins: {
              title: {
                display: true,
                text: intl.formatMessage(messages.topLeaderboard, {
                  number: 5,
                }),
                align: 'start',
                color: '#BDCADB',
                font: {
                  family: 'Montserrat',
                  size: 11,
                  weight: '700',
                },
              },
              legend: { display: false },
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: { duration: 1000, easing: 'easeInOutQuint' },
            animations: { show: { from: 0, to: 1 } },
            scales: {
              x: {
                ticks: {
                  maxRotation: 0,
                  maxTicksLimit: chartData.length > 8 ? 4 : 11,
                  display: false,
                },
                grid: {
                  display: false,
                  drawBorder: false,
                },
              },
              y: {
                ticks: {
                  color: '#BDCADB',
                  font: {
                    family: 'Montserrat',
                    weight: '700',
                  },
                  align: 'end',
                },
                grid: {
                  display: false,
                },
              },
            },
          }}
          data={{
            labels: chartLabel,
            datasets: [
              {
                label: 'Clicks',
                data: chartData,
                barThickness: 16,
                backgroundColor: 'transparent',
                datalabels: {
                  formatter: (value, context) => {
                    const index = context.dataIndex
                    if (chartTitle[index].length >= 50) {
                      return chartTitle[index].slice(0, 50) + '...'
                    } else {
                      return chartTitle[index]
                    }
                  },
                  clip: true,
                  textAlign: 'start',
                  anchor: 'start',
                  align: 'right',
                  color: '#6C7B8A',
                  font: {
                    size: 12,
                    family: 'Roboto',
                    weight: 400,
                  },
                },
              },
              {
                label: 'Clicks',
                data: chartData,
                barThickness: 12,
                backgroundColor: ({ chart }) => {
                  const data = (chart.getDatasetMeta(1)
                    .data as any) as BarElement[]
                  if (
                    !chart.chartArea ||
                    !data.every((value) => value.y !== undefined)
                  )
                    return theme.colors.pageClick
                  // return data.map((item) =>
                  //   getGradient(chart.ctx, chart.chartArea, item.y),
                  // )
                },
                datalabels: {
                  textAlign: 'start',
                  anchor: 'start',
                  align: 'right',
                  color: '#fff',
                  font: {
                    size: 9.5,
                    family: 'Roboto',
                    weight: 600,
                  },
                },
              },
            ],
          }}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
