import React, { SVGAttributes } from 'react'

export const DesktopBackIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="11"
      height="17"
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="goback">
        <path
          id="Vector 1"
          d="M9.5 1L2 8.5L9.5 16"
          stroke="#6C7B8A"
          stroke-width="1.5"
        />
      </g>
    </svg>
  )
}
