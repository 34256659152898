import {
  PageClickResponse,
  PageViewResponse,
} from '@anyonelab/frontend/types/DataInsight'
import moment from 'moment'
import {
  PageClickState,
  PageViewState,
} from '../../../pages/DataInsightsPage/slice/types'

const dateFormat = 'M/D'

export function transformDateToDMFormat(date) {
  const result = moment(date).format(dateFormat)
  if (result === 'Invalid date') return false
  return result
}

export function pageViewDataInsightParser(
  data: PageViewResponse,
): PageViewState {
  const parsedData = {
    ...data,
    today: transformDateToDMFormat(data.today) || data.today,
    seven_days_before:
      transformDateToDMFormat(data.seven_days_before) || data.seven_days_before,
    fourteen_days_before:
      transformDateToDMFormat(data.fourteen_days_before) ||
      data.fourteen_days_before,
    past_seven_days_net:
      data.past_seven_days_view_differences.net > 0
        ? '+' + data.past_seven_days_view_differences.net.toFixed(0)
        : data.past_seven_days_view_differences.net.toFixed(0),
    past_seven_days_net_percentage:
      (data.past_seven_days_view_differences.net_percentage * 100).toFixed(0) +
      '%',
  }

  return parsedData
}

export function buttonClickDataInsightParser(
  data: PageClickResponse,
): PageClickState {
  const parsedData = {
    ...data,
    today: transformDateToDMFormat(data.today) || data.today,
    seven_days_before:
      transformDateToDMFormat(data.seven_days_before) || data.seven_days_before,
    fourteen_days_before:
      transformDateToDMFormat(data.fourteen_days_before) ||
      data.fourteen_days_before,
    past_seven_days_net:
      data.past_seven_days_click_differences.net > 0
        ? '+' + data.past_seven_days_click_differences.net.toFixed(0)
        : data.past_seven_days_click_differences.net.toFixed(0),
    past_seven_days_net_percentage:
      (data.past_seven_days_click_differences.net_percentage * 100).toFixed(0) +
      '%',
  }

  return parsedData
}
