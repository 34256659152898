import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@anyonelab/frontend/types'
import { initialState } from '.'

const selectSlice = (state: RootState) => state?.i18n || initialState

export const selectI18n = createSelector([selectSlice], (state) => state)

export const selectLocale = createSelector(
  [selectI18n],
  (state) => state.locale,
)

export const selectFullLocale = createSelector(
  [selectI18n],
  (state) => state.fullLocale,
)
