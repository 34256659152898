import {
  BalanceBoard,
  BalanceStatusBoard,
  BobaLoader,
  BobaResponsiveModal,
  message,
  Spacer,
  useResponsive,
  WeeklyStatusBoard,
} from '@anyonelab/common/'
import { createBobaPayouts } from '@anyonelab/frontend/api/boba-api/BobaPayoutAPI'
import { useGetBobaUserAccount } from '@anyonelab/frontend/api/boba-api/hook/useGetBobaData'
import {
  useGetBobaDonationBobaCountInsight,
  useGetDataInsight,
} from '@anyonelab/frontend/api/hooks/DataInsight/useGetDataInsight'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { useBobaSettingSlice } from '../../slice'
import { CashoutRequestContent } from './CashoutRequestContent/CashoutRequestContent'
import messages from './messages'

export const BalanceBoardPage = () => {
  const intl = useIntl()
  const { data, refetch, isLoading } = useGetBobaUserAccount()
  const dispatch = useDispatch()
  const { actions } = useBobaSettingSlice()
  const [modalIsVisible, setModalIsVisible] = React.useState(false)
  const { responsiveClassName } = useResponsive()
  const { data: bobaPageViewFetchedData } = useGetDataInsight('boba-page-view')
  const { data: weeklyBobaDonation } = useGetBobaDonationBobaCountInsight(7)

  const balanceBoardData = data?.data[0]

  React.useEffect(() => {
    if (!data) return
    dispatch(actions.updateUserData(data.data[0]))
  }, [data])

  // TODO: Need to fix, don't know why it crash after two continuous fetching?
  const cashoutOnClick = async (data) => {
    try {
      await createBobaPayouts({ payment_method: data })
      await refetch()
      setModalIsVisible(false)
      message.success(intl.formatMessage(messages.cashoutRequestSucceed))
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  if (isLoading) return <BobaLoader />

  return (
    <>
      <BalanceBoard
        cashoutOnClick={() => setModalIsVisible(true)}
        balanceBoardData={balanceBoardData}
      />
      <Spacer space={1.5} />
      <StatusContainer className={responsiveClassName}>
        <WeeklyStatusBoard
          past_seven_days_view_times={
            bobaPageViewFetchedData?.data.past_seven_days_view_times
          }
          weeklyBobaDonation={weeklyBobaDonation?.data.reduce(
            (prev, curr) => prev + curr.boba_count,
            0,
          )}
        />
        <BalanceStatusBoard
          total_money_made={balanceBoardData?.total_money_made}
          total_received_boba_count={
            balanceBoardData?.total_received_boba_count
          }
        />
      </StatusContainer>
      <BobaResponsiveModal
        children={
          <CashoutRequestContent
            currency={balanceBoardData?.currency}
            email={balanceBoardData?.email}
            cashoutOnClick={cashoutOnClick}
            cashoutAmount={balanceBoardData?.cashout_available_balance || 0}
          />
        }
        visible={modalIsVisible}
        onClose={() => setModalIsVisible(false)}
      />
    </>
  )
}

const StatusContainer = styled.div`
  display: grid;
  &.tablet-small,
  &.tablet-big,
  &.tablet-desktop {
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 20px;
`
