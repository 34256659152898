import React, { SVGAttributes } from 'react'

export const MobileBackIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="15"
      height="25"
      viewBox="0 0 15 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="back">
        <path
          id="Vector 1"
          d="M13.5 2L3 12.5L13.5 23"
          stroke="#D8D8D8"
          stroke-width="3"
        />
      </g>
    </svg>
  )
}
