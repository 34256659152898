/**
 * Asynchronously loads the component for NotFoundPage
 */

import { lazyLoad } from '@anyonelab/frontend/utils/loadable'

export const DonatorRegisterPageLoadable = lazyLoad(
  () => import('./DonatorRegisterPage'),
  (module) => module.DonatorRegisterPage,
)
