import React from 'react'
import { useBobaUser } from '../../providers/BobaUserProvider/BobaUserProvider'
import { customPostMessage } from '@anyonelab/frontend/utils/postMessage'
import { useApplication } from '../../providers/ApplicationContextProvider/ApplicationContextProvider'
import { useUser } from '../../providers/UserContextProvider/UserContext'
import { createBobaUserAccounts } from '@anyonelab/frontend/api/boba-api/BobaUserAccountAPI'
import styled from 'styled-components'
import { Form, Radio } from 'antd'
import {
  BobaLoader,
  getDefaultBobaItems,
  IconRadioGroup,
  PrimaryButton,
  PrimaryTextInputOutlined,
  PrimaryTextInputProps,
  useResponsive,
} from '@anyonelab/common/'
import { useIntl } from 'react-intl'
import messages from './messages'
import { uuidv4 } from '@anyonelab/frontend/utils/uuid'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { bobaLogOut } from '../../components/LoginForm/components/SignOutButton/SignOutButton'

const DEFAULT_AVATAR_KEY = 'boba-avatar-0'

export function DonatorRegisterPage() {
  const { bobaUserAccount, snackBioPage, isLoading } = useBobaUser()
  const [avatarCustomImage, setAvatarCustomImage] = React.useState('')
  const { userData, isLoadingUserFetching } = useUser()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const { referer } = useApplication()
  const intl = useIntl()
  const history = useHistory()
  const { responsiveClassName } = useResponsive()

  const validateMessages = {
    required: '${label} is required!',
    string: {
      range: intl.formatMessage(messages.sthMustWithin, {
        label: '${label}',
        min: '${min}',
        max: '${max}',
      }),
    },
  }

  console.log({
    userData,
    bobaUserAccount,
    snackBioPage,
    isLoading,
    isLoadingUserFetching,
  })

  const updateAvatarCustomImage = (data: { avatar_image_url: string }) => {
    setAvatarCustomImage(data.avatar_image_url)
  }

  const onFinish = async (data) => {
    setIsSubmitting(false)
    const newData = {
      ...data,
      avatar_image_url: avatarCustomImage,
    }
    console.log({ newData })
    try {
      const res = await createBobaUserAccounts({
        ...newData,
        full_name: newData.display_name,
        currency: 'twd',
        domain: uuidv4(), // snackBioPage?.url ||
        boba_menu: getDefaultBobaItems('twd'),
        is_boba_user_activated: false,
        // Todo: add boba icon
      })

      if (res) {
        // successfully create user
        customPostMessage(
          'BOBA_DONATOR_LOGIN',
          {
            hasBobaAccount: true,
          },
          referer,
        )
        history.push('/boba')
      }
    } catch (err) {
      console.error(err)
    }
    setIsSubmitting(false)
  }

  React.useEffect(() => {
    // * initial checking , if already have boba account then post message to close iframe parent and back to boba page
    if (bobaUserAccount) {
      customPostMessage(
        'BOBA_DONATOR_LOGIN',
        {
          hasBobaAccount: true,
        },
        referer,
      )
      history.push('/boba')
    }
  }, [bobaUserAccount])

  if (
    isLoading ||
    isLoadingUserFetching ||
    _.isEmpty(userData) ||
    isSubmitting
  ) {
    return <BobaLoader />
  }

  // Todo missing API call to create boba with dummy data
  return (
    <Container className={`container ${responsiveClassName}`}>
      <Title
        style={{
          textAlign: 'center',
          fontSize: '18px',
          marginBottom: '32px',
        }}
        className="title"
        children={intl.formatMessage(messages.title)}
      />
      <StyledForm onFinish={onFinish} validateMessages={validateMessages}>
        <Subtitle
          style={{ marginBottom: '12px' }}
          className="display-name"
          children={intl.formatMessage(messages.name)}
        />
        <Form.Item
          initialValue={userData.preferred_name || userData.email}
          name="display_name"
          label={intl.formatMessage(messages.name)}
          rules={[
            {
              type: 'string',
              required: true,
            },
          ]}
        >
          <StyledPrimaryTextInputOutlined
            style={{ marginBottom: '16px' }}
            className="input-display-name-placeholder"
            defaultValue={userData.preferred_name || userData.email}
          />
        </Form.Item>
        <Subtitle
          style={{ marginBottom: '12px' }}
          className="email"
          children={intl.formatMessage(messages.email)}
        />
        <Form.Item
          initialValue={userData.email}
          name="email"
          label={intl.formatMessage(messages.email)}
          rules={[
            {
              type: 'string',
              required: true,
            },
          ]}
        >
          <StyledPrimaryTextInputOutlined
            disabled={Boolean(userData.email)}
            style={{ marginBottom: '16px' }}
            className="input-email-placeholder"
            defaultValue={userData.email}
          />
        </Form.Item>
        <Subtitle
          style={{ marginBottom: '20px' }}
          className="subtitle-icon-image"
          children={intl.formatMessage(messages.pickAvatar)}
        />
        <Form.Item
          initialValue={true}
          name="is_using_default_boba_avatar"
          label={intl.formatMessage(messages.pickAvatar)}
        >
          <Radio.Group defaultValue={true}>
            <IconRadioGroup
              updateOnClick={updateAvatarCustomImage}
              imageUrl={avatarCustomImage}
              defaultIconKey={DEFAULT_AVATAR_KEY}
            />
          </Radio.Group>
        </Form.Item>
        <ButtonsContainer>
          <PrimaryButton
            style={{ background: '#D79B7A', borderColor: '#D79B7A' }}
            className="button-back"
            onClick={bobaLogOut}
            content={intl.formatMessage(messages.back)}
          />
          <Form.Item>
            <PrimaryButton
              htmlType="submit"
              className="button-save"
              content={intl.formatMessage(messages.ctaMessage)}
            />
          </Form.Item>
        </ButtonsContainer>
      </StyledForm>
    </Container>
  )
}

const Container = styled.div`
  width: fit-content;
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  .ant-col.ant-form-item-label {
    display: none;
  }
`

const Title = styled.div`
  color: #4a1e11;
  font-weight: 700;
`

const Subtitle = styled.div`
  color: #794d2e;
  font-size: 16px;
  font-weight: 600;
`

const StyledPrimaryTextInputOutlined = styled(
  (props: PrimaryTextInputProps) => (
    <PrimaryTextInputOutlined borderColor="#ab5337;" {...props} />
  ),
)`
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  height: unset;
  padding: 8px 16px;
`

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  font-size: 18px;
  margin-top: 24px;
  > * {
    flex: 1;
  }
`
