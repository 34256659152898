export const SETTING_PAGES = [
  'barSetting',
  'receivedBobas',
  'balanceAndStats',
  'cashoutHistory',
  'donationHistory',
] as const

export const SETTING_PAGE_ROUTES = {
  barSetting: '/boba/setting',
  receivedBobas: '/boba/setting/received-donations',
  balanceAndStats: '/boba/setting/balance',
  cashoutHistory: '/boba/setting/cash-out-history',
  donationHistory: '/boba/setting/donation-history',
}

export const SETTING_PAGE_ACTIVATION_ACCESS_RIGHT = {
  barSetting: false,
  receivedBobas: true,
  balanceAndStats: true,
  cashoutHistory: true,
  donationHistory: false,
}
