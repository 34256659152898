import React from 'react'

type QuestionMarkIconProps = {
  backgroundColor?: string
  size?: number
} & React.SVGProps<SVGSVGElement>

export const QuestionMarkIcon = (props: QuestionMarkIconProps) => {
  return (
    <svg
      width={props.size || '26'}
      height={props.size || '26'}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C25.9921 20.1764 20.1764 25.9921 13 26ZM11.7 19.5V22.1H14.3V19.5H11.7ZM13 6.5C14.4359 6.5 15.6 7.66406 15.6 9.1C15.6046 9.78995 15.3276 10.4519 14.833 10.933L13.221 12.571C12.2479 13.5482 11.701 14.8709 11.7 16.25V16.9H14.3C14.199 15.5025 14.7626 14.1392 15.821 13.221L16.991 12.025C17.7685 11.2506 18.2038 10.1973 18.2 9.1C18.2 6.22812 15.8719 3.9 13 3.9C10.1281 3.9 7.8 6.22812 7.8 9.1H10.4C10.4 7.66406 11.5641 6.5 13 6.5Z"
        fill={props.backgroundColor || '#CCA78C'}
      />
    </svg>
  )
}
