import { UserOutlined } from '@ant-design/icons'
import { apiClient } from '@anyonelab/frontend/utils/api-client'
import { Avatar, Image, message, Spin, Upload } from 'antd'
import React, { Dispatch, SetStateAction } from 'react'

interface IconUploadProps {
  type:
    | 'background'
    | 'avatar'
    | 'themeIcon'
    | 'appIcon'
    | 'appScreenShoot'
    | 'bobaAvatar'
  iconUrl: string | null
  setIconUrl: Dispatch<SetStateAction<string | null>>
  cb?: (result: any) => any
  isAvatar?: boolean
}

function IconUpload({
  type,
  iconUrl,
  isAvatar = true,
  setIconUrl,
  cb,
}: IconUploadProps) {
  const [iconIsUploading, setIconIsUploading] = React.useState(false)

  const uploadIconAction = async (e) => {
    try {
      const formData = new FormData()

      formData.append(e.filename, e.file)

      const response = await apiClient.post(
        `/image-upload?type=${type}`,
        formData,
      )
      const result = response.data
      setIconUrl(result.image_name)
      setIconIsUploading(false)
      if (cb) {
        cb(result)
      }
    } catch (err) {
      console.log(err)
      message.error('Upload icon fail')
    }
  }

  return (
    <div>
      <Upload
        accept="image/png, image/gif, image/jpeg"
        name="uri"
        listType="picture-card"
        showUploadList={false}
        beforeUpload={() => {
          setIconIsUploading(true)
        }}
        customRequest={uploadIconAction}
      >
        {isAvatar ? (
          <>
            {iconUrl && !iconIsUploading ? (
              <Avatar
                src={iconUrl}
                size={100}
                icon={<UserOutlined />}
                style={{ backgroundColor: 'white' }}
              />
            ) : (
              <Avatar
                size={64}
                icon={iconIsUploading ? <Spin /> : <UserOutlined />}
                style={{ backgroundColor: 'white' }}
              />
            )}
          </>
        ) : (
          <>
            {iconUrl && !iconIsUploading ? (
              <Image
                src={iconUrl}
                width={200}
                style={{ backgroundColor: 'white' }}
                preview={false}
              />
            ) : (
              <Image
                preview={false}
                width={64}
                style={{ backgroundColor: 'white' }}
              />
            )}
          </>
        )}
      </Upload>
    </div>
  )
}

export default IconUpload
