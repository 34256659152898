/**
 * Asynchronously loads the component for NotFoundPage
 */

import { lazyLoad } from '@anyonelab/frontend/utils/loadable'

export const AdminPageLoadable = lazyLoad(
  () => import('./AdminPage'),
  (module) => module.AdminPage,
)
