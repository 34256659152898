import * as React from 'react'

type WrongIconProps = {
  backgroundColor?: string
  tickColor?: string
} & React.SVGProps<SVGSVGElement>

export const WrongIcon = (props: WrongIconProps) => (
  <svg
    width={17}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.701 5.1 8.5 7.302 6.298 5.1 5.1 6.299 7.302 8.5 5.1 10.701 6.299 11.9 8.5 9.698l2.201 2.202 1.199-1.199L9.698 8.5 11.9 6.298 10.701 5.1ZM8.5 0C3.8 0 0 3.8 0 8.5S3.8 17 8.5 17 17 13.2 17 8.5 13.2 0 8.5 0Zm0 15.3a6.809 6.809 0 0 1-6.8-6.8c0-3.748 3.051-6.8 6.8-6.8 3.748 0 6.8 3.051 6.8 6.8 0 3.748-3.052 6.8-6.8 6.8Z"
      fill={props.backgroundColor || '#AB5337'}
    />
  </svg>
)
