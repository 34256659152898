import { NavBarEvent, track, TranslationOutlined } from '@anyonelab/common/'
import { useI18nSlice } from '@anyonelab/frontend/app/providers/LanguageProvider/slice'
import { selectLocale } from '@anyonelab/frontend/app/providers/LanguageProvider/slice/selectors'
import localStorageKey from '@anyonelab/frontend/types/localStorageKey'
import { Menu, message } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import messages from './messages'

interface IChangeLanguageButton {
  bottomNavigation?: boolean
}

export function ChangeLanguageButton(props: IChangeLanguageButton) {
  const intl = useIntl()

  const locale = useSelector(selectLocale)

  const { actions } = useI18nSlice()

  const dispatch = useDispatch()

  const { bottomNavigation = false } = props

  const changeLocale = () => {
    if (locale === 'en') {
      dispatch(actions.setLocale('zh'))
      dispatch(actions.setFullLocale('zh-HK'))
      localStorage.setItem(localStorageKey.locale, 'zh')
      localStorage.setItem(localStorageKey.fullLocale, 'zh-HK')
      track(NavBarEvent.changeLanguageClick, { toLanguage: 'zh' })
      message.success(
        intl.formatMessage(messages.languageIsNowChangedToChinese),
      )
    } else {
      dispatch(actions.setLocale('en'))
      dispatch(actions.setFullLocale('en-US'))
      localStorage.setItem(localStorageKey.locale, 'en')
      localStorage.setItem(localStorageKey.fullLocale, 'en-US')
      track(NavBarEvent.changeLanguageClick, { toLanguage: 'en' })
      message.success(
        intl.formatMessage(messages.languageIsNowChangedToEnglish),
      )
    }
  }

  return bottomNavigation ? (
    <TranslationOutlined onClick={changeLocale} />
  ) : (
    <Menu.Item icon={<TranslationOutlined />} onClick={changeLocale}>
      {intl.formatMessage(messages.changeLanguage)}
    </Menu.Item>
  )
}
