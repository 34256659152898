import { useGetUsers } from '@anyonelab/frontend/api/hooks/Users/useGetUsers'
import React from 'react'
import { User } from '../../../models/User'
import { FirebaseAuthContext } from '../FirebaseAuthProvider/FirebaseAuthProvider'
import { UserContext } from './UserContext'
import { createEarlyBirdBetaTester } from '@anyonelab/frontend/api/boba-api/EarlyBirdBetaTesterAPI'

export const UserContextProvider = ({ children }) => {
  let params = new URL(window.location.href).searchParams
  let event = params.get('event')
  const isBobaEarlyBirdUserRef = React.useRef(event === 'bobame_early_bird')

  const [userData, setUserData] = React.useState<User>({})
  const { isAuthenticated } = React.useContext(FirebaseAuthContext)

  const {
    data: reactQueryUserData,
    isLoading: isLoadingUserFetching,
    refetch,
  } = useGetUsers()

  React.useEffect(() => {
    reactQueryUserData?.data && setUserData(reactQueryUserData.data[0])
  }, [reactQueryUserData])

  React.useEffect(() => {
    refetch()
  }, [isAuthenticated])

  // * for those boba user access boba early bird link to get beta testers
  // ! need refactor otherwise so many request and cause heavy loading in server for every refresh
  React.useEffect(() => {
    if (isAuthenticated && isBobaEarlyBirdUserRef.current) {
      createEarlyBirdBetaTester()
    }
  }, [isAuthenticated])

  return (
    <UserContext.Provider
      value={{
        userData: userData,
        isLoadingUserFetching,
        setUserData: setUserData,
        isBobaEarlyBirdUser: isBobaEarlyBirdUserRef.current,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
