import React, { useState } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartArea,
  Filler,
} from 'chart.js'
import styled, { useTheme } from 'styled-components/macro'
import moment from 'moment'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { PageViewDistribution } from '@anyonelab/frontend/types/DataInsight'
import { NotifyBlock } from '../NotifyBlock'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { useIntl } from 'react-intl'
import messages from './messages'

type PageViewChartProps = {
  data: PageViewDistribution[] | null
  locked: boolean
} & React.HTMLAttributes<HTMLDivElement>

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  Filler,
)

export const PageViewChart = (props: PageViewChartProps) => {
  // const chartRef = React.useRef<ChartJSOrUndefined<
  //   'bar',
  //   number[],
  //   string[]
  // > | null>(null)
  const intl = useIntl()
  const [chartData, setChartData] = React.useState({
    label: props.data?.map((item) => [
      moment(item.date).format('M/D'),
      moment(item.date).format('ddd'),
    ]),
    dataset: props.data?.map((item) => item.number_of_view),
  })

  // NOTE: Need to find the right way to do it.
  React.useEffect(() => {
    setTimeout(() => {
      setChartData({
        label: props.data?.map((item) => [
          moment(item.date).format('M/D'),
          intl.formatMessage(messages[moment(item.date).format('ddd')]),
        ]),
        dataset: props.data?.map((item) => item.number_of_view),
      })
    }, 0)
  }, [props.data])

  const theme = useTheme()

  const getGradient = (
    ctx: CanvasRenderingContext2D,
    chartArea: ChartArea,
    barHeight: number,
  ) => {
    const gradientBg = ctx.createLinearGradient(
      0,
      barHeight,
      0,
      chartArea.bottom,
    )
    gradientBg.addColorStop(0, 'rgba(39, 204, 223,1)')
    gradientBg.addColorStop(1, 'rgba(39, 204, 223,0)')
    return gradientBg
  }

  const responseData = props.data

  if (!responseData || !chartData.dataset || !chartData.label) {
    return <></>
  }

  // const chartLabel = responseData.map((item) => [
  //   moment(item.date).format('M/D'),
  //   moment(item.date).format('ddd'),
  // ])
  // const chartData = responseData.map((item) => item.number_of_view)

  return (
    <Container {...props}>
      {props.locked ? (
        <NotifyBlock
          themeColor={theme.colors.pageViewGradient}
          content={'page_view'}
        />
      ) : (
        <Bar
          redraw
          options={{
            plugins: {
              legend: { display: false },
              datalabels: {
                anchor: 'end',
                align: 'end',
                color: '#26CCDD',
                font: {
                  family: 'Roboto',
                  weight: 600,
                },
              },
            },
            responsive: true,
            // animation: false,
            animations: { show: { from: 0, to: 1 } },
            scales: {
              x: {
                ticks: {
                  maxRotation: 0,
                  maxTicksLimit: chartData.dataset.length > 8 ? 4 : 11,
                  color: '#a7b0b9',
                  font: {
                    family: 'Roboto',
                  },
                },
                grid: {
                  display: false,
                  borderColor: '#f2f2f2',
                },
              },
              y: {
                ticks: {
                  color: '#BDCADB',
                  font: {
                    family: 'Montserrat',
                    weight: '700',
                  },
                },
                grid: {
                  color: '#f2f2f2',
                  drawBorder: false,
                },
                suggestedMax: Math.max(...chartData.dataset) * 1.1,
              },
            },
          }}
          data={{
            labels: chartData.label,
            datasets: [
              {
                label: 'Page Views',
                data: chartData.dataset,
                barThickness: 12,
                backgroundColor: ({ chart }) => {
                  const numberOf7DaysData = 8
                  const data = (chart.getDatasetMeta(0)
                    .data as any) as BarElement[]
                  if (
                    !chart.chartArea ||
                    !data.every((value) => value.y !== undefined)
                  )
                    return '#27CCDF'
                  if (data.length <= numberOf7DaysData) {
                    return '#27CCDF'
                  } else {
                    return data.map((item, index) => {
                      return index % 4 ? '#27CCDF' : '#FFBE7C'
                    })
                  }
                  // return data.map((item) =>
                  //   getGradient(chart.ctx, chart.chartArea, item.y),
                  // )
                },
              },
            ],
          }}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
