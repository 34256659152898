import React, { PropsWithChildren } from 'react'
import localStorageKey from '@anyonelab/frontend/types/localStorageKey'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { useI18nSlice } from '../../providers/LanguageProvider/slice'
import { selectLocale } from '../../providers/LanguageProvider/slice/selectors'
import { message, NavBarEvent, track, useResponsive } from '@anyonelab/common'
import messages from '../../components/LoginForm/components/ChangeLanguageButton/messages'

const LanguageFunctionContext = React.createContext<{
  changeLocale: () => void
}>({
  changeLocale: () => undefined,
})

export const useLanguageFunctionContext = () =>
  React.useContext(LanguageFunctionContext)

interface LanguageFunctionProviderProps {}

function LanguageFunctionProvider({
  children,
}: PropsWithChildren<LanguageFunctionProviderProps>) {
  const intl = useIntl()
  const locale = useSelector(selectLocale)

  const { actions } = useI18nSlice()

  const dispatch = useDispatch()

  const changeLocale = () => {
    if (locale === 'en') {
      dispatch(actions.setLocale('zh'))
      dispatch(actions.setFullLocale('zh-HK'))
      localStorage.setItem(localStorageKey.locale, 'zh')
      localStorage.setItem(localStorageKey.fullLocale, 'zh-HK')
      track(NavBarEvent.changeLanguageClick, { toLanguage: 'zh' })
      message.success(
        intl.formatMessage(messages.languageIsNowChangedToChinese),
      )
    } else {
      dispatch(actions.setLocale('en'))
      dispatch(actions.setFullLocale('en-US'))
      localStorage.setItem(localStorageKey.locale, 'en')
      localStorage.setItem(localStorageKey.fullLocale, 'en-US')
      track(NavBarEvent.changeLanguageClick, { toLanguage: 'en' })
      message.success(
        intl.formatMessage(messages.languageIsNowChangedToEnglish),
      )
    }
  }

  return (
    <LanguageFunctionContext.Provider value={{ changeLocale: changeLocale }}>
      {children}
    </LanguageFunctionContext.Provider>
  )
}

export default LanguageFunctionProvider
