export const checkJsonString = (input: string): object | null => {
  try {
    const output = JSON.parse(input)
    if (output && typeof output === 'object') {
      return output
    }
  } catch (error) {
    console.error(error)
  }
  return null
}
