// adding this from envs does not work, possibly because envs aren't loaded on this step yet
const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID

export default function initFacebookSDK() {
  return new Promise<void>((resolve) => {
    // Wait for the Facebook SDK to initialize before starting the React app.
    window['fbAsyncInit'] = function () {
      FB.init({
        appId: FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v12.0',
      })

      resolve()
    }

    // Load the Facebook SDK script.
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode?.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  })
}
