import * as React from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import messages from '@anyonelab/frontend/app/components/LoginForm/components/AuthErrors/messages'

const ERROR_MESSAGES_FROM_FIREBASE = Object.freeze({
  accountExistWithDifferentCredentials:
    'auth/account-exists-with-different-credential',
})

export interface AuthErrorsProps {
  errorMessage: string
}

export const AuthErrors = ({ errorMessage }: AuthErrorsProps) => {
  const [intlError, setIntlError] = React.useState<string | null>(null)
  const intl = useIntl()

  React.useEffect(() => {
    if (
      errorMessage.includes(
        ERROR_MESSAGES_FROM_FIREBASE.accountExistWithDifferentCredentials,
      )
    ) {
      setIntlError(
        intl.formatMessage(messages.accountExistWithDifferentCredentialsError),
      )
    } else {
      setIntlError(intl.formatMessage(messages.genericAuthenticationError))
    }
  }, [errorMessage, intl])

  return <AuthErrorsStyled>{intlError}</AuthErrorsStyled>
}

const AuthErrorsStyled = styled.div`
  padding-top: 1rem;
  color: red;
`
