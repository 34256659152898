import { defineMessages } from 'react-intl'

export default defineMessages({
  title: { defaultMessage: 'Cash out' },
  description: {
    defaultMessage: "You'll get {currency} {number} in your bank.",
  },
  location: { defaultMessage: 'Location' },
  bankAccount: { defaultMessage: 'Bank Account' },
  bankCode: { defaultMessage: 'Bank code' },
  accountNumber: { defaultMessage: 'Account number' },
  cashout: { defaultMessage: 'Cash out' },
  contactWithinMessage: {
    defaultMessage:
      'We will contact you within {number, plural, =0 {today} =1 {# working day} other {# working days}}!',
  },
  numberAlert: { defaultMessage: 'Please enter correct number.' },
  email: { defaultMessage: 'Email' },
})
