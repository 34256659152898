import { getToken } from './api-client'
import store from 'storejs'
import { eraseCookie, getCookie, setCookie } from './cookies'

export async function storeAccessToken() {
  const token = await getToken()
  if (token) {
    setCookie('accessToken', token, 90)
    // store('accessToken', token)
  } else {
    console.error('not token')
  }
}

export function removeAccessToken() {
  eraseCookie('accessToken')
  // store.remove('accessToken')
}

export function getAccessToken() {
  return getCookie('accessToken')
  // return store.get('accessToken')
}
