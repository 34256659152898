import React from 'react'
import {
  BobaResponsiveModal,
  BobaResponsiveModalProps,
} from './BobaResponsiveModal'
import WelcomeNotification from './PopupContentComponent/WelcomeNotification'
import { WelcomeShakerNotification } from './PopupContentComponent/WelcomeShakerNotification'

interface BobaWelcomePopupModalProps extends BobaResponsiveModalProps {
  domain: string
  avatarImageUrl?: string
}

export function BobaWelcomePopupModal({
  domain,
  avatarImageUrl,
  ...props
}: BobaWelcomePopupModalProps) {
  const [isShakerModal, setIsShakerModal] = React.useState(false)
  return (
    <BobaResponsiveModal closeIconVisible={false} {...props}>
      {isShakerModal ? (
        <WelcomeShakerNotification onClose={props.onClose} />
      ) : (
        <WelcomeNotification
          domain={domain}
          avatarImageUrl={avatarImageUrl}
          buttonOnClick={() => {
            setIsShakerModal(true)
          }}
        />
      )}
    </BobaResponsiveModal>
  )
}
