import React from 'react'

type ChipsInPackageProps = {} & React.SVGProps<SVGSVGElement>

export const ChipsInPackage = (props: ChipsInPackageProps) => {
  return (
    <svg
      width="129"
      height="140"
      viewBox="0 0 129 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M127.466 127.106L122.087 80.3445C120.875 71.5315 125.364 39.3568 127.011 27.8278C127.39 25.1497 127.428 24.2951 127.428 24.2951C123.47 24.0102 23.8645 24.0672 23.8645 24.0672C23.8645 24.0672 30.5692 69.2524 29.0161 80.1166L23.3341 126.821C23.3341 126.821 128.034 126.536 127.466 127.106Z"
          fill="url(#paint0_linear_1286_9029)"
        />
        <path
          id="Vector_2"
          d="M126.973 24.2787L128.053 16.0166C128.072 15.8902 128.056 15.7609 128.006 15.6433C127.956 15.5258 127.874 15.4246 127.769 15.3514C127.665 15.2782 127.542 15.2359 127.415 15.2292C127.288 15.2226 127.161 15.252 127.049 15.3138C121.576 18.1059 115.856 14.0222 114.435 12.8826C114.326 12.8091 114.198 12.77 114.066 12.77C113.934 12.77 113.806 12.8091 113.697 12.8826C111.585 14.1787 109.097 14.7172 106.64 14.4101C104.183 14.103 101.903 12.9685 100.173 11.1922C100.057 11.1013 99.9138 11.0519 99.7663 11.0519C99.6187 11.0519 99.4755 11.1013 99.3591 11.1922C92.3703 15.1428 86.3662 10.2616 84.851 8.85606C84.7346 8.76519 84.5914 8.71579 84.4439 8.71579C84.2964 8.71579 84.1529 8.76519 84.0365 8.85606C81.8963 10.1735 79.3662 10.7045 76.8788 10.358C74.3913 10.0115 72.1011 8.80926 70.3999 6.95672C70.2789 6.85963 70.1287 6.80671 69.9738 6.80671C69.8188 6.80671 69.6686 6.85963 69.5476 6.95672C67.3543 8.47668 64.6755 9.12411 62.0325 8.77306C59.3894 8.422 56.971 7.09756 55.2479 5.05739C55.1285 4.9569 54.9777 4.90181 54.8218 4.90181C54.6659 4.90181 54.5151 4.9569 54.3957 5.05739C47.8803 9.04599 42.0847 4.50658 40.6073 3.15805C40.4875 3.07557 40.3456 3.03146 40.2002 3.03146C40.0548 3.03146 39.9127 3.07557 39.7928 3.15805C37.8632 4.56723 35.474 5.19352 33.1035 4.91178C30.733 4.63004 28.5561 3.46097 27.0084 1.63849C26.92 1.54373 26.8067 1.47626 26.6814 1.44374C26.5562 1.41121 26.4243 1.41503 26.3011 1.45486C26.178 1.4947 26.0687 1.56884 25.986 1.66863C25.9033 1.76843 25.8506 1.88981 25.8341 2.0185L24.6219 11.3441C24.6085 11.4315 24.6127 11.5207 24.6342 11.6065C24.6556 11.6923 24.6941 11.773 24.747 11.8437C24.7999 11.9145 24.8662 11.9739 24.9424 12.0185C25.0185 12.0632 25.1028 12.0922 25.1902 12.1038C33.5806 13.1675 117.693 23.7849 126.197 25.2663C126.326 25.3013 126.462 25.2975 126.589 25.2554C126.716 25.2134 126.828 25.1348 126.91 25.0296C126.993 24.9244 127.043 24.797 127.054 24.6635C127.066 24.53 127.037 24.3963 126.973 24.2787Z"
          fill="#FCD69B"
          stroke="#FCD69B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_3"
          d="M23.8265 128.587V136.925C23.8274 137.051 23.8625 137.173 23.9283 137.28C23.994 137.387 24.0876 137.473 24.199 137.53C24.3105 137.587 24.4355 137.612 24.5601 137.602C24.6848 137.593 24.8045 137.549 24.906 137.476C29.9819 134.038 36.27 137.362 37.7474 138.293C37.8545 138.371 37.9838 138.414 38.1166 138.414C38.2494 138.414 38.3789 138.371 38.486 138.293C40.4223 136.75 42.8222 135.911 45.2949 135.911C47.7676 135.911 50.1675 136.75 52.1038 138.293C52.2171 138.39 52.3617 138.444 52.5111 138.444C52.6605 138.444 52.8049 138.39 52.9183 138.293C59.3579 133.487 65.93 137.571 67.5967 138.767C67.7147 138.863 67.8617 138.915 68.0134 138.915C68.165 138.915 68.312 138.863 68.43 138.767C70.3836 137.184 72.828 136.337 75.3394 136.371C77.8508 136.406 80.271 137.321 82.1804 138.957C82.3057 139.053 82.4586 139.105 82.616 139.105C82.7733 139.105 82.9265 139.053 83.0518 138.957C85.0253 137.17 87.5897 136.181 90.2488 136.181C92.9079 136.181 95.4726 137.17 97.4461 138.957C97.5685 139.059 97.7226 139.115 97.8817 139.115C98.0408 139.115 98.1949 139.059 98.3173 138.957C104.283 134.152 110.609 137.932 112.238 139.071C112.356 139.159 112.499 139.207 112.645 139.207C112.792 139.207 112.935 139.159 113.053 139.071C114.789 137.427 117.081 136.502 119.47 136.481C121.858 136.46 124.166 137.344 125.932 138.957C126.03 139.04 126.15 139.092 126.278 139.109C126.405 139.126 126.534 139.107 126.651 139.053C126.768 138.999 126.867 138.914 126.938 138.806C127.008 138.699 127.047 138.573 127.049 138.444V128.948C127.049 128.766 126.977 128.593 126.85 128.464C126.722 128.336 126.548 128.264 126.367 128.264C117.92 128.264 33.126 128.416 24.5273 128.017C24.3603 128.006 24.1953 128.058 24.0654 128.164C23.9354 128.27 23.8501 128.421 23.8265 128.587V128.587Z"
          fill="#FCD69B"
          stroke="#FCD69B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_4"
          d="M126.973 128.888L23.8644 128.622C23.7338 128.622 23.6083 128.57 23.516 128.478C23.4236 128.385 23.3719 128.259 23.3719 128.128V124.33C23.3719 124.199 23.4236 124.073 23.516 123.98C23.6083 123.888 23.7338 123.836 23.8644 123.836L126.992 124.102C127.257 124.102 127.276 124.71 127.276 124.995L127.485 128.318C127.493 128.39 127.486 128.463 127.464 128.532C127.441 128.601 127.405 128.664 127.357 128.718C127.308 128.772 127.249 128.815 127.183 128.844C127.117 128.874 127.046 128.888 126.973 128.888V128.888Z"
          fill="#F7BA69"
        />
        <path
          id="Vector_5"
          d="M126.973 27.7318L24.3566 27.466C24.2242 27.4661 24.0971 27.4146 24.0018 27.3225C23.9064 27.2304 23.8504 27.1049 23.8455 26.9722V23.1735C23.8455 23.0426 23.8971 22.917 23.9895 22.8244C24.0818 22.7318 24.2073 22.6797 24.3379 22.6797L126.973 22.9456C127.239 22.9456 127.466 23.4015 127.466 23.6674L127.22 26.5164C127.239 26.8583 127.239 27.7318 126.973 27.7318Z"
          fill="#F7BA69"
        />
        <path
          id="Vector_6"
          opacity="0.2"
          d="M110.458 27.6722L25.0773 27.448C24.5124 27.4465 24.0532 27.9046 24.0518 28.471L24.0517 28.49C24.0502 29.0565 24.507 29.517 25.0719 29.5184L110.453 29.7426C111.018 29.7441 111.477 29.286 111.478 28.7196L111.478 28.7006C111.48 28.1341 111.023 27.6736 110.458 27.6722Z"
          fill="#F7BA69"
        />
        <path
          id="Vector_7"
          opacity="0.2"
          d="M111.616 18.7348L26.1524 7.78624C25.4572 7.69718 24.8218 8.19 24.733 8.88715L24.5266 10.5076C24.4378 11.2048 24.9292 11.8419 25.6244 11.931L111.088 22.8796C111.783 22.9686 112.418 22.4758 112.507 21.7787L112.714 20.1582C112.803 19.4611 112.311 18.8239 111.616 18.7348Z"
          fill="#F7BA69"
        />
        <path
          id="Vector_8"
          d="M1.59096 4.36276C2.46962 4.36276 3.18191 3.64845 3.18191 2.76732C3.18191 1.88618 2.46962 1.17188 1.59096 1.17188C0.712295 1.17188 0 1.88618 0 2.76732C0 3.64845 0.712295 4.36276 1.59096 4.36276Z"
          fill="#F7BA69"
        />
        <path
          id="Vector_9"
          d="M11.004 7.06588C11.8827 7.06588 12.595 6.35158 12.595 5.47044C12.595 4.5893 11.8827 3.875 11.004 3.875C10.1254 3.875 9.41309 4.5893 9.41309 5.47044C9.41309 6.35158 10.1254 7.06588 11.004 7.06588Z"
          fill="#F7BA69"
        />
        <path
          id="Vector_10"
          d="M6.62904 13.9253C7.5077 13.9253 8.22 13.211 8.22 12.3298C8.22 11.4487 7.5077 10.7344 6.62904 10.7344C5.75038 10.7344 5.03809 11.4487 5.03809 12.3298C5.03809 13.211 5.75038 13.9253 6.62904 13.9253Z"
          fill="#F7BA69"
        />
        <path
          id="Vector_11"
          d="M14.5648 21.0971C15.4435 21.0971 16.1558 20.3828 16.1558 19.5017C16.1558 18.6206 15.4435 17.9062 14.5648 17.9062C13.6862 17.9062 12.9739 18.6206 12.9739 19.5017C12.9739 20.3828 13.6862 21.0971 14.5648 21.0971Z"
          fill="#F7BA69"
        />
        <path
          id="Vector_12"
          opacity="0.2"
          d="M113.924 12.3281C113.924 12.3281 114.833 12.784 116.178 13.5818C117.734 14.6482 119.514 15.3388 121.381 15.5991C123.247 15.8594 125.149 15.6826 126.936 15.0823C128.83 14.3795 128.261 16.7536 128.413 15.8799L124.625 42.9076L121.462 77.2095C121.462 77.2095 128.886 129.992 127.333 134.019C127.333 134.019 128.583 139.603 125.799 138.767C123.015 137.931 120.477 134.569 112.106 138.919C112.106 138.919 105.211 78.8049 105.552 77.1524C105.893 75.5 113.242 13.9426 113.924 12.3281Z"
          fill="url(#paint1_linear_1286_9029)"
        />
        <path
          id="Vector_13"
          d="M77.7485 97.2451C81.6822 95.0863 85.7499 93.183 89.9268 91.547C94.7375 89.6477 99.9081 87.6154 105.079 87.7484C110.003 87.8623 118.034 91.2621 118.128 97.2451C118.089 97.9883 117.902 98.7159 117.576 99.3848C117.251 100.054 116.794 100.65 116.234 101.139C106.764 110.312 90.3815 106.172 80.8926 99.6192L77.7485 97.2451Z"
          fill="#F7BA69"
        />
        <path
          id="Vector_14"
          d="M38.8837 75.5612C36.9897 54.3077 61.1761 38.8471 78.26 53.7379C84.1503 58.8851 87.8624 65.9695 91.8208 72.5982C95.7793 79.2268 102.314 85.8937 109.757 88.4578V88.5907C96.9158 93.4529 84.7942 99.9866 70.8544 100.879C55.0774 101.886 40.5505 92.2754 38.8837 75.6183V75.5612Z"
          fill="#FCD69B"
        />
        <path
          id="Vector_15"
          d="M55.2289 65.3025L57.1229 66.9739C57.2055 67.0378 57.2723 67.1198 57.3183 67.2137C57.3643 67.3075 57.3881 67.4107 57.3881 67.5153C57.3881 67.6199 57.3643 67.7231 57.3183 67.8169C57.2723 67.9108 57.2055 67.9928 57.1229 68.0567C56.5942 68.4891 55.9414 68.7406 55.2599 68.7747C54.5785 68.8089 53.9041 68.6239 53.3349 68.2466C52.9159 68.0227 52.5473 67.7148 52.2518 67.3421C51.9563 66.9694 51.7402 66.5398 51.6171 66.0799C51.494 65.62 51.4665 65.1396 51.5362 64.6686C51.6059 64.1976 51.7712 63.7462 52.0222 63.342C52.2731 62.9377 52.6044 62.5894 52.9951 62.3188C53.3858 62.0482 53.8277 61.861 54.2935 61.7688C54.7593 61.6766 55.239 61.6815 55.7029 61.783C56.1668 61.8844 56.6052 62.0804 56.9905 62.3587C57.478 62.6542 57.8796 63.0727 58.1555 63.5727C58.4313 64.0726 58.5717 64.6364 58.5624 65.2077C58.568 65.3134 58.5491 65.4189 58.5071 65.516C58.4651 65.6132 58.4011 65.699 58.3203 65.7671C58.2395 65.8353 58.1441 65.8837 58.0415 65.9086C57.9389 65.9334 57.8318 65.9341 57.7289 65.9104L55.2289 65.3025Z"
          fill="#62412A"
        />
        <path
          id="Vector_16"
          d="M77.5782 73.9979L79.4722 75.6694C79.5547 75.7332 79.6215 75.8152 79.6675 75.9091C79.7135 76.0029 79.7374 76.1059 79.7374 76.2105C79.7374 76.3151 79.7135 76.4183 79.6675 76.5121C79.6215 76.606 79.5547 76.688 79.4722 76.7519C78.9434 77.1843 78.2906 77.436 77.6092 77.4701C76.9277 77.5043 76.2533 77.3191 75.6842 76.9418C75.2651 76.7179 74.8963 76.41 74.6008 76.0373C74.3053 75.6646 74.0892 75.235 73.9661 74.7751C73.843 74.3152 73.8155 73.835 73.8852 73.364C73.9549 72.893 74.1205 72.4414 74.3714 72.0371C74.6224 71.6329 74.9534 71.2848 75.3441 71.0142C75.7348 70.7436 76.1769 70.5565 76.6427 70.4643C77.1086 70.3721 77.5882 70.3767 78.0521 70.4782C78.516 70.5796 78.9542 70.7756 79.3395 71.0539C79.8297 71.3465 80.2336 71.7645 80.5098 72.2651C80.786 72.7657 80.9248 73.3309 80.9116 73.9029C80.9172 74.0086 80.8981 74.1141 80.8561 74.2112C80.8141 74.3083 80.7503 74.3944 80.6696 74.4626C80.5888 74.5307 80.4933 74.5789 80.3907 74.6038C80.2881 74.6286 80.181 74.6293 80.0781 74.6056L77.5782 73.9979Z"
          fill="#62412A"
        />
        <path
          id="Vector_17"
          d="M66.2331 79.0279C65.9281 79.0299 65.6254 78.9719 65.3427 78.857C64.4363 78.3684 63.7565 77.5436 63.4487 76.5589C62.9787 76.796 62.4599 76.9194 61.9337 76.9194C61.4075 76.9194 60.8885 76.796 60.4184 76.5589C60.0247 76.2917 59.6983 75.9364 59.4649 75.5211C59.2315 75.1058 59.0977 74.6418 59.0737 74.1657C59.0563 74.0545 59.0629 73.9408 59.0934 73.8325C59.1239 73.7242 59.1775 73.624 59.2504 73.5385C59.3233 73.453 59.4139 73.3841 59.5158 73.337C59.6177 73.2899 59.7286 73.2656 59.8408 73.2656C59.953 73.2656 60.0638 73.2899 60.1657 73.337C60.2676 73.3841 60.3582 73.453 60.4311 73.5385C60.504 73.624 60.5576 73.7242 60.5881 73.8325C60.6186 73.9408 60.6252 74.0545 60.6077 74.1657C60.6179 74.3895 60.6755 74.6086 60.7767 74.8084C60.878 75.0082 61.0206 75.1841 61.195 75.3242C61.6685 75.5901 62.5018 75.4383 63.5625 74.9064C63.6686 74.8544 63.7851 74.8274 63.9033 74.8274C64.0214 74.8274 64.1381 74.8544 64.2443 74.9064C64.3469 74.9611 64.4368 75.0365 64.5086 75.1281C64.5803 75.2197 64.6323 75.3252 64.6609 75.4381C64.7556 75.8939 65.2103 77.2236 65.9489 77.5275C66.0626 77.5275 66.4412 77.7364 67.1988 77.2236C67.2834 77.1687 67.3781 77.131 67.4772 77.1128C67.5762 77.0945 67.6779 77.096 67.7763 77.1172C67.8748 77.1383 67.9681 77.1787 68.051 77.2361C68.1339 77.2934 68.2048 77.3667 68.2596 77.4515C68.3143 77.5363 68.3519 77.6311 68.3701 77.7304C68.3883 77.8298 68.3868 77.9317 68.3657 78.0304C68.3446 78.1292 68.3043 78.2229 68.2471 78.3061C68.1899 78.3892 68.1168 78.4604 68.0323 78.5152C67.4963 78.861 66.8702 79.0392 66.2331 79.0279V79.0279Z"
          fill="#62412A"
        />
        <path
          id="Vector_18"
          d="M86.6124 88.9125C87.0831 88.9125 87.4647 88.5299 87.4647 88.0578C87.4647 87.5858 87.0831 87.2031 86.6124 87.2031C86.1417 87.2031 85.7601 87.5858 85.7601 88.0578C85.7601 88.5299 86.1417 88.9125 86.6124 88.9125Z"
          fill="#F7BA69"
        />
        <path
          id="Vector_19"
          d="M90.9497 85.9125C91.4204 85.9125 91.802 85.5299 91.802 85.0578C91.802 84.5858 91.4204 84.2031 90.9497 84.2031C90.479 84.2031 90.0974 84.5858 90.0974 85.0578C90.0974 85.5299 90.479 85.9125 90.9497 85.9125Z"
          fill="#F7BA69"
        />
        <path
          id="Vector_20"
          d="M92.2944 90.0688C92.7651 90.0688 93.1467 89.6861 93.1467 89.2141C93.1467 88.742 92.7651 88.3594 92.2944 88.3594C91.8237 88.3594 91.4421 88.742 91.4421 89.2141C91.4421 89.6861 91.8237 90.0688 92.2944 90.0688Z"
          fill="#F7BA69"
        />
        <path
          id="Vector_21"
          d="M97.9763 89.2094C98.447 89.2094 98.8286 88.8267 98.8286 88.3547C98.8286 87.8827 98.447 87.5 97.9763 87.5C97.5056 87.5 97.124 87.8827 97.124 88.3547C97.124 88.8267 97.5056 89.2094 97.9763 89.2094Z"
          fill="#F7BA69"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1286_9029"
          x1="33.9039"
          y1="39.8829"
          x2="112.99"
          y2="107.733"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E08686" />
          <stop offset="0.52" stop-color="#E79281" />
          <stop offset="1" stop-color="#EB9B7E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1286_9029"
          x1="101.148"
          y1="79.1592"
          x2="138.759"
          y2="72.2285"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E08686" />
          <stop offset="0.52" stop-color="#E79281" />
          <stop offset="1" stop-color="#EB9B7E" />
        </linearGradient>
      </defs>
    </svg>
  )
}
