import { PageViewDistribution } from '@anyonelab/frontend/types/DataInsight'
import { Table } from 'antd'
import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { transformDateToDMFormat } from '../../utils/dataInsightParser'
import { LockOverlay } from '../LockOverlay'
import message from './messages'

interface PageViewTableComponentProps {
  data: PageViewDistribution[]
  locked: boolean
}

function PageViewTableComponent({ data, locked }: PageViewTableComponentProps) {
  const intl = useIntl()
  const columns = [
    {
      title: intl.formatMessage(message.date),
      key: 'date',
      dataIndex: 'date',
      align: 'center',
      render: (value) => <>{transformDateToDMFormat(value) || value}</>,
      width: '10%', // * here width is the ratio of different columns
    },
    {
      title: intl.formatMessage(message.views),
      key: 'views',
      dataIndex: 'views',
      align: 'center',
      width: '20%',
    },
  ]

  let total = 0
  const dataSource = data.map((row, index) => {
    total += row.number_of_view
    return {
      date: row.date,
      views: row.number_of_view,
    }
  })

  dataSource.push({
    date: intl.formatMessage(message.total),
    views: total,
  })

  return (
    <Container>
      <TableContainer>
        <div className="title">
          {intl.formatMessage(message.dailyPageViews)}
        </div>
        <Table
          columns={columns}
          pagination={false}
          bordered={false}
          dataSource={dataSource}
        />
      </TableContainer>
      <LockOverlay locked={locked} />
    </Container>
  )
}

export default PageViewTableComponent

const Container = styled.div`
  position: relative;
`

const TableContainer = styled.div`
  font-family: 'Roboto';
  margin-top: 20px;
  background-color: 'yellow';

  .title {
    font-family: 'Montserrat';
    color: #bdcadb;
    margin-bottom: 20px;
  }

  /* all head */
  .ant-table-thead > tr > th {
    white-space: nowrap;
    background: #ffffff;
    color: #6c7b8a;
    font-weight: 600;
    border-right: solid 2px ${({ theme }) => theme.colors.background};
    border-bottom: solid 2px ${({ theme }) => theme.colors.background};
    border-top: none;
  }

  /* all box */
  .ant-table-tbody > tr > td {
    white-space: nowrap;
    background: #ffffff;
    color: #6c7b8a;
    border-right: solid 2px ${({ theme }) => theme.colors.background};
    border-bottom: solid 2px ${({ theme }) => theme.colors.background};
  }

  /* last column border right header */
  .ant-table-thead > tr > th:last-child {
    border-right: none;
  }

  /* last column border right */
  .ant-table-tbody > tr > td:last-child {
    border-right: none;
  }

  /* Last row border bottom  */
  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }
`
