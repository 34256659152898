import {
  bobaBackgroundColor,
  HomeProfileLayout,
  Message as MessageType,
  BobaWelcomePopupModal,
  BobaDonationDetail,
} from '@anyonelab/common'
import {
  postBobaDonationComments,
  patchBobaDonationComments,
  removeBobaDonationComments,
} from '@anyonelab/frontend/api/boba-api/BobaDonationCommentAPI'
import { createEarlyBirdBetaTester } from '@anyonelab/frontend/api/boba-api/EarlyBirdBetaTesterAPI'
import { useGetBobaDonationWithCommentInfo } from '@anyonelab/frontend/api/boba-api/hook/useGetBobaData'
import {
  GetBobaDonationWithCommentInfoResponse,
  GetBobaDontaionResponse,
  GetBobaUserAccountResponse,
} from '@anyonelab/frontend/api/boba-api/schema/boba-response.schema'
import React from 'react'
import { useBobaUser } from '../../providers/BobaUserProvider'
import { useLanguageFunctionContext } from '../../providers/LanguageProvider/LanguageFunctionProvider'
import { useUser } from '../../providers/UserContextProvider/UserContext'

function bobaMenuParse(
  bobaUserAccount: GetBobaUserAccountResponse,
): BobaDonationDetail[] {
  return bobaUserAccount?.boba_menu.map((bobaItem) => {
    return {
      ...bobaItem,
      count: 0,
    }
  })
}

// ! Temporarily use the parser until developer come up the working logic and schema
function bobaDonationParse(
  bobaUserAccount: GetBobaUserAccountResponse,
  donations: GetBobaDonationWithCommentInfoResponse[],
): MessageType[] {
  return donations
    .filter((donation) => {
      // * condition, has note,   is_display_by_user: true, is_public_by_supporter: true
      return donation.is_display_by_user && donation.is_public_by_supporter
    })
    .map((donation) => {
      return {
        donationId: donation?.id,
        content: donation?.donor_note || '',
        name: donation?.donor_name || '',
        count: donation.boba_count,
        value: donation.donated_amount,
        currency: bobaUserAccount.currency,
        creator_boba_user_account: donation.creator_boba_user_account,
        donor_boba_user_account: donation.donor_boba_user_account,
        boba_donation_comments: donation.boba_donation_comments,
      }
    })
}

const DEFAULT_SKIP_NUMBER = 10

export const HomePage = () => {
  const { isBobaEarlyBirdUser } = useUser()
  const [donationPage, setDonationPage] = React.useState(0)
  const { changeLocale } = useLanguageFunctionContext()
  const {
    bobaUserAccount,
    isNewUser,
    setIsNewUser,
    snackBioAvatarImageUrl,
  } = useBobaUser()

  const [bobaItems, setBobaItems] = React.useState<BobaDonationDetail[]>(
    bobaUserAccount ? bobaMenuParse(bobaUserAccount) : [],
  )

  const { data, isLoading, isPreviousData } = useGetBobaDonationWithCommentInfo(
    donationPage,
  )
  const [bobaDonationsMessage, setBobaDonationsMessage] = React.useState<
    MessageType[] | null
  >(null)

  const postComment = async (
    data: any,
    callback?: () => void,
    failCallback?: () => void,
  ) => {
    console.log('post data', data)
    try {
      if (!bobaUserAccount) return
      const parsedData = {
        boba_user_account_id: bobaUserAccount.id,
        boba_donation_id: data.donationId,
        message: data.message,
        is_active: true,
      }
      const result = await postBobaDonationComments(parsedData)
      result && callback && callback()
    } catch (err) {
      console.error(err)
      failCallback && failCallback()
    }
  }

  const editComment = async (
    id: number,
    data: any,
    callback?: () => void,
    failCallback?: () => void,
  ) => {
    try {
      if (!bobaUserAccount) return
      const parsedData = {
        message: data.message,
        is_active: true,
      }
      const result = await patchBobaDonationComments(id, parsedData)
      result && callback && callback()
    } catch (err) {
      console.error(err)
      failCallback && failCallback()
    }
  }

  const removeComment = async (
    id: number,
    data: any,
    callback?: () => void,
    failCallback?: () => void,
  ) => {
    try {
      if (!bobaUserAccount) return
      const parsedData = {
        message: data.message,
        is_active: false,
      }
      const result = await removeBobaDonationComments(id, parsedData)
      result && callback && callback()
    } catch (err) {
      console.error(err)
      failCallback && failCallback()
    }
  }

  React.useEffect(() => {
    if (data && !isLoading) {
      if (bobaUserAccount) {
        if (bobaDonationsMessage) {
          setBobaDonationsMessage((current) => [
            ...current!,
            ...bobaDonationParse(bobaUserAccount, data.data),
          ])
        } else {
          setBobaDonationsMessage(bobaDonationParse(bobaUserAccount, data.data))
        }
      }
    }
  }, [data])

  const donationMessageNextPage = () => {
    !isPreviousData &&
      donationPage < data?.total &&
      setDonationPage((current) => current + DEFAULT_SKIP_NUMBER)
  }

  // * for those non boba user just signup to be new user and access boba early bird link to get beta testers
  // ! need refactor otherwise so many request and cause heavy loading in server
  React.useEffect(() => {
    if (isBobaEarlyBirdUser && isNewUser) {
      createEarlyBirdBetaTester()
    }
  }, [])

  if (!bobaUserAccount) {
    return null
  }

  return (
    <>
      <HomeProfileLayout
        BobaUserInformation={bobaUserAccount}
        bobaItems={bobaItems}
        setBobaItems={setBobaItems}
        bobaDonationsMessage={bobaDonationsMessage}
        changeLocale={changeLocale}
        postComment={postComment}
        editComment={editComment}
        removeComment={removeComment}
        donationMessageNextPage={donationMessageNextPage}
      />
      <BobaWelcomePopupModal
        domain={bobaUserAccount.domain}
        avatarImageUrl={
          bobaUserAccount.avatar_image_url || snackBioAvatarImageUrl || ''
        }
        visible={isNewUser}
        onClose={() => {
          setIsNewUser(false)
        }}
      />
    </>
  )
}
