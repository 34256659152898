import { getDataInsight } from './../../DataInsightAPI'
import {
  AnalyticsInfoType,
  DataInsightResponse,
} from './../../../types/DataInsight'
import { useQuery, UseQueryResult } from 'react-query'
import { getBobaDonationBobaCount } from '../../boba-api/BobaDonationAPI'

export const useGetDataInsight = <AnalyticsType extends AnalyticsInfoType>(
  type: AnalyticsType,
): UseQueryResult<DataInsightResponse<AnalyticsType>, Error> => {
  return useQuery<DataInsightResponse<AnalyticsType>, Error>(
    ['data-insight', type], // this syntax is used to pass type in react query
    () => getDataInsight<AnalyticsType>(type),
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const useGetBobaDonationBobaCountInsight = (dayBefore: number = 7) => {
  return useQuery(
    ['boba-donation', dayBefore], // this syntax is used to pass type in react query
    () => getBobaDonationBobaCount(dayBefore),
    {
      refetchOnWindowFocus: false,
    },
  )
}
