import { Button, Divider } from 'antd-mobile'
import React from 'react'
import { useUser } from '../../providers/UserContextProvider/UserContext'
import AppCreatePage from './component/AppCreatePage'
import AppEditPage from './component/AppEditPage'

export function AdminMarketplaceApp() {
  const {
    userData: { is_admin },
  } = useUser()

  const [isAppEditPage, setIsAppEditPage] = React.useState(false)

  const toggleThemeEditPage = () => {
    setIsAppEditPage((prev) => !prev)
  }

  if (!is_admin) {
    return <h1>Not authorized account</h1>
  }

  return (
    <>
      <h1>
        {isAppEditPage ? 'Marketplace app List' : 'Marketplace app creator'}
      </h1>
      <Button onClick={toggleThemeEditPage}>
        {isAppEditPage ? 'App Generator' : 'Check existing app'}
      </Button>
      <Divider />

      {isAppEditPage ? (
        <>
          <AppEditPage />
        </>
      ) : (
        <AppCreatePage />
      )}
    </>
  )
}
