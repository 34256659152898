import { defineMessages } from 'react-intl'

export default defineMessages({
  title: { defaultMessage: 'Share your happiness!' },
  subtitle: {
    defaultMessage:
      'Say thanks to the supporter :){br}Download the pic and shout out on your instagram.',
  },
  downloadPicture: { defaultMessage: 'Download Pic' },
})
