import {
  BobaLoader,
  CashoutHistoryBoard,
  useResponsive,
} from '@anyonelab/common/'
import {
  useGetBobaPayout,
  useGetBobaUserAccount,
} from '@anyonelab/frontend/api/boba-api/hook/useGetBobaData'
import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import styled from 'styled-components/macro'
import messages from '../../messages'
import CountUp from 'react-countup'

const DEFAULT_PAGE_SIZE = 10

export const CashoutHistoryBoardPage = () => {
  const [pageNumber, setPageNumber] = React.useState(1)
  const { data, isLoading } = useGetBobaPayout(
    (pageNumber - 1) * DEFAULT_PAGE_SIZE,
  )
  const { data: accountData } = useGetBobaUserAccount()
  const queryClient = useQueryClient()
  const intl = useIntl()
  const { isMobileLayout } = useResponsive()

  const changePage = (currentPage: number, pageSize?: number) => {
    setPageNumber(currentPage)
  }
  const userCurrency = queryClient.getQueryData('boba-user-accounts').data[0]
    .currency

  if (isLoading) return <BobaLoader />

  return (
    <>
      <CashoutHistoryBoard
        currency={userCurrency}
        pagination={{
          current: pageNumber,
          defaultCurrent: 1,
          onChange: changePage,
          pageSize: DEFAULT_PAGE_SIZE,
          total: data?.total || 0,
        }}
        isLoading={isLoading}
        cashoutHistoryData={data?.data || []}
      />
      <Block className={isMobileLayout ? 'mobile' : 'desktop'}>
        <Title
          className={isMobileLayout ? 'mobile' : 'desktop'}
          children={intl.formatMessage(messages.totalReceivedMoney)}
        />
        <Amount
          className={isMobileLayout ? 'mobile' : 'desktop'}
          start={0}
          useEasing
          formattingFn={(value) => `$ ${value.toLocaleString()}`}
          end={accountData?.data[0].total_received_money || 0}
        />
      </Block>
    </>
  )
}

const Block = styled.div`
  display: flex;
  background-color: #fafafa;
  border-radius: 24px;
  justify-content: center;
  &.desktop {
    align-items: center;
    margin-top: 28px;
    padding: 24px 0;
    div:first-child {
      margin-right: 24px;
    }
  }
  &.mobile {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 14px;
    padding: 14px 0;
    div:nth-child(2) {
      margin-bottom: 16px;
    }
  }
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  color: #794e2e;
  font-weight: 600;
  &.desktop {
    font-size: 16px;
  }
  &.mobile {
    font-size: 14px;
  }
`

const Amount = styled(CountUp)`
  color: #ad5138;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-weight: 600;
  font-size: 20px;
  &.desktop {
  }
  &.mobile {
  }
`
