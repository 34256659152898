import { defineMessages } from 'react-intl'

export default defineMessages({
  total: {
    defaultMessage: 'Total {number}',
  },
  comparedToLastWeek: {
    defaultMessage: 'Compared to last week',
  },
})
