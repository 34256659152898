import { defineMessages } from 'react-intl'

export default defineMessages({
  accountExistWithDifferentCredentialsError: {
    defaultMessage:
      'You have an account with this email but with a different provider. Please log in using a different provider',
  },
  genericAuthenticationError: {
    defaultMessage: 'Something went wrong while logging in. Please try again',
  },
})
