import { WidgetType, PageWidgetType } from '@anyonelab/frontend/models/Widget'
import _ from 'lodash'
import { PageWidget } from '../models/Widget'

function getWidgetSpecificTypeData<T extends keyof WidgetType>(
  type: T,
  widgetList: PageWidgetType[],
): { data: WidgetType[T]; index: number } | null {
  let returnValue: WidgetType[T] | null = null

  widgetList.forEach((widgetData, index) => {
    if (widgetData.type === type) {
      returnValue = { data: widgetData.data, index }
    }
  })

  return returnValue
}

// new parser
export function themeParser(
  _currentData: PageWidget,
  _themeData: PageWidget,
): PageWidget {
  const currentData = _.cloneDeep(_currentData)
  const themeData = _.cloneDeep(_themeData)

  /** do something */
  let mergedData: PageWidget = {
    profileSplash: {},
    widgetList: [],
  }

  mergedData.profileSplash = themeData.profileSplash
    ? themeData.profileSplash
    : currentData.profileSplash

  currentData.widgetList.forEach((widgetData) => {
    switch (widgetData.type) {
      case 'avatar': {
        const data = widgetData.data

        mergedData.widgetList.push({
          id: widgetData.id,
          type: 'avatar',
          data,
        })
        break
      }
      case 'titleLabel': {
        const themeTitleLabel = getWidgetSpecificTypeData<'titleLabel'>(
          'titleLabel',
          themeData.widgetList,
        )

        const data = themeTitleLabel
          ? {
              ...widgetData.data,
              ...themeTitleLabel.data,
              text: widgetData.data.text,
            }
          : widgetData.data

        mergedData.widgetList.push({
          id: widgetData.id,
          type: 'titleLabel',
          data,
        })
        break
      }
      case 'subtitleLabel': {
        const themeSubtitleLabel = getWidgetSpecificTypeData<'subtitleLabel'>(
          'subtitleLabel',
          themeData.widgetList,
        )

        const data = themeSubtitleLabel
          ? {
              ...widgetData.data,
              ...themeSubtitleLabel.data,
              text: widgetData.data.text,
            }
          : widgetData.data

        mergedData.widgetList.push({
          id: widgetData.id,
          type: 'subtitleLabel',
          data,
        })
        break
      }
      case 'socialMediaButtonGroup': {
        const themeSocialMediaButtonGroup = getWidgetSpecificTypeData<
          'socialMediaButtonGroup'
        >('socialMediaButtonGroup', themeData.widgetList)

        let themeColor = widgetData.data.facebook?.color
        if (themeSocialMediaButtonGroup) {
          themeColor = Object.values(themeSocialMediaButtonGroup.data)[0].color
            ? Object.values(themeSocialMediaButtonGroup.data)[0].color
            : themeColor
        }

        const data = _.cloneDeep(widgetData.data)
        for (let key in data) {
          data[key].color = themeColor
        }

        mergedData.widgetList.push({
          id: widgetData.id,
          type: 'socialMediaButtonGroup',
          data,
        })
        break
      }
      case 'linkButton': {
        const themeLinkButton = getWidgetSpecificTypeData<'linkButton'>(
          'linkButton',
          themeData.widgetList,
        )

        const data = themeLinkButton
          ? {
              ...widgetData.data,
              ...themeLinkButton.data,
              type: widgetData.data.type,
              title: widgetData.data.title,
              url: widgetData.data.url,
            }
          : widgetData.data

        if (themeLinkButton) {
          themeData.widgetList.splice(themeLinkButton.index, 1)
        }

        mergedData.widgetList.push({
          id: widgetData.id,
          type: 'linkButton',
          data,
        })
      }
    }
  })

  return mergedData
}
