import * as React from 'react'
import { LoginForm } from '@anyonelab/frontend/app/components/LoginForm'
import { BobaDonatorLoginForm } from '../../boba-components/BobaLoginForm/BobaDonatorLoginForm'

export const LogInPage = () => {
  let params = new URL(window.location.href).searchParams

  let loginType = params.get('type')

  if (loginType === 'BOBA_DONATOR') {
    return <BobaDonatorLoginForm />
  }

  return <LoginForm />
}
