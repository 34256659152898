import { CloseOutlined } from '@anyonelab/common/'
import React from 'react'
import styled from 'styled-components'
import { AppletButtonIcon } from '../AppletButtonIcon'
import { AppletButtonIconCarrier } from '../AppletButtonIconCarrier'
import { TitleBar } from './components/TitleBar'
import { TutorialComponent } from './pages/TutorialPage'
import { AppPopUpCardDataProps } from './type'

interface AppPopUpCardProps {
  data: AppPopUpCardDataProps
  closePopUpCard: () => void
}

const TITLE_MIN_HEIGHT = 48

export function AppPopUpCard({ data, closePopUpCard }: AppPopUpCardProps) {
  const { name, icon } = data

  return (
    <Container>
      <StyledTitleBar height={TITLE_MIN_HEIGHT}>
        <IconContainer>
          {typeof icon === 'string' ? (
            <AppletButtonIcon
              style={{
                height: '100%',
                position: 'absolute',
                top: 0,
              }}
              icon={icon}
              installed={true}
            />
          ) : (
            <AppletButtonIconCarrier
              style={{
                height: '100%',
                position: 'absolute',
                top: 0,
              }}
              icon={icon}
              children={icon}
              installed={true}
            />
          )}
        </IconContainer>
        <Title>{name}</Title>
        <CloseOutlined onClick={closePopUpCard} />
      </StyledTitleBar>
      <TutorialComponent data={data} />
    </Container>
  )
}

const Container = styled.div``

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize[24]};
  font-weight: ${({ theme }) => theme.fontWeight[700]};
  flex: 1;
`

const IconContainer = styled.div`
  position: relative;
  width: ${TITLE_MIN_HEIGHT}px;
  height: ${TITLE_MIN_HEIGHT}px;
`

const StyledTitleBar = styled(TitleBar)`
  padding: ${({ theme }) => theme.padding[20]};
  padding-right: ${({ theme }) => theme.padding[36]};
`
