import { useQuery, UseQueryResult } from 'react-query'
import { getUsersList } from '../../UsersAPI'
import { PaginatedResponse } from '../../common'
import { User } from '@anyonelab/frontend/models/User'

export const useGetUsers = (): UseQueryResult<
  PaginatedResponse<User[]>,
  Error
> => {
  return useQuery<PaginatedResponse<User[]>, Error>('user', getUsersList)
}
