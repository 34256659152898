import { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@anyonelab/frontend/utils/@reduxjs/toolkit'
import { useInjectReducer } from '@anyonelab/frontend/utils/redux-injectors'
import { I18nState } from './types'
import localStorageKey from '@anyonelab/frontend/types/localStorageKey'

const userLocale = localStorage.getItem(localStorageKey.locale)
const userFullLocale = localStorage.getItem(localStorageKey.fullLocale)

export const initialState: I18nState = {
  locale: userLocale || 'en',
  fullLocale: userFullLocale || 'en-US',
}

const slice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    setLocale(state, action: PayloadAction<I18nState['locale']>) {
      state.locale = action.payload
    },
    setFullLocale(state, action: PayloadAction<I18nState['fullLocale']>) {
      state.fullLocale = action.payload
    },
  },
})

export const { actions: i18nActions } = slice

export const useI18nSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  return { actions: slice.actions }
}
