/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from '@anyonelab/frontend/utils/loadable'

export const DataInsightsPageLoadable = lazyLoad(
  () => import('./DataInsightsPage'),
  (module) => module.DataInsightsPage,
)
