/**
 *
 * Asynchronously loads the component for LinkTreePage
 *
 */

import { lazyLoad } from '@anyonelab/frontend/utils/loadable'

export const LinkTreePageLoadable = lazyLoad(
  () => import('./LinkTreePage'),
  (module) => module.LinkTreePage,
)
