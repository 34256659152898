import * as React from 'react'
import { auth } from '@anyonelab/frontend/app/services/auth/firebase'
import {
  eraseCookie,
  setCookie,
  getCookie,
} from '@anyonelab/frontend/utils/cookies'
import { getToken } from '@anyonelab/frontend/utils/api-client'
import { storeAccessToken } from '@anyonelab/frontend/utils/storage'

export interface FirebaseUser {
  displayName: string | null
  email: string | null
  photo: string | null
  uid: string
}

export interface FirebaseAuthContextValue {
  isAuthenticated: boolean | null
  firebaseUser: FirebaseUser | null
  isLoadingUserFromFirebase: boolean
}

export const FirebaseAuthContext = React.createContext<
  FirebaseAuthContextValue
>({
  isAuthenticated: null,
  firebaseUser: null,
  isLoadingUserFromFirebase: true,
})

export interface FirebaseAuthContextProviderProps {
  children: React.ReactNode
}

export const FirebaseAuthContextProvider = ({
  children,
}: React.PropsWithChildren<FirebaseAuthContextProviderProps>) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(
    !!auth.currentUser,
  )
  const [firebaseUser, setFirebaseUser] = React.useState<null | FirebaseUser>(
    null,
  )
  const [
    isLoadingUserFromFirebase,
    setIsLoadingUserFromFirebase,
  ] = React.useState(true)

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsAuthenticated(true)

        setFirebaseUser({
          displayName: user.displayName,
          email: user.email,
          photo: user.photoURL,
          uid: user.uid,
        })
        await storeAccessToken()
        setIsLoadingUserFromFirebase(false)
      } else {
        setIsAuthenticated(false)
        setFirebaseUser(null)
        setIsLoadingUserFromFirebase(false)
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  // // * used to set cookie, logout will remove cookie
  // React.useEffect(() => {
  //   async function setTokenToCookie() {
  //     const token = await getToken()
  //     // Todo setup env for development
  //     if (process.env.NODE_ENV === 'production') {
  //       setCookie('JWT_TOKEN', token, 90, 'anyonelab.com')
  //       setCookie('JWT_TOKEN', token, 90, 'bobaboba.me')
  //     } else {
  //       setCookie('JWT_TOKEN', token, 90, 'localhost')
  //     }
  //   }

  //   if (isAuthenticated) {
  //     setTokenToCookie()
  //   }
  // }, [isAuthenticated])

  const contextValue = {
    isAuthenticated,
    firebaseUser,
    isLoadingUserFromFirebase,
  }

  return (
    <FirebaseAuthContext.Provider value={contextValue}>
      {children}
    </FirebaseAuthContext.Provider>
  )
}
