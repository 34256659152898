import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { motion, Variants } from 'framer-motion'
import { Applet } from '../types'
import { AppletButtonIcon } from '../../AppletButtonIcon'
import { AppletButtonIconCarrier } from '../../AppletButtonIconCarrier'

type AppletButtonType = Omit<Applet, 'name'> & {
  name: string
  index: number
  length: number
  installed: boolean
  onClick: () => void
  initAnimation: boolean
  finishedAnimation: () => void
}

export const AppletButton = (props: AppletButtonType) => {
  const {
    index,
    length,
    name,
    description,
    icon,
    hasIntegration,
    redirectUrl,
    screenshotUrls,
    installed,
    onClick,
    initAnimation,
    finishedAnimation,
  } = props
  const containerAnimation: Variants = {
    initial: { y: '10%', opacity: 0 },
    enter: { y: 0, opacity: 1, transition: { delay: (index * 0.5) / length } },
    up: { y: '-10%' },
  }
  const buttonAnimation: Variants = {
    tap: { scale: 0.9 },
    up: { y: '-10%' },
  }

  return (
    <Container
      onClick={onClick}
      variants={!initAnimation ? containerAnimation : {}}
      initial={'initial'}
      animate={'enter'}
      onAnimationComplete={() => {
        initAnimation || finishedAnimation()
      }}
    >
      <IconContainer>
        {typeof icon === 'string' ? (
          <AppletButtonIcon
            style={{
              height: '100%',
              position: 'absolute',
              top: 0,
            }}
            variants={buttonAnimation}
            whileTap={'tap'}
            whileHover={'up'}
            installed={installed}
            icon={icon}
          />
        ) : (
          <AppletButtonIconCarrier
            style={{
              height: '100%',
              position: 'absolute',
              top: 0,
            }}
            variants={buttonAnimation}
            whileTap={'tap'}
            whileHover={'up'}
            installed={installed}
            icon=""
            children={icon}
          />
        )}
      </IconContainer>
      <ButtonLabel>{name}</ButtonLabel>
    </Container>
  )
}

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  width: 100%;
  cursor: pointer;
`

const ButtonLabel = styled(motion.div)`
  width: 100%;
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const IconContainer = styled(motion.div)`
  position: relative;
  padding-top: 100%;
  width: 100%;
  margin-bottom: 4px;
`
