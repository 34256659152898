import { defineMessages } from 'react-intl'

export default defineMessages({
  pageViews: {
    defaultMessage: 'Page Views',
  },
  pageClicks: {
    defaultMessage: 'Page Clicks',
  },
})
