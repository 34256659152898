import {
  PageClickResponse,
  PageViewResponse,
} from '@anyonelab/frontend/types/DataInsight'
import { createSlice } from '@anyonelab/frontend/utils/@reduxjs/toolkit'
import { useInjectReducer } from '@anyonelab/frontend/utils/redux-injectors'
import { PayloadAction } from '@reduxjs/toolkit'
import { DataInsightState, PageClickState, PageViewState } from './types'

export const initialState: DataInsightState = {
  linkPortalButtonClick: null,
  linkPortalPageView: null,
}

const slice = createSlice({
  name: 'dataInsight',
  initialState,
  reducers: {
    updateState(state, action: PayloadAction<Partial<DataInsightState>>) {
      return {
        ...state,
        ...action.payload,
      }
    },
    updateLinkPortalButtonClick(state, action: PayloadAction<PageClickState>) {
      state.linkPortalButtonClick = action.payload
    },
    updateLinkPortalPageView(state, action: PayloadAction<PageViewState>) {
      state.linkPortalPageView = action.payload
    },
  },
})

export const { actions: dataInsightAction } = slice

export const useDataInsightSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  return { actions: slice.actions }
}
