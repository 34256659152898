import { BobaLoader, message, SettingBoard } from '@anyonelab/common/'
import { useGetBobaUserAccount } from '@anyonelab/frontend/api/boba-api/hook/useGetBobaData'
import { useBobaUser } from '@anyonelab/frontend/app/providers/BobaUserProvider'
import { apiClient } from '@anyonelab/frontend/utils/api-client'
import React from 'react'
import { useIntl } from 'react-intl'
import { useMutation, useQueryClient } from 'react-query'
import messages from '../../messages'

export const SettingBoardPage = () => {
  const intl = useIntl()
  const {
    data: userData,
    refetch: refetchUserData,
    isLoading: isLoadingUserData,
  } = useGetBobaUserAccount()
  const { refetchBobaUser } = useBobaUser() // * update home page user account, need refactor the whole project and setup the react query at home page
  const userId = userData?.data[0].id // ! we are getting boba user id so not user id
  const userImageUrl = userData?.data[0].avatar_image_url
  const [imageUrl, setImageUrl] = React.useState<string>(userImageUrl)
  const { mutate: updateMutate, isLoading: isMutating } = useMutation(
    (data) => {
      return apiClient
        .patch(`/boba-user-accounts/${userId}`, data)
        .then((res) => {
          return res.data
        })
    },
    {
      onError: (res) => {
        message.error(intl.formatMessage(messages.updateFail))
      },
      onSuccess: (res) => {
        message.success(intl.formatMessage(messages.updateSuccess))
        refetchUserData()
        refetchBobaUser()
      },
    },
  )

  if (isLoadingUserData) return <BobaLoader />

  return (
    <SettingBoard
      currentImageUrl={imageUrl}
      uploadOnClick={setImageUrl}
      updateOnClick={updateMutate}
      data={userData?.data[0] || undefined}
    />
  )
}
