import {
  PostBobaDonationCommentRequest,
  PatchBobaDonationCommentRequest,
} from '@anyonelab/common/boba-components/schema/boba-request.schema'
import { apiClient } from '@anyonelab/frontend/utils/api-client'

export const postBobaDonationComments = (
  data: PostBobaDonationCommentRequest,
): Promise<any> => {
  try {
    return apiClient
      .post('/boba-donation-comments', data)
      .then((res) => res.data)
  } catch (err) {
    throw err
  }
}

export const patchBobaDonationComments = (
  id: number,
  data: PatchBobaDonationCommentRequest,
): Promise<any> => {
  try {
    return apiClient
      .patch(`/boba-donation-comments/${id}`, { ...data, is_active: true })
      .then((res) => res.data)
  } catch (err) {
    throw err
  }
}

export const removeBobaDonationComments = (
  id: number,
  data: PatchBobaDonationCommentRequest,
) => {
  try {
    return apiClient
      .patch(`/boba-donation-comments/${id}`, { ...data, is_active: false })
      .then((res) => res.data)
  } catch (err) {
    throw err
  }
}
