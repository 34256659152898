import * as React from 'react'

import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectFullLocale, selectLocale } from './slice/selectors'

export interface LanguageProviderProps {
  messages: object
  locale?: string
  fullLocale?: string
}

export const LanguageProvider = React.memo(
  ({
    children,
    messages = {},
  }: React.PropsWithChildren<LanguageProviderProps>) => {
    const locale = useSelector(selectLocale)
    const fullLocale = useSelector(selectFullLocale)

    return (
      <IntlProvider
        defaultLocale={fullLocale}
        locale={fullLocale}
        key={fullLocale}
        messages={messages[locale]}
        wrapRichTextChunksInFragment={true}
      >
        {React.Children.only(children)}
      </IntlProvider>
    )
  },
)
