import { defineMessages } from 'react-intl'

export default defineMessages({
  dailyButtonClicks: {
    defaultMessage: 'Daily Button Clicks',
  },
  total: {
    defaultMessage: 'Total',
  },
})
