import { DesktopOnly, MobileOnly, Spinner } from '@anyonelab/common/'
import { useGetMarketplaceApps } from '@anyonelab/frontend/api/hooks/Marketplace/useGetMarketplaceApps'
import { batchUpdateMarketplace } from '@anyonelab/frontend/api/MarketplaceAPI'
import { AppletButton } from '@anyonelab/frontend/app/components/Marketplace/components/AppletButton'
import {
  appDataListParser,
  appDataParser,
} from '@anyonelab/frontend/app/components/Marketplace/utils/appDataParser'
import { selectLocale } from '@anyonelab/frontend/app/providers/LanguageProvider/slice/selectors'
import { MarketplaceApp } from '@anyonelab/frontend/models/MarketplaceApp'
import { Button, Checkbox, Drawer, message, Modal } from 'antd'
import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

interface AppEditPageProps {}

function AppEditPage({}: AppEditPageProps) {
  const { data: availableApps, isLoading } = useGetMarketplaceApps()
  const locale = useSelector(selectLocale)
  const [initAnimation, setInitAnimation] = React.useState(false)
  const finishedAnimation = () => setInitAnimation(true)

  const [changedActiveApp, setChangedActiveApp] = React.useState<
    Partial<MarketplaceApp>[]
  >([])

  const handleCheckboxOnChange = (id: number, is_active: boolean) => {
    const isExisting = changedActiveApp.findIndex((item) => item.id === id)
    // found
    if (isExisting > -1) {
      setChangedActiveApp((prev) => {
        prev.splice(isExisting, 1)
        return prev
      })
    } else {
      setChangedActiveApp((prev) => {
        prev.push({ id, is_active: !is_active })
        return prev
      })
    }
  }

  const handleAppEdit = async () => {
    try {
      console.log(changedActiveApp)
      console.log(await batchUpdateMarketplace(changedActiveApp))
      message.success('successfully changed the apps on the marketplace save')
    } catch (err) {
      console.log(err)
      message.error('theme save fail')
    }
  }

  if (!availableApps || isLoading) {
    return <Spinner />
  }

  return (
    <div>
      <>
        These are the apps that are showing
        <DesktopOnly>
          <DesktopAppButtonContainer>
            {appDataListParser(availableApps?.data, locale)
              .sort((a, b) => a.id - b.id)
              .map((app, index, array) => {
                const onClick = () => {}

                return (
                  <AppContainer key={'app' + index}>
                    <AppletButton
                      index={index}
                      length={array.length}
                      onClick={onClick}
                      hasIntegration={app.hasIntegration}
                      name={app.name}
                      icon={app.icon}
                      description={app.content}
                      screenshotUrls={app.screenshotUrls}
                      installed={true}
                      redirectUrl={app.redirectUrl}
                      initAnimation={initAnimation}
                      finishedAnimation={() => {}}
                    />
                    <Checkbox
                      onChange={() =>
                        handleCheckboxOnChange(app.id, app.isActive)
                      }
                      defaultChecked={app.isActive}
                    />
                  </AppContainer>
                )
              })}
          </DesktopAppButtonContainer>
        </DesktopOnly>
        <MobileOnly>
          <MobileAppButtonContainer>
            {appDataListParser(availableApps?.data, locale)
              .sort((a, b) => a.id - b.id)
              .map((app, index, array) => {
                const onClick = () => {}

                return (
                  <AppContainer key={'app' + index}>
                    <AppletButton
                      index={index}
                      length={array.length}
                      onClick={onClick}
                      hasIntegration={app.hasIntegration}
                      name={app.name}
                      icon={app.icon}
                      description={app.content}
                      screenshotUrls={app.screenshotUrls}
                      installed={true}
                      redirectUrl={app.redirectUrl}
                      initAnimation={initAnimation}
                      finishedAnimation={finishedAnimation}
                    />
                    <Checkbox
                      onChange={() =>
                        handleCheckboxOnChange(app.id, app.isActive)
                      }
                      defaultChecked={app.isActive}
                      checked={
                        changedActiveApp.find((item) => item.id === app.id)
                          ?.is_active || app.isActive
                      }
                    />
                  </AppContainer>
                )
              })}
          </MobileAppButtonContainer>
        </MobileOnly>
      </>
      <Button onClick={handleAppEdit}>Save</Button>
    </div>
  )
}

export default AppEditPage

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4px;
`

const DesktopAppButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  > div {
    width: 56px;
  }
  gap: 16px;
`

const MobileAppButtonContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 16px;
`
