import * as React from 'react'

export const ChipComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="147"
    height="108"
    viewBox="0 0 147 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Frame" clip-path="url(#clip0_1286_8999)">
      <path
        id="Vector"
        d="M72.1375 91.1816C79.4137 87.1721 86.9422 83.6372 94.6758 80.599C103.57 77.0651 113.126 73.3613 122.872 73.588C131.975 73.7959 146.849 80.051 147 91.0305C146.951 92.3876 146.629 93.7211 146.054 94.9515C145.478 96.1819 144.661 97.2841 143.651 98.1928C126.278 115.068 95.8111 107.472 78.2498 95.3771L72.1375 91.1816Z"
        fill="#F7BA69"
      />
      <path
        id="Vector_2"
        d="M0.208018 51.0573C-3.40643 11.9392 41.4808 -16.5584 73.0836 10.862C83.9837 20.3108 90.8341 33.4258 98.1765 45.6148C105.519 57.8038 117.573 70.1817 131.35 74.8305C131.385 74.8305 131.419 74.8444 131.444 74.8692C131.468 74.8941 131.482 74.9278 131.482 74.9629C131.482 74.998 131.468 75.0315 131.444 75.0563C131.419 75.0811 131.385 75.0951 131.35 75.0951C107.601 84.0337 85.157 96.0715 59.4396 97.7722C30.2591 99.662 3.19805 81.9171 0.283787 51.2463L0.208018 51.0573Z"
        fill="#FCD69B"
      />
      <path
        id="Vector_3"
        d="M30.4484 32.4233L34.0058 35.5037C34.1555 35.6222 34.2766 35.7729 34.3597 35.9447C34.4428 36.1165 34.4861 36.305 34.4861 36.4958C34.4861 36.6866 34.4428 36.8749 34.3597 37.0467C34.2766 37.2185 34.1555 37.3695 34.0058 37.488C33.0395 38.2519 31.8593 38.6966 30.6285 38.7609C29.3978 38.8253 28.1776 38.5059 27.1367 37.8469C26.4235 37.4027 25.8046 36.8226 25.3159 36.1397C24.8272 35.4567 24.4782 34.6842 24.2884 33.8665C24.0986 33.0488 24.0718 32.2017 24.2099 31.3737C24.3479 30.5457 24.6479 29.7528 25.0928 29.0406C25.5376 28.3283 26.1185 27.7106 26.8024 27.2226C27.4863 26.7345 28.2599 26.3858 29.0787 26.1963C29.8976 26.0067 30.7458 25.9802 31.575 26.1181C32.4041 26.2559 33.1981 26.5555 33.9113 26.9997C34.8119 27.5475 35.5542 28.3199 36.0652 29.2411C36.5762 30.1622 36.8383 31.2004 36.8257 32.2533C36.8298 32.4468 36.79 32.6387 36.7095 32.8148C36.6289 32.9909 36.5096 33.1464 36.3604 33.2699C36.2112 33.3935 36.036 33.4819 35.8478 33.5283C35.6597 33.5748 35.4634 33.5781 35.2738 33.5382L30.4484 32.4233Z"
        fill="#62412A"
      />
      <path
        id="Vector_4"
        d="M71.8159 48.3584L75.3736 51.4387C75.5096 51.5558 75.6187 51.7008 75.6935 51.8639C75.7684 52.0269 75.8072 52.2042 75.8072 52.3836C75.8072 52.563 75.7684 52.74 75.6935 52.9031C75.6187 53.0661 75.5096 53.2114 75.3736 53.3285C74.4147 54.0988 73.2381 54.5495 72.0092 54.6173C70.7804 54.6851 69.5612 54.3665 68.5232 53.7063C67.8099 53.2621 67.1911 52.682 66.7024 51.999C66.2137 51.3161 65.8647 50.5436 65.6749 49.7259C65.4851 48.9081 65.4583 48.0611 65.5964 47.2331C65.7344 46.4051 66.0344 45.6122 66.4792 44.9C66.9241 44.1877 67.505 43.57 68.1889 43.0819C68.8728 42.5939 69.6463 42.2451 70.4652 42.0556C71.2841 41.8661 72.1323 41.8396 72.9614 41.9774C73.7906 42.1153 74.5846 42.4149 75.2978 42.8591C76.2001 43.4035 76.9427 44.1758 77.451 45.0981C77.9593 46.0204 78.2154 47.0602 78.1932 48.1127C78.2011 48.3073 78.1637 48.5011 78.0844 48.679C78.005 48.857 77.8857 49.0142 77.7356 49.1386C77.5854 49.2629 77.4086 49.3508 77.2188 49.3958C77.029 49.4407 76.8314 49.4414 76.6413 49.3976L71.8159 48.3584Z"
        fill="#62412A"
      />
      <path
        id="Vector_5"
        d="M50.8294 57.6233C50.2652 57.621 49.7066 57.5121 49.183 57.3022C48.3413 56.8683 47.5937 56.2726 46.9832 55.5494C46.3726 54.8262 45.9112 53.9898 45.6253 53.088C44.7455 53.533 43.773 53.7649 42.7868 53.7649C41.8005 53.7649 40.8281 53.533 39.9482 53.088C39.2173 52.5916 38.6126 51.9314 38.1822 51.1604C37.7518 50.3894 37.5078 49.5288 37.4693 48.6469C37.4589 48.2722 37.5957 47.9084 37.8502 47.633C38.1048 47.3576 38.457 47.1926 38.8318 47.1731C39.0182 47.1655 39.2044 47.1946 39.3795 47.2589C39.5546 47.3232 39.7152 47.4213 39.8523 47.5477C39.9894 47.6741 40.1001 47.8263 40.1783 47.9954C40.2564 48.1646 40.3006 48.3475 40.3079 48.5336C40.3297 48.9441 40.4388 49.3451 40.628 49.7101C40.8173 50.0751 41.0823 50.3955 41.4054 50.6502C42.2569 51.1604 43.8277 50.8769 45.7769 49.8942C45.9704 49.7962 46.1843 49.7452 46.4013 49.7452C46.6183 49.7452 46.8321 49.7962 47.0257 49.8942C47.2244 49.9867 47.3994 50.1232 47.5371 50.2935C47.6749 50.4638 47.7718 50.6635 47.8206 50.8769C47.9909 51.6895 48.8235 54.1462 50.186 54.6564C50.4131 54.6564 51.0943 55.0343 52.4947 54.1273C52.8084 53.9193 53.1919 53.8443 53.561 53.9187C53.9301 53.9931 54.2545 54.2108 54.4628 54.524C54.6711 54.8373 54.7462 55.2205 54.6717 55.5891C54.5971 55.9577 54.3792 56.2815 54.0655 56.4895C53.1173 57.168 51.9943 57.5614 50.8294 57.6233Z"
        fill="#62412A"
      />
      <path
        id="Vector_6"
        d="M88.5443 75.7933C89.4118 75.7933 90.115 75.091 90.115 74.2248C90.115 73.3585 89.4118 72.6562 88.5443 72.6562C87.6769 72.6562 86.9736 73.3585 86.9736 74.2248C86.9736 75.091 87.6769 75.7933 88.5443 75.7933Z"
        fill="#F7BA69"
      />
      <path
        id="Vector_7"
        d="M96.5492 70.262C97.4167 70.262 98.1199 69.5598 98.1199 68.6935C98.1199 67.8272 97.4167 67.125 96.5492 67.125C95.6817 67.125 94.9785 67.8272 94.9785 68.6935C94.9785 69.5598 95.6817 70.262 96.5492 70.262Z"
        fill="#F7BA69"
      />
      <path
        id="Vector_8"
        d="M99.0282 77.9339C99.8957 77.9339 100.599 77.2316 100.599 76.3654C100.599 75.4991 99.8957 74.7969 99.0282 74.7969C98.1607 74.7969 97.4575 75.4991 97.4575 76.3654C97.4575 77.2316 98.1607 77.9339 99.0282 77.9339Z"
        fill="#F7BA69"
      />
      <path
        id="Vector_9"
        d="M109.55 76.387C110.417 76.387 111.12 75.6848 111.12 74.8185C111.12 73.9522 110.417 73.25 109.55 73.25C108.682 73.25 107.979 73.9522 107.979 74.8185C107.979 75.6848 108.682 76.387 109.55 76.387Z"
        fill="#F7BA69"
      />
    </g>
    <defs>
      <clipPath id="clip0_1286_8999">
        <rect width="147" height="108" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
