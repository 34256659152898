/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

// Use consistent styling
import 'sanitize.css/sanitize.css'

// Import root app
import { App } from '@anyonelab/frontend/app/App'

import { HelmetProvider } from 'react-helmet-async'

import { configureAppStore } from '@anyonelab/frontend/store/configureStore'

import reportWebVitals from '@anyonelab/frontend/reportWebVitals'

import 'antd/dist/antd.css'
import { LanguageProvider } from './app/providers/LanguageProvider'
import { translationMessages } from './i18n'
import history from './utils/history'
import { FirebaseAuthContextProvider } from '@anyonelab/frontend/app/providers/FirebaseAuthProvider/FirebaseAuthProvider'
import initFacebookSDK from '@anyonelab/frontend/initFacebookSDK'

const store = configureAppStore()
const MOUNT_NODE = document.getElementById('root') as HTMLElement

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  )
}

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <FirebaseAuthContextProvider>
        <HelmetProvider>
          <React.StrictMode>
            <LanguageProvider messages={translationMessages}>
              <App />
            </LanguageProvider>
          </React.StrictMode>
        </HelmetProvider>
      </FirebaseAuthContextProvider>
    </Provider>,
    MOUNT_NODE,
  )
}

initFacebookSDK().then(() => renderApp())

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
