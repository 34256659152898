import { bobaBackgroundColor, BobaCurrency } from '../boba-components'
import { BobaMenu } from '../boba-components/type'
import { getBobaPriceOptions } from './bobaPriceOptionsGetter'

export const getDefaultBobaItems = (currency: BobaCurrency): BobaMenu[] => {
  const prices = getBobaPriceOptions(currency)
  const DEFAULT_BOBA_ITEMS: BobaMenu[] = [
    {
      name: 'Sunset',
      value: prices[1],
      boba_type: {
        background_color: bobaBackgroundColor.brownLight,
        series: 'sunset',
      },
    },
    {
      name: 'Matcha',
      value: prices[2],
      boba_type: {
        background_color: bobaBackgroundColor.brown,
        series: 'matcha',
      },
    },
    {
      name: 'Peach',
      value: prices[3],
      boba_type: {
        background_color: bobaBackgroundColor.green,
        series: 'peach',
      },
    },
  ]
  return DEFAULT_BOBA_ITEMS
}
