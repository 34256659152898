import {
  Form,
  InteractionFilled,
  PrimaryButton,
  PrimaryTextInput,
  Select,
  SelectProps,
  useResponsive,
  getCountryLocalisationLabels,
  BobaCurrency,
} from '@anyonelab/common/'
import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import messages from './messages'

type CashoutRequestContentProps = {
  currency: BobaCurrency
  email?: string
  cashoutOnClick: (data: any) => Promise<void>
  cashoutAmount: number
} & HTMLAttributes<HTMLDivElement>

export const CashoutRequestContent = (props: CashoutRequestContentProps) => {
  const intl = useIntl()
  const { isMobileLayout } = useResponsive()

  const userLanguage = intl.locale
  const translationLabels =
    userLanguage === 'zh-HK'
      ? {
          HK: '\u9999\u6e2f',
          TW: '\u53f0\u7063',
          US: '\u7f8e\u570b',
        }
      : {
          HK: 'Hong Kong',
          TW: 'Taiwan',
          US: 'United States',
        }
  const defaultLocationValue = userLanguage === 'en-US' ? 'HK' : 'TW'
  const CONTACT_WORKING_DAYS = 5
  const BANK_CODE_PLACEHOLDER = '000'
  const ACCOUNT_NUMBER_PLACEHOLDER = '1234 1234 1234 1234'

  const validateMessages = {
    required: "'${label}' is required",
  }

  return (
    <Container className={isMobileLayout ? 'mobile' : 'desktop'}>
      <Title className="title" children={intl.formatMessage(messages.title)} />
      <Subtitle
        className="subtitle"
        children={intl.formatMessage(messages.description, {
          currency: props.currency,
          number: props.cashoutAmount.toLocaleString(),
        })}
      />
      <Form
        validateMessages={validateMessages}
        onFinish={props.cashoutOnClick}
        layout="vertical"
      >
        <StyledFormItem
          required
          className="form-item"
          name="location"
          initialValue={defaultLocationValue}
          label={intl.formatMessage(messages.location)}
        >
          <StyledSelect
            className="select-input"
            defaultValue={defaultLocationValue}
            options={Object.keys(translationLabels).map((countryCode) => {
              return {
                label: translationLabels[countryCode],
                value: countryCode,
              }
            })}
          />
        </StyledFormItem>
        <StyledFormItem
          style={{ display: 'none' }}
          rules={[
            {
              pattern: new RegExp(/\d+/g),
              message: intl.formatMessage(messages.numberAlert),
            },
          ]}
          className="form-item"
          name="bank_code"
          label={intl.formatMessage(messages.bankCode)}
        >
          <StyledPrimaryTextInput
            className="text-input"
            inputMode="numeric"
            placeholder={BANK_CODE_PLACEHOLDER}
            prefix={intl.formatMessage(messages.bankCode)}
          />
        </StyledFormItem>
        <FormItemWithoutLabel
          style={{ display: 'none' }}
          rules={[
            {
              pattern: new RegExp(/\d+/g),
              message: intl.formatMessage(messages.numberAlert),
            },
          ]}
          required
          className="form-item"
          name="account_number"
          label={intl.formatMessage(messages.accountNumber)}
        >
          <StyledPrimaryTextInput
            className="text-input"
            inputMode="numeric"
            placeholder={ACCOUNT_NUMBER_PLACEHOLDER}
            prefix={intl.formatMessage(messages.accountNumber)}
          />
        </FormItemWithoutLabel>
        <StyledFormItem
          required
          className="form-item"
          name="email"
          label={intl.formatMessage(messages.email)}
        >
          <StyledPrimaryTextInput
            className="text-input"
            inputMode="text"
            prefix={intl.formatMessage(messages.email)}
            defaultValue={props.email}
          />
        </StyledFormItem>
        <StyledPrimaryButton
          htmlType="submit"
          className="primary-button"
          content={intl.formatMessage(messages.cashout)}
        />
      </Form>
      <Subtitle
        children={intl.formatMessage(messages.contactWithinMessage, {
          number: CONTACT_WORKING_DAYS,
        })}
      />
    </Container>
  )
}

const Container = styled.div`
  transition: height 1s;
  width: 100%;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  &.mobile {
    .title {
      font-size: 24px;
      margin-bottom: 8px;
    }
    .subtitle {
      font-size: 14px;
      margin-bottom: 32px;
    }
    .primary-button {
      margin-bottom: 24px;
    }
    .select-input {
      &.select-input {
        .ant-select-selector {
          height: 40px;
        }
      }
    }
    .text-input {
      border-radius: 16px;
      height: 48px;
      font-weight: 400;
      input.ant-input {
        font-size: 16px;
      }
    }
    .form-item {
      margin-bottom: 12px;
    }
  }
  &.desktop {
    .title {
      font-size: 28px;
      margin-bottom: 12px;
    }
    .subtitle {
      font-size: 16px;
      margin-bottom: 40px;
    }
    .primary-button {
      margin-bottom: 32px;
    }
    .select-input {
      &.select-input {
        .ant-select-selector {
          height: 60px;
        }
      }
    }
    .text-input {
      border-radius: 24px;
      height: 60px;
      padding: 20px 24px;
      font-weight: 400;
      input.ant-input {
        font-size: 18px;
      }
    }
  }
`

const Title = styled.div`
  color: #62463e;
  font-weight: 700;
  text-align: center;
`

const Subtitle = styled.div`
  color: #888585;
  font-weight: 600;
  text-align: center;
`

const StyledPrimaryTextInput = styled(PrimaryTextInput)`
  font-family: ${({ theme }) => theme.bobaFontFamily.secondary};
  color: #b6b6b6;
  background-color: #f4f4f4;
  box-shadow: none;
  font-size: 18px;
  border: none;
  & input.ant-input {
    background-color: #f4f4f4;
  }
`

const StyledFormItem = styled(Form.Item)`
  label {
    font-family: ${({ theme }) => theme.bobaFontFamily.secondary};
    font-weight: 500;
    color: #62463e;
    font-size: 18px;
    margin-bottom: 12px;
  }
`

const FormItemWithoutLabel = styled(Form.Item)`
  label {
    display: none;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  font-size: 20px;
`

const StyledSelect = styled(Select)<SelectProps<any>>`
  width: 100%;
  .ant-select-selector {
  }
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #f4f4f4;
    border: none;
    border-radius: 12px;
    display: flex;
    align-items: center;
  }
  .ant-select-selection-item {
    border-color: #ab5337;
    color: #929292;
  }
  .ant-select-selection {
    background-color: #f4f4f4;
  }
`
