import React from 'react'

type EmptyCircleIconProps = {
  strokeColor?: string
  strokeWidth?: string
} & React.SVGProps<SVGSVGElement>

export const EmptyCircleIcon = (props: EmptyCircleIconProps) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="done">
        <circle
          id="Ellipse 71"
          cx="11.0208"
          cy="11.0208"
          r="9.52083"
          stroke={props.strokeColor || '#CCA78C'}
          stroke-width={props.strokeWidth || '3'}
        />
      </g>
    </svg>
  )
}
