import { defineMessages } from 'react-intl'

export default defineMessages({
  Sun: {
    defaultMessage: 'Sun',
  },
  Mon: {
    defaultMessage: 'Mon',
  },
  Tue: {
    defaultMessage: 'Tue',
  },
  Wed: {
    defaultMessage: 'Wed',
  },
  Thu: {
    defaultMessage: 'Thu',
  },
  Fri: {
    defaultMessage: 'Fri',
  },
  Sat: {
    defaultMessage: 'Sat',
  },
})
