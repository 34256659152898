import { defineMessages } from 'react-intl'

export default defineMessages({
  changeLanguage: {
    defaultMessage: 'Change Language',
  },
  languageIsNowChangedToChinese: {
    defaultMessage: '語言已更改為中文',
  },
  languageIsNowChangedToEnglish: {
    defaultMessage: 'Language is now changed to English',
  },
})
