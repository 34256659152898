import {
  createPage,
  getPageList,
  updatePage,
} from '@anyonelab/frontend/api/PageAPI'
import { PageWidget } from '@anyonelab/frontend/models/Widget'
import { PayloadAction } from '@reduxjs/toolkit'
import { message } from 'antd'
import {
  take,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
  delay,
} from 'redux-saga/effects'
import { linkPortalActions as actions } from '.'
import { selectLinkPortal } from './selectors'
import { LinkPortalState } from './types'

function* getPageId() {
  const state: LinkPortalState = yield select(selectLinkPortal)

  let id = state.id
  if (!id) {
    const pages = yield call(getPageList)

    if (pages.data.length === 0) {
      const res = yield call(createPage, {
        name: 'Link Portal',
        user_id: 6,
        widget_object: {
          profileSplash: state.profileSplash,
          data: state.widgetList,
        },
      })
      id = res.id
    } else {
      id = pages.data[0].id
    }
  }
  return id
}

function* updateLinkPortalPage() {
  try {
    yield put(actions.updateSavedState({ state: false }))

    yield delay(500)

    const state: LinkPortalState = yield select(selectLinkPortal)

    const profileSplash = state.profileSplash
    const widgetList = state.widgetList

    const id = yield call(getPageId)

    yield call(updatePage, id, {
      widget_object: {
        profileSplash,
        widgetList,
      },
    })

    yield put(actions.updateSavedState({ state: true }))
  } catch (e) {
    console.log(e)
  }
}

export function* linkPortalSaga() {
  yield takeLatest((action) => {
    // redux saga issue, all of the other redux slice come to here i18n too so when the lang is changed, portal data also saved.
    const isLinkPortalAction = Object.keys(actions).includes(
      action.type.split('/')[1],
    )

    if (isLinkPortalAction) {
      console.log('Logging:', { action })
    }

    return (
      isLinkPortalAction &&
      action.type !== actions.updateState.type &&
      action.type !== actions.updateSavedState.type
    )
  }, updateLinkPortalPage)
}
