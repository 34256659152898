export function customPostMessage(key: string, data: object, url: string) {
  console.log(`send data to key:${key} to url:${url}`)

  if (
    url.endsWith('anyonelab.com') ||
    url.endsWith('anyonelab.com/') ||
    url.endsWith('bobaboba.me') ||
    url.endsWith('bobaboba.me/') ||
    url.endsWith('localhost:3006') ||
    url.endsWith('localhost:3006/')
  ) {
    console.log('post message')
    window.parent.postMessage(
      JSON.stringify({
        key,
        ...data,
      }),
      url,
    )
  }
}
//   var url =
//   window.location !== window.parent.location
//     ? document.referrer
//     : document.location.href
// console.log(window.location)
// console.log(window.parent.location)
// console.log(document.referrer)
// console.log(document.location.href)
// console.log({ url })
