import { MarketplaceApp } from '@anyonelab/frontend/models/MarketplaceApp'
import { AppPopUpCardDataProps } from '../../AppPopUpCard/type'
import { SVGComponent } from '../../Common/SVGComponent'

export const appDataParser = (app: MarketplaceApp, locale: string) => {
  const isChinese = locale === 'zh'

  const appIcon =
    app.icon.mime_type === 'image/svg+xml' && app.icon.data ? (
      <SVGComponent svgString={app.icon.data} />
    ) : (
      app.icon.url || ''
    )

  return {
    id: app.id || 1,
    name: isChinese ? app.name_chinese || app.name_english : app.name_english,
    title: isChinese
      ? app.title_chinese || app.title_english
      : app.title_english,
    hasIntegration: app.has_integration,
    icon: appIcon,
    content: isChinese
      ? app.content_chinese || app.content_english
      : app.content_english,
    screenshotUrls: app.screenshot_urls || [],
    redirectUrl: app.redirect_url || '',
    isActive: app.is_active,
    trackName: app.track_name || '',
  }
}

export const appDataListParser = (
  marketplaceData: MarketplaceApp[],
  locale: string,
): AppPopUpCardDataProps[] => {
  return marketplaceData.map((app) => appDataParser(app, locale))
}
