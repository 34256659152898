import { defineMessages } from 'react-intl'

export default defineMessages({
  backToSomewhere: {
    defaultMessage: 'Back to {somewhere}',
  },
  bar: {
    defaultMessage: 'Bar',
  },
  barSetting: { defaultMessage: 'Settings' },
  receivedBobas: { defaultMessage: 'Received Bobas' },
  balanceAndStats: { defaultMessage: 'Balance & Stats' },
  cashoutHistory: { defaultMessage: 'Cashout History' },
  donationHistory: { defaultMessage: 'Purchase History' },
  totalReceivedMoney: { defaultMessage: 'Total received money' },
  updateSuccess: { defaultMessage: 'Bar settings updated' },
  updateFail: { defaultMessage: 'Bar settings update failed' },
})
