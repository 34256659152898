import * as React from 'react'

export const ChipDisabledComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="147"
    height="108"
    viewBox="0 0 147 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Frame" clip-path="url(#clip0_1286_9009)">
      <path
        id="Vector"
        d="M72.1375 91.1818C79.4174 87.1796 86.9454 83.6449 94.6758 80.5992C103.589 77.0654 113.145 73.3613 122.891 73.588C131.974 73.7959 146.849 80.051 147 91.0305C146.955 92.3884 146.635 93.7232 146.06 94.9542C145.484 96.1852 144.664 97.2869 143.651 98.1928C126.278 115.068 95.8111 107.472 78.2498 95.3771L72.1375 91.1818Z"
        fill="#666566"
      />
      <path
        id="Vector_2"
        d="M0.227061 51.0573C-3.38738 11.9392 41.4999 -16.5584 73.1026 10.862C84.0216 20.3108 90.8721 33.4258 98.1956 45.6148C105.519 57.8038 117.592 70.1817 131.369 74.8305C131.404 74.8305 131.438 74.8444 131.463 74.8692C131.487 74.8941 131.501 74.9276 131.501 74.9627C131.501 74.9977 131.487 75.0315 131.463 75.0563C131.438 75.0811 131.404 75.0951 131.369 75.0951C107.601 84.0337 85.1571 96.0715 59.4586 97.7722C30.2781 99.662 3.21709 81.9171 0.30283 51.2463L0.227061 51.0573Z"
        fill="#9C9B9C"
      />
      <path
        id="Vector_3"
        d="M30.5051 32.2987L34.0628 35.3791C34.1961 35.4978 34.3027 35.6435 34.3758 35.8063C34.4489 35.9691 34.4867 36.1455 34.4867 36.3239C34.4867 36.5024 34.4489 36.6788 34.3758 36.8416C34.3027 37.0044 34.1961 37.1501 34.0628 37.2688C33.0994 38.0311 31.9224 38.4751 30.695 38.5394C29.4675 38.6038 28.2504 38.2851 27.2124 37.6278C26.4896 37.1894 25.8612 36.6123 25.3632 35.9297C24.8653 35.2471 24.5078 34.4729 24.3114 33.6515C24.1151 32.8301 24.0839 31.9777 24.2195 31.1442C24.3551 30.3106 24.6549 29.5122 25.1015 28.7951C25.5481 28.078 26.1326 27.4565 26.8213 26.9665C27.51 26.4765 28.2892 26.1277 29.1138 25.9404C29.9384 25.753 30.792 25.7309 31.6252 25.8751C32.4585 26.0193 33.2547 26.327 33.9681 26.7805C34.8687 27.3283 35.611 28.1007 36.122 29.0219C36.6329 29.943 36.8951 30.9812 36.8824 32.0341C36.8866 32.2277 36.847 32.4196 36.7664 32.5956C36.6859 32.7717 36.5666 32.9274 36.4174 33.051C36.2682 33.1746 36.093 33.2627 35.9048 33.3091C35.7166 33.3556 35.5204 33.3592 35.3308 33.3193L30.5051 32.2987Z"
        fill="#666566"
      />
      <path
        id="Vector_4"
        d="M71.7968 48.3583L75.3544 51.4386C75.4905 51.5557 75.5998 51.7007 75.6746 51.8638C75.7495 52.0268 75.7883 52.2041 75.7883 52.3835C75.7883 52.5629 75.7495 52.7402 75.6746 52.9032C75.5998 53.0663 75.4905 53.2113 75.3544 53.3284C74.3913 54.0977 73.2118 54.5476 71.9804 54.6154C70.749 54.6831 69.527 54.3653 68.4851 53.7062C67.7719 53.262 67.1533 52.6819 66.6646 51.9989C66.1759 51.316 65.8268 50.5435 65.6371 49.7258C65.4473 48.908 65.4205 48.061 65.5585 47.233C65.6965 46.405 65.9966 45.6123 66.4414 44.9001C66.8862 44.1879 67.4671 43.5701 68.1511 43.0821C68.835 42.5941 69.6085 42.2453 70.4274 42.0558C71.2462 41.8663 72.0944 41.8395 72.9236 41.9773C73.7528 42.1152 74.5467 42.4148 75.26 42.859C76.1623 43.4034 76.9048 44.1757 77.4131 45.098C77.9214 46.0203 78.1776 47.0601 78.1554 48.1126C78.1633 48.3072 78.1259 48.501 78.0465 48.6789C77.9672 48.8569 77.8479 49.0141 77.6977 49.1385C77.5476 49.2628 77.3708 49.351 77.181 49.3959C76.9912 49.4409 76.7935 49.4415 76.6035 49.3978L71.7968 48.3583Z"
        fill="#666566"
      />
      <path
        id="Vector_5"
        d="M50.8103 57.6192C50.2461 57.617 49.6875 57.5079 49.164 57.2979C47.4783 56.4139 46.2125 54.8984 45.6442 53.0838C44.763 53.5244 43.7911 53.7539 42.8056 53.7539C41.8201 53.7539 40.8482 53.5244 39.967 53.0838C39.2396 52.5853 38.6387 51.9245 38.2116 51.1536C37.7846 50.3828 37.5432 49.5231 37.5068 48.6429C37.4773 48.446 37.4897 48.245 37.5431 48.0532C37.5965 47.8615 37.6898 47.6832 37.8168 47.5298C37.9439 47.3764 38.1019 47.2514 38.2805 47.163C38.4591 47.0746 38.6544 47.0248 38.8536 47.0168C39.0528 47.0088 39.2513 47.0429 39.4364 47.1167C39.6216 47.1904 39.789 47.3022 39.928 47.4449C40.067 47.5876 40.1744 47.7579 40.2431 47.9448C40.3117 48.1317 40.34 48.3308 40.3265 48.5294C40.3529 48.9388 40.4642 49.3383 40.6531 49.7026C40.842 50.0669 41.1047 50.388 41.4242 50.6459C42.2947 51.1561 43.8463 50.8728 45.8144 49.8902C46.008 49.7922 46.2221 49.7411 46.439 49.7411C46.656 49.7411 46.8699 49.7922 47.0634 49.8902C47.2595 49.9837 47.4314 50.121 47.5659 50.2913C47.7004 50.4617 47.794 50.6605 47.8394 50.8727C48.0097 51.6853 48.8423 54.1419 50.2049 54.6522C50.4319 54.7467 51.1321 55.0303 52.5135 54.1232C52.6688 54.0202 52.8429 53.9486 53.0259 53.9128C53.2088 53.877 53.3971 53.8776 53.5798 53.9145C53.7626 53.9513 53.9363 54.0237 54.091 54.1276C54.2457 54.2315 54.3785 54.3649 54.4817 54.52C54.5848 54.6751 54.6563 54.8489 54.6921 55.0317C54.7279 55.2144 54.7274 55.4023 54.6905 55.5848C54.6536 55.7673 54.5811 55.9408 54.477 56.0953C54.373 56.2498 54.2394 56.3824 54.0841 56.4854C53.1252 57.1708 51.9883 57.5646 50.8103 57.6192Z"
        fill="#666566"
      />
      <path
        id="Vector_6"
        d="M88.5443 75.7933C89.4118 75.7933 90.115 75.091 90.115 74.2248C90.115 73.3585 89.4118 72.6562 88.5443 72.6562C87.6769 72.6562 86.9736 73.3585 86.9736 74.2248C86.9736 75.091 87.6769 75.7933 88.5443 75.7933Z"
        fill="#666566"
      />
      <path
        id="Vector_7"
        d="M96.5492 70.262C97.4167 70.262 98.1199 69.5598 98.1199 68.6935C98.1199 67.8272 97.4167 67.125 96.5492 67.125C95.6817 67.125 94.9785 67.8272 94.9785 68.6935C94.9785 69.5598 95.6817 70.262 96.5492 70.262Z"
        fill="#666566"
      />
      <path
        id="Vector_8"
        d="M99.0282 77.9339C99.8957 77.9339 100.599 77.2316 100.599 76.3654C100.599 75.4991 99.8957 74.7969 99.0282 74.7969C98.1607 74.7969 97.4575 75.4991 97.4575 76.3654C97.4575 77.2316 98.1607 77.9339 99.0282 77.9339Z"
        fill="#666566"
      />
      <path
        id="Vector_9"
        d="M109.55 76.387C110.417 76.387 111.12 75.6848 111.12 74.8185C111.12 73.9522 110.417 73.25 109.55 73.25C108.682 73.25 107.979 73.9522 107.979 74.8185C107.979 75.6848 108.682 76.387 109.55 76.387Z"
        fill="#666566"
      />
    </g>
    <defs>
      <clipPath id="clip0_1286_9009">
        <rect width="147" height="108" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
