/**
 *
 * ApplicationContextProvider
 *
 */
import { getToken } from '@anyonelab/frontend/utils/api-client'
import { customPostMessage } from '@anyonelab/frontend/utils/postMessage'
import { getAccessToken } from '@anyonelab/frontend/utils/storage'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useLinkPortalSlice } from '../../pages/LinkTreePage/slice'

export interface ApplicationContextProviderProps {}

const ApplicationContext = React.createContext({
  referer: '',
})
export const useApplication = () => React.useContext(ApplicationContext)

export const ApplicationContextProvider = ({
  children,
}: React.PropsWithChildren<ApplicationContextProviderProps>) => {
  const dispatch = useDispatch()

  const [referer, setReferer] = React.useState('')

  React.useEffect(() => {
    console.log(document.referrer)

    async function sendAccessToken() {
      const cookieToken = getAccessToken()
      const localStorageToken = window.localStorage.getItem('feathers-jwt')

      setReferer(document.referrer)
      customPostMessage(
        'LOGIN_SYNC',
        {
          accessToken: localStorageToken || cookieToken,
        },
        document.referrer,
      )
    }

    if (document.referrer) {
      sendAccessToken()
    }
  }, [])

  // React.useEffect(() => {
  //   dispatch(
  //     actions.updateState({
  //       avatar: {
  //         src:
  //           'https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
  //         style: 'circle',
  //       },
  //       title: {
  //         content: 'Alex Chuckles',
  //         fontFamily: 'Lato',
  //         fontSize: '38px',
  //         fontWeight: 'bold',
  //         fontStyle: 'normal',
  //         color: '#FFFFFF',
  //         align: 'center',
  //       },
  //       subtitle: {
  //         content: 'Instagram Influencer & Professional TikTok Dancer',
  //         fontFamily: 'Lato',
  //         fontSize: '20px',
  //         fontWeight: 'lighter',
  //         fontStyle: 'italic',
  //         color: '#FFFFFF',
  //         align: 'center',
  //       },
  //       profileSplash: {
  //         backgroundColor: 'pink',
  //       },
  //       links: [
  //         {
  //           buttonText: 'Instagram Giveaway! 🍑',
  //           externalUrl: 'https://www.instagram.com/p/CNofDlXp3Ga/',
  //           id: '132',
  //           type: 'external',
  //         },
  //         {
  //           buttonText: 'Japan 2020 Food Vlog 🤤🍆',
  //           externalUrl: 'https://www.youtube.com/watch?v=dHcSe4qbtc0',
  //           id: '22',
  //           type: 'external',
  //         },
  //       ],
  //       facebook: {
  //         type: 'facebook',
  //         url: '',
  //       },
  //       instagram: {
  //         type: 'instagram',
  //         url: '',
  //       },
  //       youtube: {
  //         type: 'youtube',
  //         url: '',
  //       },
  //     }),
  //   )
  // }, [dispatch, actions])

  return (
    <ApplicationContext.Provider value={{ referer }}>
      {children}
    </ApplicationContext.Provider>
  )
}
