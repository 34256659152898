import { motion, useAnimation, Variants } from 'framer-motion'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { Lock } from '../../../../components/Lock'

type LockOverlayProps = { locked: boolean } & React.HTMLAttributes<
  HTMLDivElement
>

export const LockOverlay = (props: LockOverlayProps) => {
  const controls = useAnimation()
  const shaking = async () => {
    await controls.start({ rotateZ: -10, transition: { duration: 0.1 } })
    await controls.start({ rotateZ: 10, transition: { duration: 0.1 } })
    await controls.start({ rotateZ: -5, transition: { duration: 0.05 } })
    await controls.start({ rotateZ: 5, transition: { duration: 0.05 } })
    await controls.start({ rotateZ: 0, transition: { duration: 0.025 } })
  }
  return (
    <Container onClick={shaking} visible={props.locked} {...props}>
      <motion.div animate={controls}>
        <Lock style={{ marginBottom: '14px' }} />
      </motion.div>
    </Container>
  )
}
const Container = styled.div<{ visible: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  // NOTE: blurry background UI.
  border-radius: 24px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(252, 253, 254, 0.5) 100%
  );
  backdrop-filter: blur(4px);
  box-shadow: 3px 4px 14px rgba(133, 133, 133, 0.25);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

const LockMessage = styled.p`
  font-family: Roboto;
  font-weight: ${({ theme }) => theme.fontWeight[700]};
  font-size: ${({ theme }) => theme.fontSize[14]};
  color: #808182;
`
