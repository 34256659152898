import React from 'react'
import styled from 'styled-components/macro'
import { DesktopBackIcon } from '../BackIcon/DesktopBackIcon'

type DesktopHeaderProps = {
  title: string
  subtitle: string
  onBack: () => void
} & React.HTMLAttributes<HTMLDivElement>

export const DesktopHeader = (props: DesktopHeaderProps) => {
  return (
    <Container>
      <IconContainer onClick={props.onBack}>
        <DesktopBackIcon />
      </IconContainer>
      <NavHeader children={props.title} />
      <Subtitle children={`( ${props.subtitle} )`} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 12px;
`

const NavHeader = styled.div`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
  margin-left: 12px;
  margin-right: 18px;
`

const Subtitle = styled.div`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: #808182;
`

const IconContainer = styled.div`
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
