import React from 'react'

type LockProps = {
  color?: string
} & React.SVGProps<SVGSVGElement>

export const Lock = (props: LockProps) => {
  return (
    <svg
      width="41"
      height="52"
      viewBox="0 0 41 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_1187_6686)">
        <path
          d="M33.625 16.3333H31.4375V11.9524C31.4375 5.90667 26.5375 1 20.5 1C14.4625 1 9.5625 5.90667 9.5625 11.9524V16.3333H7.375C4.96875 16.3333 3 18.3048 3 20.7143V42.619C3 45.0286 4.96875 47 7.375 47H33.625C36.0312 47 38 45.0286 38 42.619V20.7143C38 18.3048 36.0312 16.3333 33.625 16.3333ZM13.9375 11.9524C13.9375 8.31619 16.8687 5.38095 20.5 5.38095C24.1313 5.38095 27.0625 8.31619 27.0625 11.9524V16.3333H13.9375V11.9524ZM33.625 42.619H7.375V20.7143H33.625V42.619ZM20.5 36.0476C22.9062 36.0476 24.875 34.0762 24.875 31.6667C24.875 29.2571 22.9062 27.2857 20.5 27.2857C18.0938 27.2857 16.125 29.2571 16.125 31.6667C16.125 34.0762 18.0938 36.0476 20.5 36.0476Z"
          fill={props.color || '#92A5BD'}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1187_6686"
          x="0"
          y="0"
          width="41"
          height="52"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1187_6686"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1187_6686"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
