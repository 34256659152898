import { RootState } from '@anyonelab/frontend/types'
import { createSelector } from '@reduxjs/toolkit'
import { initialState } from '.'

const selectSlice = (state: RootState) => state.dataInsight || initialState

export const selectDataInsight = createSelector([selectSlice], (state) => state)

export const makeSelectLinkPortalButtonClick = () =>
  createSelector(selectDataInsight, (state) => state.linkPortalButtonClick)

export const makeSelectLinkPortalPageView = () =>
  createSelector(selectDataInsight, (state) => state.linkPortalPageView)
