import { DesktopOnly, LeftOutlined, MobileOnly } from '@anyonelab/common/'
import { NavBar } from 'antd-mobile'
import { motion } from 'framer-motion'
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { AppLogo } from '../../components/AppLogo'
import LinkPortalDataInsight from '../../components/DataInsights/page/LinkPortalDataInsight'
import { DesktopHeader } from '../../components/DesktopHeader'
import { MobileHeader } from '../../components/MobileHeader'
import messages from './messages'

type DataInsightType = 'linkPortal'

// * This page is used to classify which type of data
export const DataInsightsPage = () => {
  const history = useHistory()
  const intl = useIntl()
  const location = useLocation<{ page: string; type: DataInsightType }>()
  const currentDataInsightTypeRef = React.useRef<DataInsightType>(
    location.state.type,
  )
  const currentPageRef = React.useRef(location.state.page)

  const CurrentDataInsightComponent = () => {
    switch (currentDataInsightTypeRef.current) {
      case 'linkPortal': {
        return <LinkPortalDataInsight page={currentPageRef.current} />
      }
      default: {
        return <LinkPortalDataInsight page={currentPageRef.current} />
      }
    }
  }

  return (
    <DataInsightPageContainer
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 100 }}
    >
      <DesktopOnly>
        <div className="desktop-container">
          <AppLogo />
          <DesktopHeader
            onBack={() => history.push('/dashboard')}
            title={intl.formatMessage(messages.dataInsights)}
            subtitle={intl.formatMessage(messages.snackBio)}
          />
          <div className="data-insight-container">
            <CurrentDataInsightComponent />
          </div>
        </div>
      </DesktopOnly>
      <MobileOnly>
        <div className="mobile-container">
          <MobileHeader
            title={intl.formatMessage(messages.dataInsights)}
            onBack={() => history.push('/dashboard')}
          />
          <CurrentDataInsightComponent />
        </div>
      </MobileOnly>
    </DataInsightPageContainer>
  )
}

const DataInsightPageContainer = styled(motion.div)`
  .desktop-container {
    padding: 0 70px 70px 70px;

    .go-back-text-container {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 16px;

      .icon {
        cursor: pointer;
        color: #808182;
      }

      .title {
        font-size: 16px;
        font-weight: 700;
      }

      .subtitle {
        font-family: 'Montserrat';
        font-size: 12px;
        color: #808182;
      }
    }

    .data-insight-container {
      padding: 16px 50px;
      width: 100%;
      background-color: white;
    }
  }

  .mobile-container {
    background-color: white;
  }
`
