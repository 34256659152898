import { BobaLoader, DonorDonationHistoryBoard } from '@anyonelab/common'
import { useGetDonorBobaDonation } from '@anyonelab/frontend/api/boba-api/hook/useGetBobaData'
import React from 'react'

const DEFAULT_PAGE_SIZE = 10

export const DonorDonationHistoryBoardPage = () => {
  const [pageNumber, setPageNumber] = React.useState(1)
  const { data, isLoading } = useGetDonorBobaDonation(
    (pageNumber - 1) * DEFAULT_PAGE_SIZE,
  )
  const changePage = (currentPage: number, pageSize?: number) => {
    setPageNumber(currentPage)
  }
  if (isLoading) return <BobaLoader />
  return (
    <>
      <DonorDonationHistoryBoard
        data={data?.data}
        pagination={{
          current: pageNumber,
          defaultCurrent: 1,
          pageSize: DEFAULT_PAGE_SIZE,
          total: data?.total || 0,
          onChange: changePage,
        }}
      />
    </>
  )
}
