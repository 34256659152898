import * as React from 'react'

import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import messages from './messages'
import { ProfileSplashCreatorWidget } from '@anyonelab/common/src/widgets/ProfileSplash'
import { useLinkPortalSlice } from './slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  ShareAltOutlined,
  Space,
  Divider,
  Typography,
  EyeOutlined,
  CopyOutlined,
  Pagebuilder,
  PagebuilderProps,
  useResponsive,
  setAnalyticsUserProperties,
  setAnalyticsUserId,
  SyncOutlined,
  CloudOutlined,
  track,
  NavBarEvent,
  LinkPortalEvent,
  openInNewTab,
  AvatarConfigurationEvent,
  TitleConfigurationEvent,
  TextWidgetConfigSchema,
  EventName,
  SubtitleConfigurationEvent,
  AvatarWidgetConfigSchema,
  LinkBlockWidgetConfigSchema,
  SocialMediaLinkButtonWidgetConfigSchema,
  LinkBlockConfigurationEvent,
  SocialMediaLinkButtonConfigurationEvent,
  ProfileSplashConfigurationEvent,
  PageBuilderContext,
  LEAD_FORM_WIDGET_ID,
  BOBAME_WIDGET_ID,
} from '@anyonelab/common'
import { LINK_PORTAL_SPLASH_INSTANCE_ID } from './constants'
import { PageConfigChangeSchema } from './types'
import { AVATAR_WIDGET_ID } from '@anyonelab/common/src/widgets/Avatar/constants'
import { TEXT_WIDGET_ID } from '@anyonelab/common/src/widgets/TextWidget/constants'
import {
  LinkBlockWidgetConfigDefaults,
  LINK_BLOCK_WIDGET_ID,
} from '@anyonelab/common/src/widgets/LinkBlock/constants'
import { PROFILE_SPLASH_WIDGET_ID } from '@anyonelab/common/src/widgets/ProfileSplash/constants'
import { SOCIAL_MEDIA_LINK_BUTTON_WIDGET_ID } from '@anyonelab/common/src/widgets/SocialMediaLinkButton/constants'
import { useGetPageList } from '@anyonelab/frontend/api/hooks/Page/useGetPageList'
import { Form, Input, message, Modal } from '@anyonelab/common'
import { getPageList, updatePage } from '@anyonelab/frontend/api/PageAPI'
import { Page } from '@anyonelab/frontend/models/Page'
import { AppHeader } from '../../components/AppLayout'
import { useGetUsers } from '@anyonelab/frontend/api/hooks/Users/useGetUsers'
import _ from 'lodash'
import { themeParser } from '@anyonelab/frontend/utils/themeParser'
import { LinkPortalState } from './slice/types'
import {
  makeSelectLinkPortal,
  makeSelectProfileSplash,
  makeSelectSavedStated,
  makeSelectWidgetList,
} from './slice/selectors'
import {
  AvatarWidget,
  LabelWidget,
  LinkButtonWidget,
  PageWidget,
  ProfileSplashWidget,
  WidgetType,
} from '@anyonelab/frontend/models/Widget'
import { reservedSubdomain } from './constant.domain'
import { useUser } from '../../providers/UserContextProvider/UserContext'
import { fbqTrack } from '@anyonelab/frontend/utils/facebookTrack'

const Filter = require('bad-words')

function convertPageToLinkPageSchema(_page: Page): Partial<LinkPortalState> {
  const page = _.cloneDeep(_page)
  page.widget_object.widgetList.forEach((widget) => {
    switch (widget.type) {
      case 'socialMediaButtonGroup': {
        // handle some old version social media data widget data and hardcode assign a color to them
        Object.keys(widget.data).forEach((socialType) => {
          if (typeof widget.data[socialType] === 'string') {
            widget.data[socialType.replace('Url', '')] = {
              // in case some is using old version facebookUrl, tiktokUrl change to new version facebook , tiktok
              url: widget.data[socialType],
              color: '#FFFFFF',
            }
            if (socialType !== 'email') {
              delete widget.data[socialType]
            }
          }
        })
        break
      }
      case 'linkButton': {
        // handle some old version button without type data and hardcode assign a type to them
        widget.data.type = widget.data.type ? widget.data.type : 'external'
        break
      }
    }
  })

  return {
    id: page.id,
    url: page.url,
    isPublished: page.is_published,
    profileSplash: page.widget_object.profileSplash,
    widgetList: page.widget_object.widgetList,
  }
}

export interface LinkTreePageProps {}

export const LinkTreePage = (props: LinkTreePageProps) => {
  const { userData } = useUser()
  const dispatch = useDispatch()
  const { actions } = useLinkPortalSlice()
  const intl = useIntl()

  // pass to theme config for theme selection
  const [
    selectedLinkPortalTheme,
    setSelectedLinkPortalTheme,
  ] = React.useState<PageWidget | null>(null)
  // after select the data and store the parsed data
  const [
    themeParsedData,
    setThemeParsedData,
  ] = React.useState<PageWidget | null>(null)

  const selectLinkPortal = useSelector(makeSelectLinkPortal())
  const selectWidgetList = useSelector(makeSelectWidgetList())
  const selectProfileSplash = useSelector(makeSelectProfileSplash())
  const savedState = useSelector(makeSelectSavedStated())
  const [showPublishModal, setShowPublishModal] = React.useState(false)
  const [activePage, setActivePage] = React.useState<
    Page | LinkPortalState | null
  >(null)
  const [isPublished, setIsPublished] = React.useState(false)
  const [isPreviewMode, setIsPreviewMode] = React.useState(false)

  const { data, isLoading } = useGetPageList()
  const getUserResult = useGetUsers()
  const [publicUrl, setPublicUrl] = React.useState<string>('')
  const [form] = Form.useForm()

  const { isMobileLayout } = useResponsive()

  React.useEffect(() => {
    // this mean the data already exist
    if (selectLinkPortal.id) {
      setActivePage(selectLinkPortal)
      setPublicUrl(selectLinkPortal.url || '')
      setIsPublished(selectLinkPortal.isPublished)
      return
    }

    if (!data?.data?.length || activePage) return

    const page = data.data[0]
    setActivePage(page)
    setPublicUrl(page.url || '')
    setIsPublished(page.is_published)
    dispatch(actions.updateState(convertPageToLinkPageSchema(page)))
  }, [data])

  React.useEffect(() => {
    if (!getUserResult?.data?.data) return
    const userData = getUserResult.data.data[0]
    setAnalyticsUserId(userData.email)
    setAnalyticsUserProperties({
      email: userData.email,
      area_code: userData.area_code || '',
    })
  }, [getUserResult])

  function getDifferenceInField<T extends keyof WidgetType>(
    newData: WidgetType[T],
    oldData: WidgetType[T],
  ): keyof WidgetType[T] | undefined {
    if (oldData === undefined) return
    for (let field in newData) {
      if (newData[field] !== oldData[field]) {
        // NOTE: Refactor the correct type in refactor period.
        return field
      }
    }
  }

  const titleTracker = (field: keyof LabelWidget | undefined): void => {
    if (field === undefined) return
    let eventName: EventName | '' = ''
    switch (field) {
      case 'fontColor':
        // NOTE: only fire analytics event when the select panel is focused.
        break
      case 'text':
        // NOTE: only fire analytics event when the input panel is focused.
        break
      case 'fontFamily':
        eventName = TitleConfigurationEvent.fontFamilyOnChange
        break
      case 'fontSize':
        eventName = TitleConfigurationEvent.fontSizeOnChange
        break
      case 'fontStyle':
        eventName = TitleConfigurationEvent.fontStyleOnChange
        break
      case 'fontWeight':
        eventName = TitleConfigurationEvent.fontWeightOnChange
        break
      default:
        break
    }
    eventName && track(eventName)
  }

  const subtitleTracker = (field: keyof LabelWidget | undefined): void => {
    if (field === undefined) return
    let eventName: EventName | '' = ''
    switch (field) {
      case 'fontColor':
        // NOTE: only fire analytics event when the select panel is focused.
        break
      case 'text':
        // NOTE: only fire analytics event when the input panel is focused.
        break
      case 'fontFamily':
        eventName = SubtitleConfigurationEvent.fontFamilyOnChange
        break
      case 'fontSize':
        eventName = SubtitleConfigurationEvent.fontSizeOnChange
        break
      case 'fontStyle':
        eventName = SubtitleConfigurationEvent.fontStyleOnChange
        break
      case 'fontWeight':
        eventName = SubtitleConfigurationEvent.fontWeightOnChange
        break
      default:
        break
    }
    eventName && track(eventName)
  }

  const avatarTracker = (field: keyof AvatarWidget | undefined): void => {
    if (field === undefined) return
    let eventName: EventName | '' = ''
    switch (field) {
      case 'imageUrl':
        eventName = AvatarConfigurationEvent.imageUpload
        break
      default:
        break
    }
    eventName && track(eventName)
  }

  const linkTracker = (field: keyof LinkButtonWidget | undefined): void => {
    if (field === undefined) return
    let eventName: EventName | '' = ''
    switch (field) {
      case 'backgroundColor':
        eventName = LinkBlockConfigurationEvent.backgroundColorOnChange
        break
      case 'title':
        // NOTE: only fire analytics event when the input panel is focused.
        break
      case 'buttonType':
        eventName = LinkBlockConfigurationEvent.buttonTypeOnChange
        break
      case 'url':
        // NOTE: only fire analytics event when the input panel is focused.
        break
      case 'fontColor':
        // NOTE: only fire analytics event when the select panel is focused.
        break
      case 'fontFamily':
        eventName = LinkBlockConfigurationEvent.fontFamilyOnChange
        break
      case 'fontSize':
        eventName = LinkBlockConfigurationEvent.fontSizeOnChange
        break
      case 'fontStyle':
        eventName = LinkBlockConfigurationEvent.fontStyleOnChange
        break
      case 'fontWeight':
        eventName = LinkBlockConfigurationEvent.fontWeightOnChange
        break
      default:
        break
    }
    eventName && track(eventName)
  }

  const profileSplashTracker = (
    field: keyof ProfileSplashWidget | undefined,
  ) => {
    if (field === undefined) return
    let eventName
    switch (field) {
      case 'backgroundDesktopImageURL':
        // NOTE: only fire analytics event when the select panel is focused.
        break
      case 'gradientColor':
        // NOTE: only fire analytics event when the select panel is focused.
        break
      case 'singleColor':
        // NOTE: only fire analytics event when the select panel is focused.
        break
      default:
        break
    }
    eventName && track(eventName)
  }

  const onConfigChange = (payload: PageConfigChangeSchema) => {
    console.log(payload)
    const { data, instanceId, widgetId } = payload
    const index = Number(instanceId.split('.').pop())

    // amplitude change tracker
    switch (widgetId) {
      case PROFILE_SPLASH_WIDGET_ID:
        profileSplashTracker(
          getDifferenceInField(payload.data, selectProfileSplash),
        )
        dispatch(actions.updateProfileSplash(data))
        return //  so won't pass to below update
      case AVATAR_WIDGET_ID:
        avatarTracker(getDifferenceInField(data, selectWidgetList[index].data))
        break
      case TEXT_WIDGET_ID:
        switch (instanceId) {
          case 'linkportal-profilesplash.TITLE':
            titleTracker(
              getDifferenceInField(data, selectWidgetList[index].data),
            )
            break
          case 'linkportal-profilesplash.SUBTITLE':
            subtitleTracker(
              getDifferenceInField(data, selectWidgetList[index].data),
            )
            break
        }
        break
      case LINK_BLOCK_WIDGET_ID:
        linkTracker(getDifferenceInField(data, selectWidgetList[index].data))
        break
      case SOCIAL_MEDIA_LINK_BUTTON_WIDGET_ID:
        // NOTE: only fire analytics event when the corresponding select panel is focused.
        break
      case LEAD_FORM_WIDGET_ID:
        // Todo @Louie add track
        break
      case BOBAME_WIDGET_ID:
        // Todo @Louie add track
        break
    }

    // is in widgetList
    dispatch(
      actions.updateWidgetState({
        index,
        data,
      }),
    )
  }

  const onDuplicateInstance: PagebuilderProps['onDuplicateInstance'] = (
    payload,
  ) => {
    const { data, type } = payload
    dispatch(actions.addWidget({ data: { data, type } }))
  }

  const onRemoveInstance: PagebuilderProps['onRemoveInstance'] = (payload) => {
    const { instanceId } = payload
    const index = Number(instanceId.split('.').pop())
    dispatch(actions.removeWidgetElement({ index }))
  }

  const onPromoteInstance: PagebuilderProps['onPromoteInstance'] = (
    payload,
  ) => {
    const { instanceId } = payload
    const index = Number(instanceId.split('.').pop())
    const firstWidgetIndex = 0
    if (index === firstWidgetIndex) return
    dispatch(actions.promoteWidgetElement({ index }))
  }

  const onDemoteInstance: PagebuilderProps['onDemoteInstance'] = (payload) => {
    const { instanceId } = payload
    const index = Number(instanceId.split('.').pop())
    const lastWidgetIndex = selectWidgetList.length - 1
    if (index === lastWidgetIndex) return
    dispatch(actions.demoteWidgetElement({ index }))
  }

  // parse theme data
  React.useEffect(() => {
    if (selectedLinkPortalTheme === null) {
      setThemeParsedData(null)
      return
    }

    const parsedData = themeParser(
      {
        profileSplash: selectProfileSplash,
        widgetList: selectWidgetList,
      },
      selectedLinkPortalTheme,
    )

    setThemeParsedData(parsedData)
  }, [selectedLinkPortalTheme])

  // save theme data
  const onLinkPortalThemeSave = () => {
    if (!themeParsedData) return
    dispatch(actions.onLinkPortalThemeSave(themeParsedData))
  }

  const onAddBobame = () => {
    dispatch(actions.addBobameWidget())
  }

  const onAddLeadForm = () => {
    dispatch(actions.addLeadForm())
  }

  const onAddLink = () => {
    dispatch(actions.addLink())
  }

  // logic to choose copy or share
  const isCopyButton = () => {
    return !(isMobileLayout && navigator.share)
  }

  const copyUrl = () => {
    navigator.clipboard.writeText(`https://${publicUrl}.anyonelab.com`)
    track(LinkPortalEvent.copyLinkClick)
    message.success(intl.formatMessage(messages.copiedLink))
  }

  const shareUrl = () => {
    if (navigator.share) {
      navigator.share({
        title: `${publicUrl}.anyonelab.com`,
        url: `https://${publicUrl}.anyonelab.com`,
        text: intl.formatMessage(messages.shareMessage, {
          subdomain: publicUrl,
        }),
      })
    }
  }

  const onPublish = async () => {
    const result = await getPageList()
    if (result.data.length > 0) {
      setActivePage(result.data[0])
      track(LinkPortalEvent.launchPublishPageModalClick)
      setShowPublishModal(true)
    }
  }

  const onUnpublish = () => {
    if (!activePage || !activePage.id) return
    updatePage(activePage.id, { is_published: false }).then((page: Page) => {
      // TODO refactor state vs redux.
      dispatch(actions.updateState(page))
      setActivePage(page)
      track(LinkPortalEvent.unpublishClick)
      setIsPublished(page.is_published)
      message.success(intl.formatMessage(messages.unpublished))
    })
  }

  const publish = ({ url }) => {
    if (!activePage || !activePage.id) return
    updatePage(activePage.id, { url, is_published: true })
      .then((page: Page) => {
        fbqTrack('PublishSnackbioButtonClick', {
          email: userData.email,
          domain: url,
        })
        // TODO refactor state vs redux.
        dispatch(actions.updateState(page))
        setActivePage(page)
        setPublicUrl(url)
        setIsPublished(page.is_published)
        track(LinkPortalEvent.publishPageClick)

        setShowPublishModal(false)

        message.success(intl.formatMessage(messages.publishedSuccessfully))
      })
      .catch(() => {
        track(LinkPortalEvent.usernameIsTakenIsShown, { taken_name: url })
        message.error(intl.formatMessage(messages.usernameIsAlreadyTaken))
      })
  }

  const togglePreviewMode = () => {
    isPreviewMode
      ? track(LinkPortalEvent.exitPreviewClick)
      : track(LinkPortalEvent.previewClick)
    setIsPreviewMode(!isPreviewMode)
  }

  const SaveStateComponent = React.useCallback(() => {
    return (
      <Space direction="horizontal">
        {savedState ? (
          <>
            <CloudOutlined />
            <Typography.Text style={{ whiteSpace: 'nowrap' }}>
              {intl.formatMessage(messages.lastActionSaved)}
            </Typography.Text>
          </>
        ) : (
          <>
            <SyncOutlined spin />
            <Typography.Text style={{ whiteSpace: 'nowrap' }}>
              {intl.formatMessage(messages.saving)}
            </Typography.Text>
          </>
        )}
      </Space>
    )
  }, [savedState])

  return (
    <LinkTreePageStyled>
      <AppHeader
        title={isMobileLayout ? '' : intl.formatMessage(messages.linkPortal)}
        customStateComponent={SaveStateComponent()}
        menu={
          !isLoading
            ? [
                {
                  title: intl.formatMessage(messages.publicUrl),
                  component: (
                    <Space direction="horizontal">
                      <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                        {intl.formatMessage(messages.publishedAt)}
                      </Typography.Text>
                      <Typography.Link
                        style={{ whiteSpace: 'nowrap', color: '#DE8181' }}
                        onClick={() => {
                          track(LinkPortalEvent.publishLinkClick)
                          openInNewTab(`https://${publicUrl}.anyonelab.com`)
                        }}
                      >
                        {`https://${publicUrl}.anyonelab.com`}
                      </Typography.Link>
                    </Space>
                  ),
                  hideTitleOnDesktop: true,
                  hidden: isMobileLayout || isPreviewMode || !isPublished,
                },
                {
                  title: isCopyButton()
                    ? intl.formatMessage(messages.copyUrl)
                    : intl.formatMessage(messages.share),
                  icon: isCopyButton() ? (
                    <CopyOutlined />
                  ) : (
                    <ShareAltOutlined />
                  ),
                  hideTitleOnDesktop: true,
                  hidden: isPreviewMode || !isPublished,
                  onClick: isCopyButton() ? copyUrl : shareUrl,
                },
                {
                  title: intl.formatMessage(messages.divider),
                  component: <Divider type="vertical" />,
                  hideTitleOnDesktop: true,
                  hidden: isMobileLayout || isPreviewMode || !isPublished,
                },
                {
                  title: intl.formatMessage(messages.preview),
                  icon: <EyeOutlined />,
                  hideTitleOnDesktop: true,
                  hidden: isMobileLayout || isPreviewMode,
                  onClick: togglePreviewMode,
                },
                {
                  title: intl.formatMessage(messages.exitPreview),
                  hidden: !isPreviewMode,
                  onClick: togglePreviewMode,
                },
                {
                  title: intl.formatMessage(messages.publish),
                  primary: true,
                  onClick: onPublish,
                  hidden: isPublished,
                },
                {
                  title: intl.formatMessage(messages.unpublish),
                  primary: true,
                  onClick: onUnpublish,
                  hidden: !isPublished,
                },
              ]
            : []
        }
      />

      <Pagebuilder
        isPreviewMode={isPreviewMode}
        onExitPreview={() => {
          track(LinkPortalEvent.exitPreviewClick)
          setIsPreviewMode(false)
        }}
        isLoading={isLoading}
        onConfigChange={onConfigChange}
        onDuplicateInstance={onDuplicateInstance}
        onRemoveInstance={onRemoveInstance}
        onPromoteInstance={onPromoteInstance}
        onDemoteInstance={onDemoteInstance}
        onLinkPortalThemeSave={onLinkPortalThemeSave}
        elementData={selectWidgetList}
        previewComponent={
          <ProfileSplashCreatorWidget
            domain={publicUrl}
            instanceLabel={intl.formatMessage(messages.linkPortalSplash)}
            instanceId={LINK_PORTAL_SPLASH_INSTANCE_ID}
            elementData={themeParsedData?.widgetList || selectWidgetList}
            data={themeParsedData?.profileSplash || selectProfileSplash}
            onAddLink={onAddLink}
            isPreviewComponent={true}
          />
        }
        setSelectedLinkPortalTheme={setSelectedLinkPortalTheme}
      >
        <Pagebuilder.Workspace>
          <ProfileSplashCreatorWidget
            domain={publicUrl}
            instanceLabel={intl.formatMessage(messages.linkPortalSplash)}
            instanceId={LINK_PORTAL_SPLASH_INSTANCE_ID}
            elementData={selectWidgetList}
            data={selectProfileSplash}
            onAddLink={onAddLink}
            onAddLeadForm={onAddLeadForm}
            onAddBobame={onAddBobame}
            isAdmin={userData.is_admin}
          />
        </Pagebuilder.Workspace>
      </Pagebuilder>

      <Modal
        title={intl.formatMessage(messages.publishPage)}
        visible={showPublishModal}
        okText={intl.formatMessage(messages.publish)}
        cancelText={intl.formatMessage(messages.cancel)}
        onOk={() =>
          form.validateFields().then((values) => {
            publish(values)
          })
        }
        onCancel={() => {
          track(LinkPortalEvent.cancelPublishClick)
          setShowPublishModal(false)
        }}
      >
        <Form
          form={form}
          initialValues={{ url: activePage?.url || '' }}
          layout="vertical"
          validateMessages={{
            required: intl.formatMessage(messages.publicPageUrlIsRequired),
          }}
        >
          <Form.Item
            name="url"
            label={intl.formatMessage(messages.publicPageUrl)}
            required
            rules={[
              {
                required: true,
                // NOTE: Only lowercase letters and numbers are allowed.
                validator: (_, value) => {
                  // check url not empty
                  if (!value) {
                    return Promise.reject(
                      new Error(
                        intl.formatMessage(messages.publicPageUrlIsRequired),
                      ),
                    )
                  }

                  // check url pattern
                  const regex = RegExp('^[a-z0-9,_,-]*$')
                  const match = regex.test(value)
                  if (!match) {
                    return Promise.reject(
                      new Error(
                        intl.formatMessage(
                          messages.weOnlySupportLowercaseLettersAndNumbers,
                        ),
                      ),
                    )
                  }

                  // check url is reserved
                  if (reservedSubdomain.includes(value)) {
                    return Promise.reject(
                      new Error(intl.formatMessage(messages.thisURLIsReserved)),
                    )
                  }

                  // check url bad words
                  const filter = new Filter()
                  if (filter.isProfane(value.replace(/_/g, ' '))) {
                    return Promise.reject(
                      new Error(
                        intl.formatMessage(messages.ProfanityIsNotAllowed),
                      ),
                    )
                  }

                  return Promise.resolve()
                },
              },
            ]}
          >
            <Input addonAfter={`.anyonelab.com`} />
          </Form.Item>
          <h4 style={{ color: '#000000' }}>
            {intl.formatMessage(messages.subdomainDescription)}
          </h4>
        </Form>
      </Modal>
    </LinkTreePageStyled>
  )
}

const LinkTreePageStyled = styled.div``
