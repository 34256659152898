import * as React from 'react'

export const BobaDisabledComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="108"
    height="156"
    viewBox="0 0 108 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Frame" clip-path="url(#clip0_1286_9141)">
      <path
        id="Vector"
        d="M103.092 68.82C107.015 57.94 106.517 45.943 101.706 35.4302C96.8947 24.9174 88.1587 16.7363 77.3921 12.6607C66.6255 8.58519 54.6963 8.94396 44.1907 13.6589C33.6852 18.3738 25.4501 27.065 21.2715 37.848L103.092 68.82Z"
        fill="#9C9B9C"
      />
      <path
        id="Vector_2"
        d="M20.6297 41.1734L0.0942412 133.083C-0.0317631 133.78 0.0878744 134.499 0.432471 135.116C0.777068 135.733 1.325 136.209 1.98169 136.463L52.9429 155.795C53.6062 156.061 54.3425 156.079 55.0179 155.846C55.6933 155.612 56.2629 155.143 56.6233 154.523L101.337 71.7657C101.538 71.3888 101.654 70.9724 101.679 70.5455C101.703 70.1186 101.635 69.6916 101.479 69.294C101.322 68.8963 101.082 68.5378 100.774 68.2432C100.466 67.9486 100.098 67.7248 99.6951 67.5879L24.4802 39.1034C24.0931 38.9637 23.681 38.9074 23.2708 38.9376C22.8606 38.9679 22.4613 39.0841 22.0985 39.2791C21.7358 39.4741 21.4177 39.7435 21.1647 40.0698C20.9117 40.396 20.7295 40.772 20.6297 41.1734Z"
        fill="#F2F2F2"
      />
      <path
        id="Vector_3"
        d="M82.9895 0.244765L82.9719 0.238071C80.9035 -0.544937 78.5959 0.50725 77.8176 2.5882L48.9696 79.7236C48.1913 81.8045 49.2372 84.1262 51.3055 84.9092L51.3232 84.9159C53.3915 85.6989 55.6991 84.6467 56.4774 82.5658L85.3254 5.43042C86.1037 3.34946 85.0579 1.02777 82.9895 0.244765Z"
        fill="#666566"
      />
      <path
        id="Vector_4"
        d="M104.862 63.645L24.7499 33.317C22.2133 32.3567 19.383 33.647 18.4285 36.1991L18.4219 36.2169C17.4674 38.769 18.7502 41.6164 21.2868 42.5767L101.399 72.9047C103.936 73.865 106.766 72.5746 107.72 70.0225L107.727 70.0047C108.681 67.4526 107.399 64.6053 104.862 63.645Z"
        fill="#666566"
      />
      <path
        id="Vector_5"
        d="M89.6538 93.0834L56.7178 154.572C56.3574 155.192 55.7878 155.662 55.1124 155.895C54.437 156.129 53.7006 156.111 53.0374 155.844L2.07618 136.513C1.41948 136.259 0.87155 135.782 0.526954 135.165C0.182357 134.548 0.0627193 133.829 0.188724 133.133L15.4204 64.9219L89.6538 93.0834Z"
        fill="#CECDCC"
      />
      <path
        id="Vector_6"
        d="M74.3088 11.125C77.2135 11.8219 79.9953 12.9619 82.5572 14.5052L69.2128 50.1868L60.2285 46.7876C60.2285 46.7876 74.6297 11.2579 74.3088 11.125Z"
        fill="#9C9B9C"
      />
      <path
        id="Vector_7"
        d="M15.1374 125.272C17.7851 125.272 19.9315 123.112 19.9315 120.448C19.9315 117.784 17.7851 115.625 15.1374 115.625C12.4897 115.625 10.3433 117.784 10.3433 120.448C10.3433 123.112 12.4897 125.272 15.1374 125.272Z"
        fill="#666566"
      />
      <path
        id="Vector_8"
        d="M15.3261 108.756C17.9738 108.756 20.1202 106.597 20.1202 103.933C20.1202 101.269 17.9738 99.1094 15.3261 99.1094C12.6784 99.1094 10.532 101.269 10.532 103.933C10.532 106.597 12.6784 108.756 15.3261 108.756Z"
        fill="#666566"
      />
      <path
        id="Vector_9"
        d="M23.0837 141.303C25.7314 141.303 27.8778 139.143 27.8778 136.48C27.8778 133.816 25.7314 131.656 23.0837 131.656C20.436 131.656 18.2896 133.816 18.2896 136.48C18.2896 139.143 20.436 141.303 23.0837 141.303Z"
        fill="#666566"
      />
      <path
        id="Vector_10"
        d="M58.9829 119.678C61.6306 119.678 63.777 117.518 63.777 114.855C63.777 112.191 61.6306 110.031 58.9829 110.031C56.3351 110.031 54.1887 112.191 54.1887 114.855C54.1887 117.518 56.3351 119.678 58.9829 119.678Z"
        fill="#666566"
      />
      <path
        id="Vector_11"
        d="M59.9643 138.022C62.612 138.022 64.7584 135.862 64.7584 133.198C64.7584 130.534 62.612 128.375 59.9643 128.375C57.3166 128.375 55.1702 130.534 55.1702 133.198C55.1702 135.862 57.3166 138.022 59.9643 138.022Z"
        fill="#666566"
      />
      <path
        id="Vector_12"
        d="M41.3351 136.631C43.9829 136.631 46.1293 134.472 46.1293 131.808C46.1293 129.144 43.9829 126.984 41.3351 126.984C38.6874 126.984 36.541 129.144 36.541 131.808C36.541 134.472 38.6874 136.631 41.3351 136.631Z"
        fill="#666566"
      />
      <path
        id="Vector_13"
        d="M50.5837 150.701C53.2314 150.701 55.3778 148.542 55.3778 145.878C55.3778 143.214 53.2314 141.055 50.5837 141.055C47.936 141.055 45.7896 143.214 45.7896 145.878C45.7896 148.542 47.936 150.701 50.5837 150.701Z"
        fill="#666566"
      />
      <path
        id="Vector_14"
        d="M35.7106 121.381C38.3584 121.381 40.5048 119.222 40.5048 116.558C40.5048 113.894 38.3584 111.734 35.7106 111.734C33.0629 111.734 30.9165 113.894 30.9165 116.558C30.9165 119.222 33.0629 121.381 35.7106 121.381Z"
        fill="#666566"
      />
      <path
        id="Vector_15"
        d="M68.8354 37.6408C67.5648 37.1141 66.5029 36.1793 65.8153 34.9822C65.7527 34.8578 65.7394 34.7142 65.778 34.5803C65.8165 34.4464 65.9042 34.3321 66.0231 34.2606C66.0832 34.2243 66.1499 34.2009 66.2194 34.1918C66.2889 34.1827 66.3595 34.1882 66.4268 34.2078C66.4941 34.2274 66.5567 34.2608 66.6107 34.3058C66.6646 34.3508 66.7088 34.4065 66.7404 34.4695C67.2161 35.4052 68.0192 36.1307 68.9951 36.5059C69.9709 36.8811 71.0503 36.8793 72.0251 36.5013C72.1528 36.4435 72.2981 36.4391 72.429 36.489C72.5599 36.5388 72.6658 36.6388 72.7234 36.7671C72.7515 36.8294 72.767 36.8966 72.7688 36.9649C72.7707 37.0332 72.7591 37.1012 72.7345 37.1649C72.7099 37.2286 72.6728 37.2867 72.6255 37.3358C72.5783 37.3849 72.5217 37.424 72.4592 37.4507C71.8991 37.7266 71.2894 37.8857 70.6666 37.9183C70.0439 37.9509 69.421 37.8567 68.8354 37.6408Z"
        fill="#666566"
      />
      <path
        id="Vector_16"
        d="M52.3767 34.5824C55.8688 34.5824 58.6996 31.7343 58.6996 28.2209C58.6996 24.7075 55.8688 21.8594 52.3767 21.8594C48.8846 21.8594 46.0537 24.7075 46.0537 28.2209C46.0537 31.7343 48.8846 34.5824 52.3767 34.5824Z"
        fill="#F2F2F2"
      />
      <path
        id="Vector_17"
        d="M46.3936 25.979C46.6783 25.1869 47.1165 24.4595 47.6829 23.8392C48.2493 23.2188 48.9326 22.7179 49.6929 22.3653C50.4533 22.0128 51.2757 21.8156 52.1124 21.7854C52.949 21.7551 53.7833 21.8922 54.5668 22.1889C55.3504 22.4857 56.0677 22.9361 56.6768 23.5139C57.286 24.0918 57.7751 24.7855 58.1157 25.555C58.4563 26.3245 58.6416 27.1543 58.6608 27.9964C58.6801 28.8385 58.5329 29.6761 58.2279 30.4605"
        fill="#666566"
      />
      <path
        id="Vector_18"
        d="M53.4151 28.518C52.9822 29.3294 52.2711 29.955 51.414 30.2783C50.557 30.6016 49.6125 30.6007 48.7561 30.2755C47.8997 29.9504 47.1898 29.3231 46.7587 28.5108C46.3276 27.6984 46.2048 26.7562 46.4127 25.8594"
        fill="#898889"
      />
      <path
        id="Vector_19"
        d="M87.4079 47.8559C90.9 47.8559 93.7309 45.0077 93.7309 41.4943C93.7309 37.981 90.9 35.1328 87.4079 35.1328C83.9159 35.1328 81.085 37.981 81.085 41.4943C81.085 45.0077 83.9159 47.8559 87.4079 47.8559Z"
        fill="#F2F2F2"
      />
      <path
        id="Vector_20"
        d="M81.4246 39.2313C82.0304 37.6734 83.222 36.4183 84.741 35.7378C86.26 35.0572 87.9843 35.0059 89.5405 35.5952C91.0967 36.1845 92.3595 37.3671 93.0555 38.8864C93.7515 40.4057 93.8245 42.1397 93.2589 43.7129"
        fill="#666566"
      />
      <path
        id="Vector_21"
        d="M88.446 41.8148C88.3239 42.3416 88.0911 42.836 87.7637 43.265C87.4364 43.694 87.0217 44.0479 86.5475 44.3026C86.0732 44.5573 85.5506 44.7071 85.0141 44.7421C84.4777 44.7771 83.9398 44.6964 83.4368 44.5054C82.9338 44.3145 82.4772 44.0176 82.0975 43.6348C81.7177 43.2519 81.4238 42.7919 81.2351 42.2854C81.0463 41.779 80.9671 41.2379 81.0031 40.6982C81.039 40.1586 81.1892 39.6328 81.4434 39.1562"
        fill="#898889"
      />
    </g>
    <defs>
      <clipPath id="clip0_1286_9141">
        <rect width="108" height="156" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
