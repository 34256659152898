import styled from 'styled-components'
import { motion } from 'framer-motion'

export const AppletButtonIcon = (props) => {
  return <Container {...props} />
}

export const Container = styled(motion.div)<{
  icon: string
  installed?: boolean
}>`
  overflow: hidden;
  width: 100%;
  // NOTE: Use aspect-ratio when it became more popular (currently 83.9%)
  /* aspect-ratio: 1; */
  margin-bottom: 4px;
  border-radius: ${({ theme }) => theme.borderRadius[16]};
  background-color: ${({ installed, theme }) =>
    installed ? theme.colors.pinkLight : theme.colors.grey80};
  background-image: ${({ icon }) => (icon ? 'url(' + icon + ')' : 'none')};
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }
`
