import { defineMessages } from 'react-intl'

export default defineMessages({
  title: { defaultMessage: 'Hello!' },
  name: { defaultMessage: 'Your name' },
  email: { defaultMessage: 'Email' },
  pickAvatar: { defaultMessage: 'Pick an avatar' },
  defaultAvatar: { defaultMessage: 'Cute Boba' },
  back: { defaultMessage: 'Back' },
  ctaMessage: { defaultMessage: 'Finish! Let me buy Boba' },
  sthMustWithin: { defaultMessage: '{label} must be between {min} and {max}' },
})
