import { LinkButtonDistribution } from '@anyonelab/frontend/types/DataInsight'
import { Table } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import message from './messages'
import { transformDateToDMFormat } from '../../utils/dataInsightParser'
import { useResponsive } from '@anyonelab/common/'
import { LockOverlay } from '../LockOverlay'

interface PageClickTableComponentProps {
  data: LinkButtonDistribution[]
  locked: boolean
}

function PageClickTableComponent({
  data,
  locked,
}: PageClickTableComponentProps) {
  const { isMobileLayout } = useResponsive()
  const intl = useIntl()

  const ButtonClickComponent = React.useCallback(() => {
    return data.map((buttonClick) => {
      const title = buttonClick.link_button_title
      const total = buttonClick.total_clicks
      const distribution = buttonClick.distribution

      const columns = [
        {
          title: intl.formatMessage(message.total),
          key: 'total',
          dataIndex: 'total',
          align: 'center',
          render: (value) => <div className="total-column">{value}</div>,
        },
      ]

      distribution.forEach(({ date }) => {
        columns.push({
          title: transformDateToDMFormat(date),
          key: date,
          dataIndex: date,
          align: 'center',
          render: (value) => <>{value}</>,
        })
      })

      const dataSource = distribution.reduce(
        (prev, curr) => {
          prev[curr.date] = curr.number_of_clicks
          return prev
        },
        { key: 0, total },
      )

      return (
        <ButtonStatisticContainer>
          <div className="button-title-container">
            <div className="dot" />
            <div className="button-title">{title}</div>
          </div>
          <div className={`table ${!isMobileLayout && 'desktop'}`}>
            <Table
              columns={columns}
              pagination={false}
              bordered={false}
              dataSource={[dataSource]}
            />
          </div>
        </ButtonStatisticContainer>
      )
    })
  }, [data])

  return (
    <Container>
      <TableContainer>
        <div className="title">
          {intl.formatMessage(message.dailyButtonClicks)}
        </div>
        {ButtonClickComponent()}
      </TableContainer>
      <LockOverlay locked={locked} />
    </Container>
  )
}

export default PageClickTableComponent

const Container = styled.div`
  position: relative;
  min-height: 200px;
  margin-bottom: 36px;
`

const ButtonStatisticContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .button-title-container {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #6c7b8a08;
    color: #6c7b8a;
    border-radius: 26px;
    margin-bottom: 16px;
    padding: ${({ theme }) => theme.padding[8]}
      ${({ theme }) => theme.padding[12]};

    .dot {
      height: 6px;
      width: 6px;
      background-color: #979797;
      border-radius: 100%;
      margin: 8px;
    }

    .button-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .table {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  }

  .desktop {
    flex: 1;
  }
`

// Todo need to think about the X overflow issue
const TableContainer = styled.div`
  font-family: 'Roboto';
  margin-top: 20px;

  .title {
    color: #bdcadb;
    margin-bottom: 20px;
  }

  .total-column {
    color: #ee8183;
  }

  /* all head */
  .ant-table-thead > tr > th {
    white-space: nowrap;
    background: #ffffff;
    color: #6c7b8a;
    font-weight: 600;
    border-right: solid 2px #6c7b8a08;
    border-bottom: solid 2px #6c7b8a08;
    border-top: none;
    padding: 8px;
  }

  /* all box */
  .ant-table-tbody > tr > td {
    white-space: nowrap;
    background: #ffffff;
    color: #6c7b8a;
    border-right: solid 2px #6c7b8a08;
    border-bottom: solid 2px #6c7b8a08;
    padding: 8px;
  }

  /* last column border right header */
  .ant-table-thead > tr > th:last-child {
    border-right: none;
  }

  /* last column border right */
  .ant-table-tbody > tr > td:last-child {
    border-right: none;
  }

  /* Last row border bottom  */
  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }
`
