import { useGetDataInsight } from '@anyonelab/frontend/api/hooks/DataInsight/useGetDataInsight'
import { useDataInsightSlice } from '@anyonelab/frontend/app/pages/DataInsightsPage/slice'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  buttonClickDataInsightParser,
  pageViewDataInsightParser,
} from '../utils/dataInsightParser'
import { Tabs } from 'antd'
import { NavBar } from 'antd-mobile'
import { PageViewPage } from '../components/PageViewPage'
import { PageClickPage } from '../components/PageClickPage'
import messages from './messages'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { DesktopOnly, MobileOnly } from '@anyonelab/common/'
import {
  makeSelectLinkPortalButtonClick,
  makeSelectLinkPortalPageView,
} from '@anyonelab/frontend/app/pages/DataInsightsPage/slice/selectors'
import { DataInsightContext, DateRange } from '../Context'
import './style.css'

import styled from 'styled-components'
const { TabPane } = Tabs

interface LinkPortalDataInsightProps {
  page: string
}

function LinkPortalDataInsight({ page }: LinkPortalDataInsightProps) {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { actions } = useDataInsightSlice()
  const {
    data: pageViewFetchedData,
    isLoading: isLoadingPageViewData,
  } = useGetDataInsight('page-view')

  const {
    data: buttonClickFetchedData,
    isLoading: isLoadingButtonClickData,
  } = useGetDataInsight('page-click')

  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange>(
    '7',
  )

  const dateRangeOnChange = (date: DateRange) => {
    setSelectedDateRange(date)
  }

  React.useEffect(() => {
    if (pageViewFetchedData) {
      dispatch(
        actions.updateLinkPortalPageView(
          pageViewDataInsightParser(pageViewFetchedData.data),
        ),
      )
    }
  }, [pageViewFetchedData])

  const linkPortalButtonClick = useSelector(makeSelectLinkPortalButtonClick())
  const linkPortalPageView = useSelector(makeSelectLinkPortalPageView())

  React.useEffect(() => {
    if (buttonClickFetchedData) {
      dispatch(
        actions.updateLinkPortalButtonClick(
          buttonClickDataInsightParser(buttonClickFetchedData.data),
        ),
      )
    }
  }, [buttonClickFetchedData])

  return (
    <DataInsightContext.Provider
      value={{
        dateRange: selectedDateRange,
        dateRangeOnChange,
      }}
    >
      <DesktopOnly>
        <Tabs
          defaultActiveKey={page || '1'}
          tabBarStyle={{ width: '50%' }}
          moreIcon={false}
        >
          <TabPane tab={intl.formatMessage(messages.pageViews)} key="1">
            <DesktopStatisticContainer>
              {linkPortalPageView ? (
                <PageViewPage
                  data={linkPortalPageView}
                  isLoading={isLoadingPageViewData}
                />
              ) : (
                <></>
              )}
            </DesktopStatisticContainer>
          </TabPane>
          <TabPane tab={intl.formatMessage(messages.pageClicks)} key="2">
            <DesktopStatisticContainer>
              {linkPortalButtonClick ? (
                <PageClickPage
                  data={linkPortalButtonClick}
                  isLoading={isLoadingButtonClickData}
                />
              ) : (
                <></>
              )}
            </DesktopStatisticContainer>
          </TabPane>
        </Tabs>
      </DesktopOnly>
      <MobileOnly>
        <Tabs defaultActiveKey={page || '1'} moreIcon={false}>
          <TabPane tab={intl.formatMessage(messages.pageViews)} key="1">
            <MobileStatisticContainer>
              {linkPortalPageView ? (
                <PageViewPage
                  data={linkPortalPageView}
                  isLoading={isLoadingPageViewData}
                />
              ) : (
                <></>
              )}
            </MobileStatisticContainer>
          </TabPane>
          <TabPane tab={intl.formatMessage(messages.pageClicks)} key="2">
            <MobileStatisticContainer>
              {linkPortalButtonClick ? (
                <PageClickPage
                  data={linkPortalButtonClick}
                  isLoading={isLoadingButtonClickData}
                />
              ) : (
                <></>
              )}
            </MobileStatisticContainer>
          </TabPane>
        </Tabs>
      </MobileOnly>
    </DataInsightContext.Provider>
  )
}

export default LinkPortalDataInsight

const DesktopStatisticContainer = styled.div`
  width: 90%;
  padding: 0px 20px;
`

const MobileStatisticContainer = styled.div`
  padding: 12px 20px;
  padding-bottom: ${({ theme }) => theme.padding['bottomNavbarHeight']};
`
