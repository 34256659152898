/**
 * Asynchronously loads the component for NotFoundPage
 */

import { lazyLoad } from '@anyonelab/frontend/utils/loadable'

export const NotFoundPage = lazyLoad(
  () => import('./NotFoundPage'),
  (module) => module.NotFoundPage,
)
