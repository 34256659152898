import * as React from 'react'
import {
  AppLogo,
  BobaComponent,
  ChipComponent,
  Divider,
  LogoStyled,
} from '@anyonelab/common'
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons'
import { useIntl } from 'react-intl'
import { FirebaseAuthContext } from '@anyonelab/frontend/app/providers/FirebaseAuthProvider/FirebaseAuthProvider'
import { useHistory, Redirect } from 'react-router-dom'
import { LoginFormStyled } from '@anyonelab/frontend/app/components/LoginForm/styles/LoginFormStyled'
import { useSignInWithProvider } from '@anyonelab/frontend/app/components/LoginForm/hooks/useSignInWithProvider'
import { googleProvider } from '@anyonelab/frontend/app/services/auth/getGoogleProvider'
import { facebookProvider } from '@anyonelab/frontend/app/services/auth/getFacebookProvider'
import { AuthErrors } from '@anyonelab/frontend/app/components/LoginForm/components/AuthErrors/AuthErrors'
import styled from 'styled-components/macro'
import googleButtonImage from '../../../assets/btn_google_signin_light_normal_web@2x.png'
import facebookButtonImage from '../../../assets/btn_facebook_signin_light_normal_web@2x.png'
import { ReactComponent as BobaHeartIcon } from '@anyonelab/common/src/assets/boba-heart-icon.svg'
import messages from './messages'

export function BobaDonatorLoginForm() {
  const { isAuthenticated } = React.useContext(FirebaseAuthContext)
  const [authError, setAuthError] = React.useState<null | string>(null)
  const BOBA_HEART_WIDTH = 120

  const intl = useIntl()
  const history = useHistory()

  const onAuthError = (errMessage) => {
    setAuthError(errMessage)
  }

  const signInWithGoogleCb = useSignInWithProvider(
    googleProvider,
    history,
    onAuthError,
    '/boba/donator/register',
  )
  const signInWithFacebookCb = useSignInWithProvider(
    facebookProvider,
    history,
    onAuthError,
    '/boba/donator/register',
  )

  return (
    <StyledLoginFormStyled>
      <Title children={'Hello 👋'} />
      <Description children={intl.formatMessage(messages.introduction)} />
      <StyledBobaHeartIcon width={BOBA_HEART_WIDTH} />
      <SocialButtonsSectionStyled>
        <SignInButton
          src={googleButtonImage}
          alt="google-sign-in-button"
          onClick={signInWithGoogleCb}
        />
        <SignInButton
          style={{
            width: 214,
            paddingTop: 9,
            paddingBottom: 9,
            backgroundColor: '#4267b2',
            borderRadius: 5,
          }}
          src={facebookButtonImage}
          alt="facebook-sign-in-button"
          onClick={signInWithFacebookCb}
        />
      </SocialButtonsSectionStyled>
      <AppLogo />
    </StyledLoginFormStyled>
  )
}
const StyledLoginFormStyled = styled(LoginFormStyled)`
  background-color: #fafafa;
  margin: 0;
  padding: 24px 0;
  width: 100%;
  max-width: unset;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 40px;
  margin: 24px auto;
`

const Description = styled.div`
  color: #b6b6b6;
  font-size: 16px;
  font-weight: 300;
  margin: 0 32px 40px;
  text-align: center;
`
const StyledBobaHeartIcon = styled(BobaHeartIcon)`
  margin-bottom: 32px;
  transform: translateX(-8px);
`

const SocialButtonsSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 48px;
  button {
    width: 40px;
    max-width: 100%;
  }
`

const SignInButton = styled.img`
  width: 220px;
  margin: 6px 0;
  cursor: pointer;
`
