import { apiClient } from '@anyonelab/frontend/utils/api-client'
import { PaginatedResponse } from './common'
import { Page } from '../models/Page'

export const getPageList = (): Promise<PaginatedResponse<Page[]>> => {
  return apiClient.get('/page').then((res) => res.data)
}

export const createPage = (data: Partial<Page>): Promise<Page> => {
  return apiClient.post('/page', data).then((res) => res.data)
}

export const updatePage = (id: number, data: Partial<Page>): Promise<Page> => {
  return apiClient.patch(`/page/${id}`, data).then((res) => res.data)
}
