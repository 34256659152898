import { defineMessages } from 'react-intl'

export default defineMessages({
  linkPortal: {
    defaultMessage: 'SnackBio',
  },
  linkPortalSplash: {
    defaultMessage: 'SnackBio Splash',
  },
  publishedAt: {
    defaultMessage: 'Published at:',
  },
  copyUrl: {
    defaultMessage: 'Copy URL',
  },
  share: {
    defaultMessage: 'Share',
  },
  publicUrl: {
    defaultMessage: 'Public URL',
  },
  divider: {
    defaultMessage: 'Divider',
  },
  preview: {
    defaultMessage: 'Preview',
  },
  exitPreview: {
    defaultMessage: 'Exit Preview',
  },
  publish: {
    defaultMessage: 'Publish',
  },
  unpublish: {
    defaultMessage: 'Unpublish',
  },
  publishPage: {
    defaultMessage: 'Publish Page',
  },
  publicPageUrl: {
    defaultMessage: 'Public Page URL',
  },
  publicPageUrlIsRequired: {
    defaultMessage: 'Public Page URL is required',
  },
  weOnlySupportLowercaseLettersAndNumbers: {
    defaultMessage: 'We only support lowercase letters and numbers',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  lastActionSaved: {
    defaultMessage: 'Last action saved',
  },
  saving: {
    defaultMessage: 'saving',
  },
  unpublished: {
    defaultMessage: 'Unpublished',
  },
  publishedSuccessfully: {
    defaultMessage: 'Published successfully',
  },
  usernameIsAlreadyTaken: {
    defaultMessage: 'Username is already taken',
  },
  copiedLink: {
    defaultMessage: 'Copied link!',
  },
  shareMessage: {
    defaultMessage: "Check out {subdomain}'s SnackBio on AnyoneLab",
  },
  thisURLIsReserved: {
    defaultMessage: 'This URL is reserved',
  },
  ProfanityIsNotAllowed: {
    defaultMessage: 'Profanity is not allowed',
  },
  subdomainDescription: {
    defaultMessage: 'The name above will be synced with your BobaMe URL',
  },
})
