import {
  GetBobaDonationWithCommentInfoResponsePaginatedResponse,
  GetBobaDonorDonationResponsePaginatedResponse,
  GetBobaDontaionResponsePaginatedResponse,
} from './schema/boba-response.schema'
import { apiClient } from '@anyonelab/frontend/utils/api-client'
import { PatchBobaDonationRequest } from './schema/boba-request.schema'
import moment from 'moment'

export const getBobaDonations = (
  skip?: number,
): Promise<GetBobaDontaionResponsePaginatedResponse> => {
  try {
    return apiClient
      .get<GetBobaDontaionResponsePaginatedResponse>(`/boba-donations`, {
        params: {
          $skip: skip || 0,
          '$sort[createdAt]': -1,
        },
      })
      .then((res) => res.data)
  } catch (err) {
    throw err
  }
}

export const getBobaDonorDonations = (
  skip?: number,
): Promise<GetBobaDonorDonationResponsePaginatedResponse> => {
  try {
    return apiClient
      .get<GetBobaDonorDonationResponsePaginatedResponse>(`/boba-donations`, {
        params: {
          'my-donation-history': 'true',
          $skip: skip || 0,
          '$sort[createdAt]': -1,
        },
      })
      .then((res) => res.data)
  } catch (err) {
    throw err
  }
}

export const getBobaDonationWithCommentInfo = (
  skip?: number,
  limit?: number,
): Promise<GetBobaDonationWithCommentInfoResponsePaginatedResponse> => {
  try {
    return apiClient
      .get<GetBobaDonationWithCommentInfoResponsePaginatedResponse>(
        `/boba-donations`,
        {
          params: {
            'comment-info': 'true',
            $skip: skip || 0,
            $limit: limit || 100,
            '$sort[createdAt]': -1,
          },
        },
      )
      .then((res) => res.data)
  } catch (err) {
    throw err
  }
}

export const getBobaDonationsWithDonorInfo = (
  skip?: number,
): Promise<GetBobaDontaionResponsePaginatedResponse> => {
  try {
    return apiClient
      .get<GetBobaDontaionResponsePaginatedResponse>(`/boba-donations`, {
        params: {
          'donor-info': 'true',
          $skip: skip || 0,
          '$sort[createdAt]': -1,
        },
      })
      .then((res) => res.data)
  } catch (err) {
    throw err
  }
}

export const updateBobaDonations = (
  data: PatchBobaDonationRequest,
  id: number,
): Promise<any> => {
  try {
    return apiClient
      .patch(`/boba-donations/${id}`, data)
      .then((res) => res.data)
  } catch (err) {
    throw err
  }
}

export const getBobaDonationBobaCount = (dayBefore: number) => {
  const date = new Date(new Date().getTime() - dayBefore * 24 * 60 * 60 * 1000)
  const dateString = date.toISOString().slice(0, 19).replace('T', ' ')

  try {
    return apiClient
      .get(`/boba-donations?created_at[$gte]=${dateString}`, {
        params: {
          $select: ['boba_count', 'created_at'],
        },
      })
      .then((res) => res.data)
  } catch (err) {
    throw err
  }
}
