import { defineMessages } from 'react-intl'

export default defineMessages({
  dailyPageViews: {
    defaultMessage: 'Daily Page Views',
  },
  date: {
    defaultMessage: 'Date',
  },
  views: {
    defaultMessage: 'Views',
  },
  total: {
    defaultMessage: 'Total',
  },
})
