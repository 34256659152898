import { getMarketplaceApps } from '../../MarketplaceAPI'
import { MarketplaceApp } from '@anyonelab/frontend/models/MarketplaceApp'
import { useQuery, UseQueryResult } from 'react-query'
import { PaginatedResponse } from '../../common'

export const useGetMarketplaceApps = (): UseQueryResult<
  PaginatedResponse<MarketplaceApp[]>,
  Error
> => {
  return useQuery<PaginatedResponse<MarketplaceApp[]>, Error>(
    'marketplace-app',
    getMarketplaceApps,
    {
      refetchOnWindowFocus: false,
    },
  )
}
