import React, { SVGProps } from 'react'

type DoneIconProps = {
  backgroundColor?: string
  tickColor?: string
} & React.SVGProps<SVGSVGElement>

export const DoneIcon = (props: DoneIconProps) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="done">
        <circle
          id="Ellipse 71"
          cx="11.5"
          cy="11.5"
          r="11.5"
          fill={props.backgroundColor || '#CCA78C'}
        />
        <path
          id="Vector"
          d="M9.81818 14.1194L6.95455 11.2985L6 12.2388L9.81818 16L18 7.9403L17.0455 7L9.81818 14.1194Z"
          fill={props.tickColor || '#F2EADF'}
        />
      </g>
    </svg>
  )
}
