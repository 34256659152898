import { defineMessages } from 'react-intl'

export default defineMessages({
  dashboard: { defaultMessage: 'Dashboard' },
  linkPortal: { defaultMessage: 'SnackBio' },
  boba: { defaultMessage: 'Boba' },
  leaveUsFeedback: { defaultMessage: 'Leave us feedback' },
  changeLanguage: { defaultMessage: 'Change language' },
  discord: { defaultMessage: 'Join the Discord community' },
  instagram: { defaultMessage: 'Follow us on Instagram' },
  adminPanel: { defaultMessage: 'Admin Panel' },
})
