import {
  DesktopOnly,
  BalanceBoard,
  BalanceStatusBoard,
  BobaBackButton,
  CountingTable,
  BobaCharacterDisplay,
  CashoutHistoryBoard,
  DonationHistoryBoard,
  SettingBoard,
  MobileOnly,
  BobaBoardContainer,
  useResponsive,
  useImageUrlChecker,
  Divider,
} from '@anyonelab/common/'
import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import {
  NavLink,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import styled from 'styled-components/macro'
import { useBobaUser } from '../../providers/BobaUserProvider'
import {
  SETTING_PAGES,
  SETTING_PAGE_ACTIVATION_ACCESS_RIGHT,
  SETTING_PAGE_ROUTES,
} from './const'
import messages from './messages'
import { BalanceBoardPage } from './pages/BalanceBoardPage'
import { CashoutHistoryBoardPage } from './pages/CashoutHistoryBoardPage'
import { DonationHistoryBoardPage } from './pages/DonationHistoryBoardPage'
import { SettingBoardPage } from './pages/SettingBoardPage'
import { makeSelectBobaSetting } from './slice/selectors'

type SettingPageProps = {} & HTMLAttributes<HTMLDivElement>

export const SettingPage = (props: SettingPageProps) => {
  const { children } = props
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()
  const { isMobileLayout } = useResponsive()
  const { bobaUserAccount: userData, snackBioAvatarImageUrl } = useBobaUser()
  // ! test the updated image and use original image replace
  const { isWaitingThumbnail } = useImageUrlChecker(userData?.avatar_image_url)
  let userAvatarUrl = userData?.avatar_image_url
  if (userData?.avatar_image_url) {
    userAvatarUrl = isWaitingThumbnail
      ? userData?.avatar_image_url.replace('thumbnail', 'original')
      : userData?.avatar_image_url
  }
  // const userData = queryClient.getQueryData('boba-user-accounts').data[0]

  // ! it will not be the case in our bussiness logic, but typescript blame it, need think think how to due with it
  // if (!userData) {
  //   return null
  // }

  return (
    <Container isMobileLayout={isMobileLayout}>
      <MobileOnly>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '20px',
            marginBottom: '36px',
          }}
        >
          <BobaBackButton
            text={intl.formatMessage(messages.backToSomewhere, {
              somewhere: intl.formatMessage(messages.bar),
            })}
            onClick={() => history.push('/boba')}
            hideArrow={false}
          />
        </div>
        <MenuItemContainer
          style={{ alignItems: 'center', marginBottom: '16px' }}
        >
          {SETTING_PAGES.map((item) => {
            const isActive = location.pathname === SETTING_PAGE_ROUTES[item]
            if (
              SETTING_PAGE_ACTIVATION_ACCESS_RIGHT[item] &&
              !userData?.is_boba_user_activated
            ) {
              return null
            }

            return (
              <MenuItem
                to={SETTING_PAGE_ROUTES[item]}
                className={isActive ? 'selected' : ''}
                children={intl.formatMessage(messages[item])}
              />
            )
          })}
        </MenuItemContainer>
        <ContentContainer>{children}</ContentContainer>
      </MobileOnly>
      <DesktopOnly>
        <BobaBackButton
          style={{ alignSelf: 'start', marginBottom: '60px' }}
          text={intl.formatMessage(messages.backToSomewhere, {
            somewhere: intl.formatMessage(messages.bar),
          })}
          onClick={() => history.push('/boba')}
        />
        <LayoutContainer>
          <MenuContainer style={{ marginRight: '120px' }}>
            <BobaCharacterDisplay
              image={userAvatarUrl || snackBioAvatarImageUrl || ''}
              style={{ marginBottom: '24px' }}
              size={150}
              direction="left"
            />
            <UserName
              style={{ fontWeight: 'bold', marginBottom: '24px' }}
              children={userData?.full_name}
            />
            <MenuItemContainer>
              {SETTING_PAGES.map((item) => {
                const isActive = location.pathname === SETTING_PAGE_ROUTES[item]

                if (
                  SETTING_PAGE_ACTIVATION_ACCESS_RIGHT[item] &&
                  !userData?.is_boba_user_activated
                ) {
                  return null
                }
                return (
                  <>
                    {item === 'donationHistory' ? (
                      <Divider
                        style={{
                          borderColor: '#b3b3b3',
                          height: 'auto',
                          marginBottom: 12,
                          marginTop: 0,
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    <MenuItem
                      key={item}
                      to={SETTING_PAGE_ROUTES[item]}
                      className={isActive ? 'selected' : ''}
                      children={intl.formatMessage(messages[item])}
                    />
                  </>
                )
              })}
            </MenuItemContainer>
          </MenuContainer>
          <ContentContainer>{children}</ContentContainer>
        </LayoutContainer>
      </DesktopOnly>
    </Container>
  )
}

const Container = styled.div<{ isMobileLayout: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isMobileLayout }) =>
    isMobileLayout ? '24px 12px 120px' : '64px 90px'};
  background-color: #f2eadf;
  min-height: 100vh;
  min-width: fit-content;
  width: 100%;
`

const LayoutContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  max-width: 200px;
`

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const MenuItem = styled(NavLink)`
  color: #4a1e11;
  font-family: '${({ theme }) => theme.bobaFontFamily.primary}';
  cursor: pointer;
  &:hover {
    color: unset;
  }
  &.selected {
    font-weight: bold;
  }
  margin-bottom: 12px;
`

const UserName = styled.div`
  color: #4a1e11;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
`

const ContentContainer = styled.div``
