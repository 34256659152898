import React from 'react'

export type DateRange = '7' | '14' | '30'

interface DateInsightContextProps {
  dateRange: DateRange
  dateRangeOnChange: (date: DateRange) => void
}

const DefaultDateInsightContext: DateInsightContextProps = {
  dateRange: '7',
  dateRangeOnChange: () => undefined,
}

export const DataInsightContext = React.createContext<DateInsightContextProps>(
  DefaultDateInsightContext,
)

export const useDataInsight = () => React.useContext(DataInsightContext)
