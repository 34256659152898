import {
  AnalyticsContext,
  BobaDonationReceipt,
  BobaMeSettingPageEvent,
  captureElement,
  DownloadIcon,
  PrimaryButton,
  track,
  useResponsive,
} from '@anyonelab/common/'
import { GetBobaDontaionResponse } from '@anyonelab/frontend/api/boba-api/schema/boba-response.schema'
import { useBobaUser } from '@anyonelab/frontend/app/providers/BobaUserProvider'
import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import messages from './messages'

type DonationReceiptContentProps = {
  data: GetBobaDontaionResponse | null
} & HTMLAttributes<HTMLDivElement>

export const DonationReceiptContent = (props: DonationReceiptContentProps) => {
  const intl = useIntl()
  const { isMobileLayout } = useResponsive()
  const { data, ...rest } = props
  const receiptElement = React.useRef<any>()
  const { bobaUserAccount } = useBobaUser()
  const { amplitudeInit } = React.useContext(AnalyticsContext)

  const downloadOnClick = () => {
    const ELEMENT_SELECTOR = '.donation-receipt-container>.receipt'
    const ANIMATED_RECEIPT_IMAGE_ELEMENT_SELECTOR =
      '.donation-receipt-container .animated-receipt-image'
    const STATIC_RECEIPT_IMAGE_ELEMENT_SELECTOR =
      '.donation-receipt-container .static-receipt-image'

    const element = document.querySelector(ELEMENT_SELECTOR) as HTMLElement
    const animatedReceiptImageElement = document.querySelector(
      ANIMATED_RECEIPT_IMAGE_ELEMENT_SELECTOR,
    ) as HTMLElement
    const staticReceiptImageElement = document.querySelector(
      STATIC_RECEIPT_IMAGE_ELEMENT_SELECTOR,
    ) as HTMLElement

    if (!element) return
    element.classList.remove('shadow')
    animatedReceiptImageElement.classList.add('hide')
    staticReceiptImageElement.classList.remove('hide')
    captureElement(element, undefined, () => {
      element.classList.add('shadow')
      animatedReceiptImageElement.classList.remove('hide')
      staticReceiptImageElement.classList.add('hide')
      amplitudeInit && track(BobaMeSettingPageEvent.downloadReceiptClick, {})
    })
  }

  if (!data) return <div>Loading</div>

  return (
    <Container
      className={`${
        isMobileLayout ? 'mobile' : 'desktop'
      } donation-receipt-container`}
      {...rest}
    >
      <Title
        className="title"
        style={{ marginBottom: '12px', padding: '0 36px' }}
        children={intl.formatMessage(messages.title, {})}
      />
      <Subtitle
        className="subtitle"
        style={{ marginBottom: '12px', padding: '0 36px' }}
        children={intl.formatMessage(messages.subtitle, { br: <br /> })}
      />
      <BobaDonationReceipt
        ref={receiptElement}
        className="receipt shadow"
        donated_amount={data.donated_amount}
        donor_name={data.donor_name}
        donor_note={data.donor_note}
        is_public_by_supporter={data.is_public_by_supporter}
        // TODO: Refactor, this should be count_details instead of boba_donation_details.
        boba_donation_details={data.boba_donation_details}
        currency={bobaUserAccount?.currency}
      />
      <StyledPrimaryButton
        className="button"
        onClick={downloadOnClick}
        content={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: isMobileLayout ? '12px 0' : '14px 30px',
            }}
          >
            <DownloadIcon
              style={{
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                marginRight: '12px',
              }}
            />
            {intl.formatMessage(messages.downloadPicture)}
          </div>
        }
      />
    </Container>
  )
}

// TODO: Refactor, I used transform scale due to unable to add horizontal padding for the receipt component.

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  .shadow {
    box-shadow: 5px 10px 14px rgba(0, 0, 0, 0.25);
  }
  &.mobile {
    .title {
      font-size: 18px;
    }
    .subtitle {
      font-size: 14px;
    }
    .button {
      font-size: 18px;
    }
    .receipt {
      transform: scale(0.8);
      margin: -72px 0 -36px;
    }
  }
  &.desktop {
    .title {
      font-size: 24px;
    }
    .subtitle {
      font-size: 16px;
    }
    .button {
      font-size: 16px;
      width: fit-content;
    }
    .receipt {
      margin: 0 0 36px;
    }
  }
`

const Title = styled.div`
  color: #62463e;
  font-weight: 700;
  text-align: center;
`

const Subtitle = styled.div`
  color: #7c7c7c;
  font-weight: 500;
  text-align: center;
`

const StyledPrimaryButton = styled(PrimaryButton)``
