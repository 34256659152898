import * as React from 'react'
import { Dispatch, SetStateAction } from 'react'
import { User } from '../../../models/User'

export type UserContextValue = {
  userData: Partial<User>
  setUserData: Dispatch<SetStateAction<Partial<User>>>
  isLoadingUserFetching: boolean
  isBobaEarlyBirdUser: boolean
}

export const UserContext = React.createContext<UserContextValue>({
  userData: {
    id: undefined,
    uuid: undefined,
    created_at: undefined,
    updated_at: undefined,
    is_active: undefined,
    first_name: undefined,
    last_name: undefined,
    preferred_name: undefined,
    email: undefined,
    area_code: undefined,
    phone_number: undefined,
    external_uid: undefined,
    profile_image_url: undefined,
    profile_image_object: undefined,
    last_login: undefined,
    default_brand_styles: undefined,
    is_onboarded: undefined,
    is_admin: undefined,
    instagram_username: undefined,
    facebook_access_token: undefined,
    facebook_long_lived_access_token: undefined,
    facebook_access_token_expires_in: undefined,
    google_auth_code: undefined,
    google_access_token: undefined,
    google_refresh_token: undefined,
    google_access_token_expiry_date: undefined,
    youtube_channel_title: undefined,
    instagram_facebook_access_token: undefined,
    instagram_facebook_long_lived_access_token: undefined,
    instagram_facebook_access_token_expires_in: undefined,
    app_installations: undefined,
    viewed_notifications: undefined,
  },
  setUserData: () => {},
  isLoadingUserFetching: false,
  isBobaEarlyBirdUser: false,
})

export const useUser = () => React.useContext(UserContext)
