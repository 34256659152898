import React, { PropsWithChildren } from 'react'
import { Drawer, DrawerProps } from 'antd'
import { Navigation } from '../Navigation'
import styled from 'styled-components'
import logo from '../AppLayout/assets/Logo.svg'

interface NavigationBarContextProps {
  showDrawer: () => void
}

export const useNavigationBar = () => React.useContext(NavigationBarContext)
export const NavigationBarContext = React.createContext<
  DrawerProps & NavigationBarContextProps
>({
  showDrawer: () => undefined,
  onClose: () => undefined,
  visible: false,
})

const HEADER_HEIGHT = '54px'

const StyledDrawer = styled(Drawer)`
  .logo {
    height: ${HEADER_HEIGHT};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export function DrawerNavigation() {
  const { onClose, visible } = useNavigationBar()
  return (
    <StyledDrawer
      closable={false}
      placement="left"
      onClose={onClose}
      visible={visible}
      bodyStyle={{
        padding: 0,
        margin: 0,
      }}
      contentWrapperStyle={{
        padding: 0,
        margin: 0,
        width: 80,
      }}
      drawerStyle={{ padding: 0, margin: 0 }}
    >
      <>
        <div className="logo">
          <img src={logo} alt="AnyoneLab" />
        </div>{' '}
        <Navigation />
      </>
    </StyledDrawer>
  )
}

interface NavigationDrawerProviderProps {}
export function NavigationDrawerProvider({
  children,
}: PropsWithChildren<NavigationDrawerProviderProps>) {
  const [visible, setVisible] = React.useState(false)

  const onClose = () => setVisible(false)
  const showDrawer = () => setVisible(true)

  return (
    <NavigationBarContext.Provider
      value={{
        onClose,
        showDrawer,
        visible,
      }}
    >
      {children}
      <DrawerNavigation />
    </NavigationBarContext.Provider>
  )
}
