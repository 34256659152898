import { createGlobalStyle } from 'styled-components/macro'

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    /* min-width: 855px; */
    background-color: #e5e5e5;
    font-size: 16px;
    
  }

  body {
    font-family: 'Roboto', Arial, sans-serif;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  svg {
    vertical-align: initial;
  }

  .appPopUpCardCSS .ant-modal-footer,
.desktop-notification-wrapper .ant-modal-footer {
  display: none;
}

.appPopUpCardCSS .ant-modal-body,
.desktop-notification-wrapper .ant-modal-body {
  padding: 0;
}

.appPopUpCardCSS .ant-modal-content,
.desktop-notification-wrapper .ant-modal-content {
  border-radius: 16px;
}

`
