import { CircleLogoStyled, LogoStyled } from '@anyonelab/common/'
import React from 'react'
import styled from 'styled-components/macro'

type AppLogoProps = {} & React.HTMLAttributes<HTMLDivElement>

export const AppLogo = (props: AppLogoProps) => {
  return (
    <LogoContainer>
      <CircleLogoStyled width={18} />
      <LogoStyled width={70} />
    </LogoContainer>
  )
}

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 0;
  gap: 8px;
`
