import { AppPopUpCard } from '@anyonelab/frontend/app/components/AppPopUpCard'
import { AppPopUpCardDataProps } from '@anyonelab/frontend/app/components/AppPopUpCard/type'
import { AppletButton } from '@anyonelab/frontend/app/components/Marketplace/components/AppletButton'
import { appDataParser } from '@anyonelab/frontend/app/components/Marketplace/utils/appDataParser'
import { selectLocale } from '@anyonelab/frontend/app/providers/LanguageProvider/slice/selectors'
import { MarketplaceApp } from '@anyonelab/frontend/models/MarketplaceApp'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { DEFAULT_APP_FORM_PLACEHOLDER } from '../constant'
import AppCreateForm from './AppCreateForm'

export type MarketplaceFormType = Partial<MarketplaceApp> & {
  icon_select: 'upload' | 'svg'
  svg?: string
}

function AppCreatePage() {
  const locale = useSelector(selectLocale)
  const [appData, setAppData] = React.useState<MarketplaceFormType>(
    localStorage.getItem('CREATING_APP_SAVE') // * (2) Here get the app data into local storage
      ? JSON.parse(localStorage.getItem('CREATING_APP_SAVE')) // * // * (3) Here parse the app data to object
      : DEFAULT_APP_FORM_PLACEHOLDER,
  )
  const [parsedAppData, setParsedAppData] = React.useState<
    AppPopUpCardDataProps
  >(appDataParser(DEFAULT_APP_FORM_PLACEHOLDER as MarketplaceApp, locale))

  React.useEffect(() => {
    localStorage.setItem('CREATING_APP_SAVE', JSON.stringify(appData)) // * (1) Here set the app data into local storage
    setParsedAppData(appDataParser(appData as MarketplaceApp, locale))
  }, [appData])

  return (
    <StyledAppCreateContainer>
      <div className="preview">
        <h4>Icon Preview</h4>
        <div className="icon-preview">
          <DesktopAppButtonContainer>
            <AppletButton
              index={1}
              length={1}
              onClick={() => {}}
              name={parsedAppData.name}
              icon={parsedAppData.icon}
              description={parsedAppData.content}
              screenshotUrls={parsedAppData.screenshotUrls}
              installed={true}
              hasIntegration={parsedAppData.hasIntegration}
              redirectUrl={parsedAppData.redirectUrl}
              initAnimation={true}
              finishedAnimation={() => {}}
            />
          </DesktopAppButtonContainer>
        </div>
      </div>
      <div className="preview">
        <h4>Popup Card Preview</h4>
        <div className="popup-card-preview ">
          <AppPopUpCard data={parsedAppData} closePopUpCard={() => {}} />
        </div>
      </div>
      <div className="form-container">
        <AppCreateForm appData={appData} setAppData={setAppData} />
      </div>
    </StyledAppCreateContainer>
  )
}

export default AppCreatePage

const StyledAppCreateContainer = styled.div`
  display: flex;
  padding: 0px 8px;
  flex-wrap: wrap;
  gap: 30px;

  .preview {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .popup-card-preview {
    width: ${(props) => props.theme.DesktopModalWidth};
    border-radius: 16px;
    background-color: white;
  }

  .form-container {
    flex: 1;
    padding-bottom: 100px;
  }
`

const DesktopAppButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  > div {
    width: 56px;
  }
  gap: 16px;
`
