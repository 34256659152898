import { apiClient } from '@anyonelab/frontend/utils/api-client'
import { message } from 'antd'
// ! Admin action
export const createEarlyBirdBobaBetaTester = () => {
  try {
    return apiClient
      .post('/create-early-bird-beta-testers')
      .then((res) => res.data)
      .catch((err) => {
        throw err
      })
  } catch (err) {
    throw err
  }
}

export async function createEarlyBirdBetaTester() {
  try {
    await createEarlyBirdBobaBetaTester()
    message.success({
      content: 'You are Boba Beta User now🎉🎉🎉🎉🎉',
    })
  } catch (err) {}
}
