import { auth } from '@anyonelab/frontend/app/services/auth/firebase'
import { History } from 'history'
import { AuthProvider, GoogleAuthProvider } from 'firebase/auth'
import { authenticateUser } from '@anyonelab/frontend/app/services/auth/signInWithProvider'
import { LoginPageEvent, track } from '@anyonelab/common'

export const useSignInWithProvider = (
  provider: AuthProvider,
  history: History<unknown>,
  onAuthError: (errMessage: string) => void,
  redirectRoute?: string,
) => {
  const isGoogleSignIn = provider instanceof GoogleAuthProvider
  return () => {
    isGoogleSignIn
      ? track(LoginPageEvent.googleLoginClick)
      : track(LoginPageEvent.facebookLoginClick)
    authenticateUser(auth, provider)
      .then((res) => {
        if (redirectRoute) {
          history.push(redirectRoute)
        }
        isGoogleSignIn
          ? track(LoginPageEvent.googleLoginSuccess)
          : track(LoginPageEvent.facebookLoginSuccess)
      })
      .catch((err) => {
        onAuthError(err.message)
        isGoogleSignIn
          ? track(LoginPageEvent.googleLoginFail)
          : track(LoginPageEvent.facebookLoginFail)
      })
  }
}
