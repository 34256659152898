import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { DateRange } from '../../Context/DataInsightContext'
import messages from './messages'
import CountUp from 'react-countup'

type NetCountComponentProps = {
  themeColor: string
  numberOfData: DateRange
  net: string
  netPercentage: string | null
  total: number | null
} & React.HTMLAttributes<HTMLDivElement>

function NetCountComponent(props: NetCountComponentProps) {
  const intl = useIntl()
  const hasSevenDaysData = props.numberOfData === '7'

  return (
    <Container color={props.themeColor} {...props}>
      {props.total !== undefined ? (
        <TotalRow
          children={intl.formatMessage(messages.total, { number: props.total })}
        />
      ) : (
        <></>
      )}
      <NetRow>
        <span
          style={{
            display: hasSevenDaysData ? 'inline' : 'none',
          }}
          className="with-color net"
        >
          <CountUp
            prefix={parseInt(props.net) >= 0 ? '+' : ''}
            useEasing
            end={parseInt(props.net)}
          />
        </span>
        <span
          style={{
            display: hasSevenDaysData ? 'inline' : 'none',
          }}
          className="with-color net-percentage"
        >
          {props.netPercentage ? (
            <CountUp
              useEasing
              prefix={
                parseInt(props.netPercentage.slice(0, -1)) >= 0 ? '+' : ''
              }
              suffix="%"
              end={parseInt(props.netPercentage.slice(0, -1))}
            />
          ) : (
            <></>
          )}
        </span>
        <span
          style={{
            display: hasSevenDaysData ? 'inline' : 'none',
          }}
          className="description"
        >
          {intl.formatMessage(messages.comparedToLastWeek)}
        </span>
      </NetRow>
    </Container>
  )
}

export default NetCountComponent

const Container = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  & .with-color {
    font-weight: ${({ theme }) => theme.fontWeight[600]};
    color: ${({ color }) => color};
  }
`

const TotalRow = styled.div`
  font-size: ${({ theme }) => theme.fontSize[28]};
  font-weight: ${({ theme }) => theme.fontWeight[600]};
  color: #6c7b8a;
  margin-bottom: ${({ theme }) => theme.padding[6]};
`

const NetRow = styled.div`
  font-family: Montserrat;
  & * {
    margin-right: ${({ theme }) => theme.padding[6]};
  }
  & > span {
    color: #808182;
  }
  & .net {
    font-size: ${({ theme }) => theme.fontSize[24]};
  }
  & .net-percentage {
    font-size: ${({ theme }) => theme.fontSize[14]};
  }
  & .net-percentage::after {
    content: ')';
  }
  & .net-percentage::before {
    content: '(';
  }
`
