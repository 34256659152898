import { apiClient } from '@anyonelab/frontend/utils/api-client'
import { PaginatedResponse } from './common'
import { User } from '../models/User'

export const USER_ENDPOINT = '/user'

export const getUsersList = () => {
  try {
    return apiClient.get(`${USER_ENDPOINT}`).then((res) => res.data)
  } catch (err) {
    console.log(err)
    throw err
  }
}

// TODO: add types
export const filterUsers = async (filterBy: string, filterValue?: string) => {
  try {
    const res = await apiClient.get(
      `${USER_ENDPOINT}?${filterBy}=${filterValue}`,
    )
    return res.data
  } catch (err) {
    console.log(err)
    throw err
  }
}

// TODO: the `data` type might need to be widen up if we allow to update more user's info in the future
// {is_onboarded: true}
// {is_onboarded: true, instagram_username: 'gfgfg' }

export const updateUser = async (id: number, data: Partial<User>) => {
  try {
    const res = await apiClient.patch<User>(`${USER_ENDPOINT}/${id}`, data)
    return res.data
  } catch (err) {
    console.log(err)
    throw err
  }
}
