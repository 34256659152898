import React from 'react'
import styled from 'styled-components'
import { WideRoundedButton } from '../../WideRoundedButton'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { MarketplaceEvent, openInNewTab, track } from '@anyonelab/common/'
import { AppPopUpCardDataProps } from '../type'
import messages from './messages'

interface TutorialComponentProps {
  data: AppPopUpCardDataProps
}

export const TutorialComponent = ({ data }: TutorialComponentProps) => {
  const {
    name,
    title,
    content,
    redirectUrl,
    screenshotUrls,
    hasIntegration,
    trackName,
  } = data

  const intl = useIntl()
  const history = useHistory()

  return (
    <Container>
      <Image src={screenshotUrls[0]} />
      <Title>{title}</Title>
      <Content>{content}</Content>
      <ButtonWrapper>
        <StyledWideRoundedButton
          onClick={() => {
            if (hasIntegration) {
              history.push(redirectUrl)
            } else {
              openInNewTab(redirectUrl)
            }
            track(MarketplaceEvent.openAppletClick, { app_name: trackName })
          }}
        >
          {intl.formatMessage(messages.go)}
        </StyledWideRoundedButton>
      </ButtonWrapper>
    </Container>
  )
}

const Container = styled.div``

const Image = styled.img`
  width: 100%;
  padding: 44px 16px;
`

const Text = styled.div`
  color: #ee8183;
  text-align: center;
  padding: ${({ theme }) => theme.padding[16]}
    ${({ theme }) => theme.padding[32]};
`

const Title = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize[16]};
  font-weight: ${({ theme }) => theme.fontWeight[700]};
`

const Content = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize[12]};
  text-align: justify;
  padding-bottom: ${({ theme }) => theme.padding[32]};
`

const StyledWideRoundedButton = styled(WideRoundedButton)`
  color: #ee8183;
  background-color: #fce6e6;
`

const ButtonWrapper = styled.div`
  padding: ${({ theme }) => theme.padding[16]};
`
