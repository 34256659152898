/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import {
  Switch,
  Route,
  Router,
  Redirect,
  useRouteMatch,
} from 'react-router-dom'

import { DashboardPageLoadable } from './pages/DashboardPage/DashboardPageLoadable'
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPageLoadable'
import { GlobalStyle } from '../styles/global-styles'
import { AppLayout } from './components/AppLayout'
import history from '../utils/history'
import { LinkTreePageLoadable } from './pages/LinkTreePage'
import { ApplicationContextProvider } from './providers/ApplicationContextProvider'
import {
  ResponsiveProvider,
  Spinner,
  ScrollToTop,
  ALThemeProvider as ThemeProvider,
  AnalyticsProvider,
  BobaLoader,
} from '@anyonelab/common'
import { FirebaseAuthContext } from '@anyonelab/frontend/app/providers/FirebaseAuthProvider/FirebaseAuthProvider'
import { LogInPageLoadable } from '@anyonelab/frontend/app/pages/LogInPage'
import { ReactQueryProvider } from './providers/ReactQueryProvider'
import { UserContextProvider } from './providers/UserContextProvider/UserContextProvider'
import { AdminPageLoadable } from './pages/AdminPage/AdminPageLoadable'
import { AdminThemeLoadable } from './pages/AdminPage/AdminThemeLoadable'
import { AdminMarketplaceApp } from './pages/AdminPage/AdminMarketplaceApp'
import { DataInsightsPageLoadable } from './pages/DataInsightsPage'
import { HomePageLoadable } from './boba-pages/HomePage'
import { SettingPageLoadable } from './boba-pages/SettingPage'
import BobaUserProvider, {
  useBobaUser,
} from './providers/BobaUserProvider/BobaUserProvider'
import { OnBoardingPageLoadable } from './boba-pages/OnBoardingPage'
import LanguageFunctionProvider from './providers/LanguageProvider/LanguageFunctionProvider'
import { useUser } from './providers/UserContextProvider/UserContext'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { auth } from './services/auth/firebase'
import { getCookie, setCookie } from '../utils/cookies'
import { DonatorRegisterPageLoadable } from './boba-pages/DonatorRegisterPage'
import { BalanceBoardPage } from './boba-pages/SettingPage/pages/BalanceBoardPage'
import { CashoutHistoryBoardPage } from './boba-pages/SettingPage/pages/CashoutHistoryBoardPage'
import { DonationHistoryBoardPage } from './boba-pages/SettingPage/pages/DonationHistoryBoardPage'
import { SettingBoardPage } from './boba-pages/SettingPage/pages/SettingBoardPage'
import { SETTING_PAGE_ROUTES } from './boba-pages/SettingPage/const'
import { DonorDonationHistoryBoardPage } from './boba-pages/SettingPage/pages/DonorDonationHistoryBoardPage'
// import { ReactQueryDevtools } from 'react-query/devtools'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
)

function BobaRoute() {
  const { bobaUserAccount, isLoading } = useBobaUser()
  const querySearch = window.location.search
  const { url } = useRouteMatch()

  if (isLoading) {
    return <BobaLoader />
  }

  return (
    <Switch>
      {/* TODO: Add guard for member who has activated. */}
      <Route
        path={`${url}/donator/register`}
        component={DonatorRegisterPageLoadable}
      />

      {!bobaUserAccount && (
        <Switch>
          <Route
            exact
            path={`${url}/onboard`}
            component={OnBoardingPageLoadable}
          />
          <Route
            render={() => <Redirect to={`${url}/onboard${querySearch}`} />}
          />
        </Switch>
      )}

      {bobaUserAccount && !bobaUserAccount.is_boba_user_activated && (
        <Switch>
          <Route
            exact
            path={`${url}/onboard`}
            component={OnBoardingPageLoadable}
          />
          <Route path={SETTING_PAGE_ROUTES.barSetting}>
            <SettingPageLoadable
              children={
                <Switch>
                  <Route
                    exact
                    path={SETTING_PAGE_ROUTES.barSetting}
                    children={<SettingBoardPage />}
                  />
                  <Route
                    exact
                    path={SETTING_PAGE_ROUTES.donationHistory}
                    children={<DonorDonationHistoryBoardPage />}
                  />
                </Switch>
              }
            />
          </Route>
          <Route
            render={() => <Redirect to={`${url}/onboard${querySearch}`} />}
          />
        </Switch>
      )}

      {bobaUserAccount && bobaUserAccount.is_boba_user_activated && (
        <Switch>
          <Route path={SETTING_PAGE_ROUTES.barSetting}>
            <SettingPageLoadable
              children={
                <Switch>
                  <Route
                    exact
                    path={SETTING_PAGE_ROUTES.barSetting}
                    children={<SettingBoardPage />}
                  />
                  <Route
                    path={SETTING_PAGE_ROUTES.receivedBobas}
                    children={<DonationHistoryBoardPage />}
                  />
                  <Route
                    path={SETTING_PAGE_ROUTES.cashoutHistory}
                    children={<CashoutHistoryBoardPage />}
                  />
                  <Route
                    path={SETTING_PAGE_ROUTES.balanceAndStats}
                    children={<BalanceBoardPage />}
                  />
                  <Route
                    exact
                    path={SETTING_PAGE_ROUTES.donationHistory}
                    children={<DonorDonationHistoryBoardPage />}
                  />
                </Switch>
              }
            />
          </Route>
          <Route exact path={`${url}`} component={HomePageLoadable} />

          {/* // ! Double confirm whether it is useful for early bird user */}
          <Route render={() => <Redirect to={`${url}${querySearch}`} />} />
        </Switch>
      )}
    </Switch>
  )
}

export function App() {
  const querySearch = window.location.search
  const { isAuthenticated, isLoadingUserFromFirebase } = React.useContext(
    FirebaseAuthContext,
  )

  // TODO: routes are messy now, they need to be refactored!
  // TODO: properly load /setup/social-accounts page!
  return (
    <Elements stripe={stripePromise}>
      <ResponsiveProvider>
        <ReactQueryProvider>
          <UserContextProvider>
            <LanguageFunctionProvider>
              <ThemeProvider>
                <AnalyticsProvider>
                  <ApplicationContextProvider>
                    {isLoadingUserFromFirebase && <Spinner />}
                    {!isLoadingUserFromFirebase && (
                      <Router history={history}>
                        <ScrollToTop />
                        <Helmet
                          titleTemplate="%s - AnyoneLab"
                          defaultTitle="AnyoneLab"
                        >
                          <meta name="description" content="AnyoneLab" />
                        </Helmet>
                        {!isAuthenticated && (
                          <Redirect to={`/login${querySearch}`} />
                        )}
                        {!isAuthenticated && (
                          <Route path="/login" component={LogInPageLoadable} />
                        )}
                        {isAuthenticated && (
                          <AppLayout>
                            <Switch>
                              <Redirect
                                exact
                                from="/"
                                to={`/dashboard${querySearch}`}
                              />

                              <Route
                                path="/link"
                                component={LinkTreePageLoadable}
                              />
                              <Route
                                path="/dashboard"
                                component={DashboardPageLoadable}
                              />
                              <Route
                                path="/boba"
                                children={
                                  <BobaUserProvider>
                                    <BobaRoute />
                                  </BobaUserProvider>
                                }
                              />

                              <Route
                                path="/data-insight"
                                component={DataInsightsPageLoadable}
                              />
                              <Route
                                exact
                                path="/admin/okr"
                                component={AdminPageLoadable}
                              />
                              <Route
                                exact
                                path="/admin/theme"
                                component={AdminThemeLoadable}
                              />
                              <Route
                                exact
                                path="/admin/app"
                                component={AdminMarketplaceApp}
                              />

                              <Route
                                render={() => (
                                  <Redirect to={`/dashboard${querySearch}`} />
                                )}
                              />
                              <Route component={NotFoundPage} />
                            </Switch>
                          </AppLayout>
                        )}
                        <GlobalStyle />
                      </Router>
                    )}
                  </ApplicationContextProvider>
                </AnalyticsProvider>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
              </ThemeProvider>
            </LanguageFunctionProvider>
          </UserContextProvider>
        </ReactQueryProvider>
      </ResponsiveProvider>
    </Elements>
  )
}
