import { useQuery, UseQueryResult } from 'react-query'
import { getPageList } from '@anyonelab/frontend/api/PageAPI'
import { Page } from '@anyonelab/frontend/models/Page'
import { PaginatedResponse } from '../../common'

export const useGetPageList = (): UseQueryResult<
  PaginatedResponse<Page[]>,
  Error
> => {
  return useQuery<PaginatedResponse<Page[]>, Error>('pages', getPageList, {
    refetchOnWindowFocus: false,
  })
}
