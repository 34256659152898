import { createMarketplaceApp } from '@anyonelab/frontend/api/MarketplaceAPI'
import IconUpload from '@anyonelab/frontend/app/components/Common/IconUpload'
import { Button, Form, Input, message, Select, Switch } from 'antd'
import _ from 'lodash'
import React, { Dispatch, SetStateAction } from 'react'
import { MarketplaceFormType } from './AppCreatePage'
import { snakeCase } from 'change-case'
interface AppCreateFormProps {
  appData: Partial<MarketplaceFormType>
  setAppData: Dispatch<SetStateAction<MarketplaceFormType>>
}

interface MarketplaceFormItemType<T extends keyof MarketplaceFormType> {
  T: MarketplaceFormType[T]
}

function AppCreateForm({ appData, setAppData }: AppCreateFormProps) {
  const { Option } = Select
  const [form] = Form.useForm<MarketplaceFormType>()
  const [appIconUrl, setAppIconUrl] = React.useState<string | null>(null)
  const [screenshotUrl, setScreenshotUrl] = React.useState<string | null>(null)

  const onValuesChange = (
    changedValues: MarketplaceFormItemType<keyof MarketplaceFormType>,
    _,
  ) => {
    switch (Object.keys(changedValues)[0]) {
      case 'svg': {
        const icon = {
          mime_type: 'image/svg+xml',
          data: Object.values(changedValues)[0],
        }
        setAppData((data: any) => {
          return {
            ...data,
            icon,
          }
        })
        break
      }
      default: {
        setAppData((data) => {
          return {
            ...data,
            ...changedValues,
          }
        })
      }
    }
  }

  const submit = async () => {
    try {
      const clonedApp = _.cloneDeep(appData)
      delete clonedApp.icon_select
      delete clonedApp.svg
      delete clonedApp.id

      await createMarketplaceApp(clonedApp)
      message.success('create success')
    } catch (err) {
      console.log(err)
      message.error('create fail, wrong data or track name already exist')
    }
  }

  return (
    <Form
      form={form}
      initialValues={appData}
      onValuesChange={onValuesChange}
      layout="vertical"
      validateMessages={{
        required: 'please input the field',
      }}
    >
      <Form.Item label="icon image format" name="icon_select">
        <Select>
          <Option value="svg">SVG</Option>
          <Option value="upload">Upload</Option>
        </Select>
      </Form.Item>
      {appData.icon_select === 'upload' ? (
        <Form.Item
          label="icon_upload (requirement: dimension should be closed to 56x56)"
          name="image_upload"
        >
          <IconUpload
            type="appIcon"
            iconUrl={appIconUrl}
            setIconUrl={setAppIconUrl}
            cb={(result) => {
              setAppData((data) => {
                return {
                  ...data,
                  icon: {
                    mime_type: 'image/jpeg',
                    url: result.image_name,
                  },
                }
              })
            }}
          />
        </Form.Item>
      ) : (
        <Form.Item label="svg" name="svg" rules={[{ required: true }]}>
          <Input.TextArea placeholder="paste the whole svg here" />
        </Form.Item>
      )}

      <Form.Item
        label="name english"
        name="name_english"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="name chinese"
        name="name_chinese"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="title english"
        name="title_english"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="title chinese"
        name="title_chinese"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="content english"
        name="content_english"
        rules={[{ required: true }]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label="content chinese"
        name="content_chinese"
        rules={[{ required: true }]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label="has integration? (false if it is redirect app) *only allow redirect app at now"
        name="has_integration"
        valuePropName="checked"
        rules={[{ required: true }]}
      >
        <Switch disabled />
      </Form.Item>
      <Form.Item
        label="redirect url (if it is not redirect app, such as linkPortal then put /link, otherwise put the whole redirect url)"
        name="redirect_url"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="card image (requirement: dimension should be closed to 16:9 and 640x360)"
        name="screenshot_urls"
      >
        <IconUpload
          type="appScreenShoot"
          iconUrl={screenshotUrl}
          setIconUrl={setScreenshotUrl}
          cb={(result) => {
            setAppData((data) => {
              return {
                ...data,
                screenshot_urls: [result.image_name],
              }
            })
          }}
        />
      </Form.Item>
      <Form.Item
        label="track name (snake_case unique name used for amplitude tracking)"
        name="track_name"
        rules={[
          {
            required: true,
            validator: (_, value) => {
              const regex = /^([a-z][a-z0-9]*)(_[a-z0-9]+)*$/
              const match = regex.test(value)
              if (!match) {
                return Promise.reject(
                  new Error(
                    `pattern not match snake case suggest ${value} -> ${snakeCase(
                      value,
                    )}`,
                  ),
                )
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Button
        onClick={() => {
          form
            .validateFields()
            .then((values) => {
              submit()
            })
            .catch((err) => {
              message.error(err?.errorFields[0]?.errors || 'invalid data')
            })
        }}
      >
        Create
      </Button>
    </Form>
  )
}

export default AppCreateForm
