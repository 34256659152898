import React from 'react'
import { useIntl } from 'react-intl'
import { useResponsive } from '../..'
import messages from './messages'

export const EmptyDonorDonationHistoryMessage = () => {
  const intl = useIntl()
  const { isMobileLayout } = useResponsive()
  return (
    <div
      style={{
        fontSize: isMobileLayout ? 12 : 16,
        textAlign: 'center',
        color: '#4a1e11',
        marginBottom: '12px',
      }}
    >
      {intl.formatMessage(messages.emptyMessage)}
    </div>
  )
}
