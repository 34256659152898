/**
 * Asynchronously loads the component for NotFoundPage
 */

import { lazyLoad } from '@anyonelab/frontend/utils/loadable'

export const SettingPageLoadable = lazyLoad(
  () => import('./SettingPage'),
  (module) => module.SettingPage,
)
