import { defineMessages } from 'react-intl'

export default defineMessages({
  header: {
    defaultMessage: 'Hey data enthusiasts!',
  },
  content: {
    defaultMessage:
      'If you’re interested in the 30 days data, please let us know.',
  },
  notifyMe: {
    defaultMessage: 'Notify Me',
  },
  respondHeader: {
    defaultMessage: 'Thank you!',
  },
  respondContent: {
    defaultMessage: 'We will notify you when the data is ready.',
  },
  gotIt: {
    defaultMessage: 'Got it',
  },
})
