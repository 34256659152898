import { DesktopOnly, MobileOnly, Spinner } from '@anyonelab/common/'
import { PageClickState } from '@anyonelab/frontend/app/pages/DataInsightsPage/slice/types'
import { LinkButtonDistribution } from '@anyonelab/frontend/types/DataInsight'
import React from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { useDataInsight } from '../../Context'
import DateRangeComponent from '../DateRangeComponent/DateRangeComponent'
import NetCountComponent from '../NetCountComonent/NetCountComponent'
import { PageClickChart } from '../PageClickChart'
import PageClickTableComponent from '../PageClickTableComponent/PageClickTableComponent'

interface PageClickPageProps {
  data: PageClickState
  isLoading: boolean
}

export const PageClickPage = ({ data, isLoading }: PageClickPageProps) => {
  const { dateRange } = useDataInsight()
  const theme = useTheme()

  const [netCountData, setNetCountData] = React.useState<{
    total: number
    net: string
    netPercentage: string
  }>({
    total: data.past_seven_days_click_times,
    net: data.past_seven_days_net,
    netPercentage: data.past_seven_days_net_percentage,
  })

  const [distributionData, setDistributionData] = React.useState<
    LinkButtonDistribution[]
  >(data.past_seven_days_click_distribution)

  React.useEffect(() => {
    switch (dateRange) {
      case '7': {
        setNetCountData({
          total: data.past_seven_days_click_times,
          net: data.past_seven_days_net,
          netPercentage: data.past_seven_days_net_percentage,
        })
        setDistributionData(data.past_seven_days_click_distribution)
        break
      }
      case '14': {
        setNetCountData({
          total: data.past_fourteen_days_click_times,
          net: data.past_seven_days_net,
          netPercentage: data.past_seven_days_net_percentage,
        })
        setDistributionData(data.past_fourteen_days_click_distribution)
        break
      }
      case '30': {
        setNetCountData({
          total: data.past_fourteen_days_click_times,
          net: data.past_seven_days_net,
          netPercentage: data.past_seven_days_net_percentage,
        })
        setDistributionData(
          data.past_fourteen_days_click_distribution.slice(0, 2),
        )
        break
      }
    }
  }, [dateRange])

  if (!data || isLoading) {
    return <Spinner />
  }

  return (
    <>
      <MobileOnly>
        <StyledNetCountComponent
          numberOfData={dateRange}
          total={netCountData.total}
          net={netCountData.net}
          netPercentage={netCountData.netPercentage}
          themeColor={theme.colors.pageClick}
        />
        <StyledPageClickChart
          locked={dateRange === '30'}
          data={distributionData}
        />
        <DateRangeComponent themeColor={theme.colors.pageClick} />
        <PageClickTableComponent
          locked={dateRange === '30'}
          data={distributionData}
        />
      </MobileOnly>
      <DesktopOnly>
        <DesktopStatisticContainer>
          <div className="net-date-range-container">
            <NetCountComponent
              numberOfData={dateRange}
              total={netCountData.total}
              net={netCountData.net}
              netPercentage={netCountData.netPercentage}
              themeColor={theme.colors.pageClick}
            />
            <DateRangeComponent themeColor={theme.colors.pageClick} />
          </div>
          <div className="chart-container">
            <PageClickChart
              locked={dateRange === '30'}
              data={distributionData}
            />
          </div>
          <div className="table-container">
            <PageClickTableComponent
              locked={dateRange === '30'}
              data={distributionData}
            />
          </div>
        </DesktopStatisticContainer>
      </DesktopOnly>
    </>
  )
}

const DesktopStatisticContainer = styled.div`
  .net-date-range-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .chart-container {
    width: 70%;
    min-width: 500px;
    margin: 20px;
  }
`

const StyledNetCountComponent = styled(NetCountComponent)`
  margin-bottom: 32px;
`

const StyledPageClickChart = styled(PageClickChart)`
  margin-bottom: 32px;
`
