import { BobaCurrency } from '../boba-components'

export const getMinimumCashoutAmountFromCurrency = (currency: BobaCurrency) => {
  switch (currency) {
    case 'hkd':
      return 500
    case 'twd':
      return 2000
    case 'usd':
      return 64
  }
}
