/**
 *
 * AppLayout
 *
 */
import {
  Button,
  Typography,
  Col,
  Layout,
  Space,
  MoreOutlined,
  Dropdown,
  Menu,
  DesktopOnly,
  useResponsive,
  DashboardFooter,
} from '@anyonelab/common'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { useRouteMatch } from 'react-router-dom'

import styled from 'styled-components/macro'
import { NavigationDrawerProvider } from '../DrawerNavigation'
import { Navigation } from '../Navigation'
import logo from './assets/Logo.svg'

export interface AppLayoutProps {}

export const AppLayout = ({
  children,
}: React.PropsWithChildren<AppLayoutProps>) => {
  const { Content, Sider } = Layout
  const {
    isTabletLayoutBig,
    isDesktopLayout,
    responsiveClassName,
  } = useResponsive()
  const isBobaDonatorRegisterPath = window.location.pathname.endsWith(
    '/donator/register',
  )

  return (
    <AppLayoutStyled>
      <Layout style={{ minHeight: '100vh' }}>
        <NavigationDrawerProvider>
          <DesktopOnly>
            {(isTabletLayoutBig || isDesktopLayout) &&
            !isBobaDonatorRegisterPath ? (
              <Sider theme="light" collapsed>
                <div className="logo">
                  <img src={logo} alt="AnyoneLab" />
                </div>
                <Navigation />
              </Sider>
            ) : (
              <></>
            )}
          </DesktopOnly>

          <ContentLayout className={`content-layout ${responsiveClassName}`}>
            <div id="app-header" style={{ width: '100%' }} />
            <Content style={{ overflow: 'initial' }}>{children}</Content>
            <DashboardFooter />
          </ContentLayout>
        </NavigationDrawerProvider>
      </Layout>
    </AppLayoutStyled>
  )
}

const HEADER_HEIGHT = '54px'

const AppLayoutStyled = styled.div`
  min-height: 100vh;

  .logo {
    height: ${HEADER_HEIGHT};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #app-header {
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 99;
  }
`

const ContentLayout = styled(Layout)`
  min-width: 320px;
  &.tablet-small,
  &.tablet-big {
    width: fit-content;
  }
  &.tablet-small {
    min-width: 744px;
  }
  &.tablet-big {
    min-width: 1024px;
  }
  background: ${({ theme }) => theme.colors.background};
  position: relative;
`

export interface AppHeaderMenuItem {
  title: string
  icon?: React.ReactNode
  primary?: boolean
  hideTitleOnDesktop?: boolean
  onClick?(): void
  hidden?: boolean
  component?: React.ReactNode
}

export interface AppHeaderProps {
  title: string
  customStateComponent?: React.ReactNode
  menu?: AppHeaderMenuItem[]
}

export const AppHeader = ({
  title,
  customStateComponent,
  menu = [],
}: AppHeaderProps) => {
  const domNode = document.body.querySelector('#app-header')

  if (!domNode) return null

  const renderDesktopButtons = () => {
    return (
      <Space direction="horizontal">
        {menu.map((item, index) => {
          /** Allow app header wrap not only button but also custom component */
          return !item.hidden ? (
            item.component ? (
              <>{item.component}</>
            ) : (
              <Button
                key={index}
                type={item.primary ? 'primary' : 'default'}
                icon={item.icon ? item.icon : null}
                onClick={item.onClick}
              >
                {item.hideTitleOnDesktop ? '' : item.title}
              </Button>
            )
          ) : null
        })}
      </Space>
    )
  }

  const renderMobileButtons = () => {
    const overlay = (
      <Menu>
        {menu.map((item, index) => {
          return !item.hidden ? (
            <AppHeaderMobileMenuItem key={index} onClick={item.onClick}>
              {item.title}
            </AppHeaderMobileMenuItem>
          ) : null
        })}
      </Menu>
    )

    return (
      <>
        <Dropdown key="more" overlay={overlay} destroyPopupOnHide>
          <Button icon={<MoreOutlined />}></Button>
        </Dropdown>
      </>
    )
  }

  return ReactDOM.createPortal(
    <AppHeadertStyled>
      {/* <DesktopOnly> */}
      <Col flex={'auto'} style={{ display: 'flex', alignItems: 'center' }}>
        <Space direction="horizontal">
          <Typography.Text>{title}</Typography.Text>
          {customStateComponent && (
            <Typography.Text>{customStateComponent}</Typography.Text>
          )}
        </Space>
      </Col>
      <Col flex={'0 1'}>{renderDesktopButtons()}</Col>
      {/* </DesktopOnly> */}

      {/* <MobileOnly>
        <Col flex={'auto'} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography.Text>{title}</Typography.Text>
        </Col>
        <Col flex={'0 1'}>{renderMobileButtons()}</Col>
      </MobileOnly> */}
    </AppHeadertStyled>,
    domNode,
  )
}

const AppHeadertStyled = styled.div`
  height: ${HEADER_HEIGHT};
  padding: 0 16px;

  display: flex;
  align-items: center;
`

const AppHeaderMobileMenuItem = styled(Menu.Item)`
  height: 48px;
  min-width: 250px;
  text-align: center;
`
