import { MarketplaceFormType } from './component/AppCreatePage'

export const DEFAULT_APP_FORM_PLACEHOLDER: MarketplaceFormType = {
  id: 1,
  track_name: 'link_in_bio',
  name_english: 'SnakeBio',
  name_chinese: '連結雲',
  title_english: '🔗 A link to showcase everything.',
  title_chinese: '專為社群帳號設計 🔗 多連結整合神器',
  content_english:
    "The only link you'll ever need to introduce yourself, your services or your brand to prospects. AnyoneLab gives you the luxury to really personalize your connections and analyze them!",
  content_chinese:
    '告別複雜的設定！只需一個連結就直達不同的頁面，系統化且美觀的一次性呈現你的所有服務。高自由度的顏色設定跟模板選擇，讓 AnyoneLab 協助你的品牌起飛！',
  icon_select: 'svg',
  icon: {
    mime_type: 'image/svg+xml',
    data: `<svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 0H12C11.45 0 11 0.45 11 1C11 1.55 11.45 2 12 2H15C16.65 2 18 3.35 18 5C18 6.65 16.65 8 15 8H12C11.45 8 11 8.45 11 9C11 9.55 11.45 10 12 10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM6 5C6 5.55 6.45 6 7 6H13C13.55 6 14 5.55 14 5C14 4.45 13.55 4 13 4H7C6.45 4 6 4.45 6 5ZM8 8H5C3.35 8 2 6.65 2 5C2 3.35 3.35 2 5 2H8C8.55 2 9 1.55 9 1C9 0.45 8.55 0 8 0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H8C8.55 10 9 9.55 9 9C9 8.45 8.55 8 8 8Z" fill="#EE8183"/></svg>`
      .replace(/"/g, "'")
      .replace(/\n/g, ''),
  },
  svg: `<svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 0H12C11.45 0 11 0.45 11 1C11 1.55 11.45 2 12 2H15C16.65 2 18 3.35 18 5C18 6.65 16.65 8 15 8H12C11.45 8 11 8.45 11 9C11 9.55 11.45 10 12 10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM6 5C6 5.55 6.45 6 7 6H13C13.55 6 14 5.55 14 5C14 4.45 13.55 4 13 4H7C6.45 4 6 4.45 6 5ZM8 8H5C3.35 8 2 6.65 2 5C2 3.35 3.35 2 5 2H8C8.55 2 9 1.55 9 1C9 0.45 8.55 0 8 0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H8C8.55 10 9 9.55 9 9C9 8.45 8.55 8 8 8Z" fill="#EE8183"/></svg>`
    .replace(/"/g, "'")
    .replace(/\n/g, ''),
  screenshot_urls: [
    'https://media.anyonelab.com/images/marketplace-app/screenshot/cool-kids-brainstorming.png',
  ],
  has_integration: false,
  redirect_url: '/link',
}
