/**
 *
 * Navigation
 *
 */
import {
  BobaComponent,
  BobaDisabledComponent,
  ChipComponent,
  ChipDisabledComponent,
  DiscordLogo,
  KeyOutlined,
  Menu,
  message,
  NavBarEvent,
  openInNewTab,
  track,
  TranslationOutlined,
} from '@anyonelab/common'
import {
  HomeFilled,
  InstagramOutlined,
  LayoutFilled,
  LinkOutlined,
  QuestionCircleOutlined,
  SettingFilled,
} from '@ant-design/icons'
import * as React from 'react'

import styled from 'styled-components/macro'
import { NavLink, useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { SignOutButton } from '@anyonelab/frontend/app/components/LoginForm/components/SignOutButton/SignOutButton'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectFullLocale,
  selectI18n,
  selectLocale,
} from '../../providers/LanguageProvider/slice/selectors'
import {
  i18nActions,
  useI18nSlice,
} from '../../providers/LanguageProvider/slice'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import localStorageKey from '@anyonelab/frontend/types/localStorageKey'
import { ChangeLanguageButton } from '../LoginForm/components/ChangeLanguageButton/ChangeLanguageButton'
import messages from './messages'
import { headerCase } from 'change-case'
import { useUser } from '../../providers/UserContextProvider/UserContext'
import { BASE_API_URL } from '@anyonelab/frontend/config'
import { getToken } from '@anyonelab/frontend/utils/api-client'

export interface NavigationProps {}

export const FEEDBACK_GOOGLE_FORM_URL = 'https://tally.so/r/w4MQrn'
export const ANYWHERE_COMMUNITY_DISCORD_LINK = 'https://discord.gg/GMyTV7S9s3'
export const ANYONELAB_INSTAGRAM_ZH_LINK =
  'https://www.instagram.com/anyone.lab/'

export const Navigation = (props: NavigationProps) => {
  const location = useLocation()
  const querySearch = window.location.search
  const intl = useIntl()
  const history = useHistory()
  const { userData } = useUser()
  const handleClick = (e) => {
    track(NavBarEvent.dashboardClick)
    e.preventDefault()
  }
  const [token, setToken] = React.useState('')
  React.useEffect(() => {
    getToken()
      .then((value) => setToken(value))
      .catch((err) => console.error('no token', err))
  }, [])

  const currentPath = '/' + location.pathname.split('/')[1]
  return (
    <NavigationStyled>
      <Menu mode="inline" inlineCollapsed={true} selectedKeys={[currentPath]}>
        <Menu.Item key="/dashboard" icon={<HomeFilled />}>
          {intl.formatMessage(messages.dashboard)}
          <NavLink
            onClick={() => track(NavBarEvent.dashboardClick)}
            to={`/dashboard${querySearch}`}
          />
        </Menu.Item>
        <Menu.Item
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          key="/link"
          icon={
            currentPath === '/link' ? (
              <ChipComponent width="22px" height="16px" />
            ) : (
              <ChipDisabledComponent width="22px" height="16px" />
            )
          }
        >
          {intl.formatMessage(messages.linkPortal)}
          <NavLink
            onClick={() => track(NavBarEvent.linkPortalClick)}
            to={`/link${querySearch}`}
          />
        </Menu.Item>
        <Menu.Item
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          key="/boba"
          icon={
            currentPath === '/boba' ? (
              <BobaComponent width="16px" height="25px" />
            ) : (
              <BobaDisabledComponent width="16px" height="25px" />
            )
          }
        >
          {intl.formatMessage(messages.boba)}
          <NavLink
            onClick={(e) => {
              if (currentPath === '/boba') {
                // ! prevent user click navlink again in boba page and cause bugs
                e.preventDefault()
              }
              // track(NavBarEvent.linkPortalClick)
            }}
            to={`/boba${querySearch}`}
          />
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            openInNewTab(ANYONELAB_INSTAGRAM_ZH_LINK)
            track(NavBarEvent.anyonelabZhInstagramLinkClick)
          }}
          key="/instagram"
          icon={<InstagramOutlined />}
        >
          {intl.formatMessage(messages.instagram)}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            openInNewTab(ANYWHERE_COMMUNITY_DISCORD_LINK)
            track(NavBarEvent.anywhereDiscordChannelInvitationLinkClick)
          }}
          key="/discord"
          icon={<DiscordLogo width={16} height={16} />}
        >
          {intl.formatMessage(messages.discord)}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            openInNewTab(FEEDBACK_GOOGLE_FORM_URL)
            track(NavBarEvent.feedbackClick)
          }}
          key="/feedback"
          icon={<QuestionCircleOutlined />}
        >
          {intl.formatMessage(messages.leaveUsFeedback)}
        </Menu.Item>
        <ChangeLanguageButton />
        {/* <Menu.Item key="/settings" icon={<SettingFilled />}>
          Settings
          <NavLink to="/settings"></NavLink>
        </Menu.Item> */}
        {userData.is_admin ? (
          <Menu.Item
            onClick={() => {
              token &&
                openInNewTab(`${BASE_API_URL}/admin-panel?accessToken=${token}`)
            }}
            key="/admin"
            icon={<KeyOutlined width={16} height={16} />}
          >
            {intl.formatMessage(messages.adminPanel)}
          </Menu.Item>
        ) : (
          <></>
        )}
        <SignOutButton />
      </Menu>
    </NavigationStyled>
  )
}

const NavigationStyled = styled.div`
  .ant-menu-item {
    color: #9a9a9a;

    &.ant-menu-item-selected {
      background-color: #fce6e6;
      color: #ee8183;
    }

    &.ant-menu-item-selected svg path {
      background-color: #fce6e6;
      /* fill: #ee8183; */
    }

    &:hover {
      color: #ee8183;
    }

    &:hover svg path {
      fill: #ee8183;
    }
  }
  .disabled {
    background-color: #dadada;
    &:hover {
      color: #9a9a9a;
    }
  }
  /* NOTE: Remove preset ant-menu margin to align with the header of linkPortal */
  .ant-menu-vertical .ant-menu-item {
    margin-top: 0;
  }
`
