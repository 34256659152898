/**
 * Asynchronously loads the component for NotFoundPage
 */

import { lazyLoad } from '@anyonelab/frontend/utils/loadable'

export const HomePageLoadable = lazyLoad(
  () => import('./HomePage'),
  (module) => module.HomePage,
)
