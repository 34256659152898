import { DataInsightEvent, Modal, track } from '@anyonelab/common/'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import messages from './messages'

type NotifyBlockProps = {
  content: 'page_view' | 'page_click'
  themeColor: string
} & React.HTMLAttributes<HTMLDivElement>

export const NotifyBlock = (props: NotifyBlockProps) => {
  const intl = useIntl()
  const [showModal, setShowModal] = React.useState(false)
  const requestUnlock = () => {
    track(DataInsightEvent.requestUnlock30DaysCardClick, {
      type: props.content,
    })
    setShowModal(true)
  }

  return (
    <div {...props}>
      <Container>
        <Header children={intl.formatMessage(messages.header)} />
        <Content children={intl.formatMessage(messages.content)} />
        <Button
          buttonColor={props.themeColor}
          whileTap={{ opacity: 0.5, scale: 0.9 }}
          onClick={requestUnlock}
          children={intl.formatMessage(messages.notifyMe)}
        />
      </Container>
      <Modal
        visible={showModal}
        centered
        wrapClassName={'appPopUpCardCSS'}
        maskClosable
        onCancel={() => setShowModal(false)}
        closable={false}
        footer={<></>}
      >
        <ModalContainer>
          <Header children={intl.formatMessage(messages.respondHeader)} />
          <Content children={intl.formatMessage(messages.respondContent)} />
          <Button
            buttonColor={props.themeColor}
            whileTap={{ opacity: 0.5, scale: 0.9 }}
            children={intl.formatMessage(messages.gotIt)}
            onClick={() => setShowModal(false)}
          />
        </ModalContainer>
      </Modal>
    </div>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(236, 238, 240, 0.5) 0%,
    rgba(236, 238, 240, 0.415) 100%
  );
  font-family: Montserrat;
  box-shadow: 3px 4px 14px rgba(133, 133, 133, 0.25);
  backdrop-filter: blur(4px);
  border-radius: 24px;
  padding: 32px;
  & > div:not(:last-child) {
    margin-bottom: 24px;
  }
`

const Header = styled.div`
  color: #808182;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
`

const Content = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
`

const Button = styled(motion.div)<{ buttonColor: string }>`
  color: white;
  background: ${({ buttonColor }) => buttonColor};
  padding: 8px 12px;
  border-radius: 24px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
`

const ModalContainer = styled.div`
  font-family: Montserrat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 32px;
  & > div:not(:last-child) {
    margin-bottom: 24px;
  }
`
