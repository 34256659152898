import { Radio } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { useDataInsight } from '../../Context'
import messages from './messages'

export type DateRange = '7' | '14' | '30'

type DateRangeComponentProps = {
  themeColor: string
} & React.HTMLAttributes<HTMLDivElement>

function DateRangeComponent(props: DateRangeComponentProps) {
  const { dateRange, dateRangeOnChange } = useDataInsight()
  const intl = useIntl()
  const options: { label: string; value: DateRange }[] = [
    { label: 'Past 7 days', value: '7' },
    { label: 'Past 14 days', value: '14' },
    { label: 'Past 30 days', value: '30' },
  ]

  return (
    <Container {...props}>
      {options.map((item) => {
        return (
          <PickerButton
            key={item.label}
            active={dateRange === item.value}
            activeBackgroundColor={props.themeColor}
            onClick={() => dateRangeOnChange(item.value)}
            children={intl.formatMessage(messages.pastNumberOfDays, {
              number: item.value,
            })}
          />
        )
      })}
    </Container>
  )
}

export default DateRangeComponent

const Container = styled.div`
  background-color: #f3f4f5;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PickerButton = styled.div<{
  active: boolean
  activeBackgroundColor: string
}>`
  white-space: nowrap;
  flex: 1;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 999px;
  padding: 4px 10px;
  color: ${({ active }) => (active ? 'white' : '#989898')};
  background: ${({ active, activeBackgroundColor }) =>
    active ? activeBackgroundColor : 'transparent'};
`
