import {
  AnalyticsContext,
  BobaLoader,
  BobaMeSettingPageEvent,
  BobaResponsiveModal,
  DonationHistoryBoard,
  openInNewTab,
  track,
} from '@anyonelab/common/'
import { useGetBobaDonationWithDonorInfo } from '@anyonelab/frontend/api/boba-api/hook/useGetBobaData'
import { PatchBobaDonationRequest } from '@anyonelab/frontend/api/boba-api/schema/boba-request.schema'
import { GetBobaDontaionResponse } from '@anyonelab/frontend/api/boba-api/schema/boba-response.schema'
import { useBobaUser } from '@anyonelab/frontend/app/providers/BobaUserProvider'
import { apiClient } from '@anyonelab/frontend/utils/api-client'
import React from 'react'
import { useMutation } from 'react-query'
import { DonationReceiptContent } from './DonationReceiptContent'

const DEFAULT_PAGE_SIZE = 10

export const DonationHistoryBoardPage = () => {
  const [pageNumber, setPageNumber] = React.useState(1)
  const {
    data,
    isLoading,
    refetch: getBobaDonationRefetch,
  } = useGetBobaDonationWithDonorInfo((pageNumber - 1) * DEFAULT_PAGE_SIZE)
  const [showReceiptModal, setShowReceiptModal] = React.useState(false)
  // TODO: We could have used query data from React-query instead of passing an actual object, lets refactor later on.
  const [
    receiptModalContent,
    setReceiptModalContent,
  ] = React.useState<GetBobaDontaionResponse | null>(null)

  // TODO: Refactor to simpler code during cool down period.
  const updateDisplayStatus = useMutation(
    ({
      data,
      donationId,
    }: {
      data: PatchBobaDonationRequest
      donationId: number
    }) => {
      return apiClient.patch(`/boba-donations/${donationId}`, data)
    },
    {
      onSuccess: async () => {
        getBobaDonationRefetch()
      },
    },
  )

  const { amplitudeInit } = React.useContext(AnalyticsContext)

  const { bobaUserAccount } = useBobaUser()

  const changePage = (currentPage: number, pageSize?: number) => {
    setPageNumber(currentPage)
  }

  const displayOnClick = async (isDisplay: boolean, donationId: number) => {
    updateDisplayStatus.mutate({
      donationId,
      data: { is_display_by_user: isDisplay },
    })
  }

  const repostOnClick = (data: GetBobaDontaionResponse) => {
    setShowReceiptModal(true)
    setReceiptModalContent(data)
    amplitudeInit && track(BobaMeSettingPageEvent.repost_click, {})
  }

  const replyOnClick = (sectionId: string) => {
    const domain = bobaUserAccount?.domain
    // TODO: Replace bobaboba.me with .env variable.
    openInNewTab(`https://${domain}.bobaboba.me#${sectionId}`)
  }

  React.useEffect(() => {
    if (data) return
  }, [pageNumber])

  if (isLoading) {
    return <BobaLoader />
  } else {
    return (
      <>
        <DonationHistoryBoard
          donationHistoryData={data?.data || []}
          pagination={{
            current: pageNumber,
            defaultCurrent: 1,
            onChange: changePage,
            pageSize: DEFAULT_PAGE_SIZE,
            total: data?.total || 0,
          }}
          displayOnClick={displayOnClick}
          repostOnClick={repostOnClick}
          replyOnClick={replyOnClick}
        />
        <BobaResponsiveModal
          bodyStyle={{ margin: '24px 0px 120px' }}
          visible={showReceiptModal}
          onClose={() => setShowReceiptModal(false)}
          children={<DonationReceiptContent data={receiptModalContent} />}
        />
      </>
    )
  }
}
