import { apiClient } from '@anyonelab/frontend/utils/api-client'
import moment from 'moment'
import { AnalyticsInfoType, DataInsightResponse } from '../types/DataInsight'

const dataInsightApiUrl = '/analytics'

// generic type used to tell the response type with corresponding matching type
export const getDataInsight = <AnalyticsType extends AnalyticsInfoType>(
  type: AnalyticsType,
  todayData = new Date(),
): Promise<DataInsightResponse<AnalyticsType>> => {
  return apiClient
    .get(`${dataInsightApiUrl}/${type}`, {
      params: { todayData },
    })
    .then((res) => res.data)
}
