import { GetBobaPayoutResponsePaginatedResponse } from './schema/boba-response.schema'
import { apiClient } from '@anyonelab/frontend/utils/api-client'
import {
  CreateBobaPayoutRequest,
  PatchBobaPayoutRequest,
} from './schema/boba-request.schema'

export const getBobaPayouts = (
  skip: number,
): Promise<GetBobaPayoutResponsePaginatedResponse> => {
  try {
    return apiClient
      .get<GetBobaPayoutResponsePaginatedResponse>('/boba-payouts', {
        params: {
          $skip: skip || 0,
          '$sort[createdAt]': 1,
        },
      })
      .then((res) => res.data)
  } catch (err) {
    throw err
  }
}

export const createBobaPayouts = (data: CreateBobaPayoutRequest) => {
  try {
    return apiClient.post('/boba-payouts', data).then((res) => res.data)
  } catch (err) {
    throw err
  }
}

// ! admin action
export const updatePendingCashoutRequestToPaid = (
  data: PatchBobaPayoutRequest,
) => {
  try {
    return apiClient.patch('/boba-payouts', data).then((res) => res.data)
  } catch (err) {
    throw err
  }
}
