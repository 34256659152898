import { apiClient } from '@anyonelab/frontend/utils/api-client'
import { MarketplaceApp } from '../models/MarketplaceApp'
import { PaginatedResponse } from './common'

export const getMarketplaceApps = (): Promise<
  PaginatedResponse<MarketplaceApp[]>
> => {
  return apiClient.get('/marketplace-app?$limit=50').then((res) => res.data)
}

export const createMarketplaceApp = (
  data: Partial<MarketplaceApp>,
): Promise<MarketplaceApp> => {
  return apiClient.post('/marketplace-app', data).then((res) => res.data)
}

export const batchUpdateMarketplace = (data: Partial<MarketplaceApp>[]) => {
  return apiClient.post(`/marketplace-batch`, data).then((res) => res.data)
}
