/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from '@anyonelab/frontend/utils/loadable'

export const DashboardPageLoadable = lazyLoad(
  () => import('./DashboardPage'),
  (module) => module.DashboardPage,
)
