import styled from 'styled-components'

export const TitleBar = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  min-height: ${({ height }) => height}px;
  > div:not(:last-child) {
    margin-right: ${({ theme }) => theme.padding[16]};
  }
`
