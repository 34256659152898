import * as React from 'react'
import {
  Button,
  clearAnalyticsUserProperties,
  LogoutOutlined,
  Menu,
  NavBarEvent,
  track,
} from '@anyonelab/common'
import { auth } from '@anyonelab/frontend/app/services/auth/firebase'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import messages from '@anyonelab/frontend/app/components/LoginForm/components/SignOutButton/messages'
import { eraseCookie } from '@anyonelab/frontend/utils/cookies'
import { removeAccessToken } from '@anyonelab/frontend/utils/storage'

export const logOut = () => {
  track(NavBarEvent.logoutClick, {})
  removeAccessToken()
  auth
    .signOut()
    .then(() => clearAnalyticsUserProperties())
    .then(() => window.location.replace('/login'))
}

export const bobaLogOut = () => {
  removeAccessToken()

  auth
    .signOut()
    .then(() => clearAnalyticsUserProperties())
    .then(() => window.location.replace('/login?type=BOBA_DONATOR'))
}

export const SignOutButton = () => {
  const history = useHistory()

  const intl = useIntl()

  return (
    <Menu.Item icon={<LogoutOutlined />} onClick={logOut}>
      {intl.formatMessage(messages.logOutButtonText)}
    </Menu.Item>
  )
}
